import { Avatar, Box, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../assets/images/icons/DeleteIcon.tsx";
import RolesDropdown from "../../../components/common/ActionsDotsDropdown/ActionsDotsDropdown.tsx";
import BriaInput from "../../../components/common/BriaInput/BriaInput.tsx";
import BriaTooltip from "../../../components/common/BriaTooltip/BriaTooltip.tsx";
import ConfirmationPopup from "../../../components/common/ConfirmationPopup/ConfirmationPopup.tsx";
import GrantPermissions from "../../../components/common/GrantPermissions/GrantPermissions.tsx";
import LoadingPlaceholder from "../../../components/common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import { USER_DEFAULT_AVATAR, USER_ORGANIZATION_ROLES } from "../../../constants/UserConstants.ts";
import { getSelectedOrganization } from "../../../helpers/localStorage.ts";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { useUserChecks } from "../../../hooks/useUserChecks.tsx";
import TableLayout from "../../../layout/TableLayout/TableLayout.tsx";
import { MyAccountUser } from "../../../models/organization.ts";
import { validateEmail } from "../../../utils";
import styles from "./Users.module.scss";

const Users = () => {
	const { appStore, authStore } = useAppStore();
	const { hasEditPermission, hideSecondaryButton } = useUserChecks();
	const selectedOrg = getSelectedOrganization();
	const [ancestorsSelectedRole, setAncestorsSelectedRole] = React.useState<string>(
		USER_ORGANIZATION_ROLES.ADMIN_HIDDEN,
	);
	const [ancestorsSelectedUsers, setAncestorsSelectedUsers] = React.useState<string[]>([]);
	const [showAncestorsUsersPopup, setShowAncestorsUsersPopup] = useState(false);
	const [deleteConfirmationPopup, showDeleteConfirmationPopup] = useState<boolean>(false);
	const [userToUpdate, setUserToUpdate] = useState<MyAccountUser | null>(null);
	const { t } = useTranslation("translation", { keyPrefix: "account.users" });
	const [showInviteMembersPopup, setShowInviteMembersPopup] = useState(false);
	const [inviteMembersEmails, setInviteMembersEmails] = useState("");
	const [invitationSentPopup, showInvitationSentPopup] = useState(false);
	const [ownershipTransferPopup, setOwnershipTransferPopup] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [tableData, setTableData] = useState<
		Array<{
			rowStyle: any;
			data: {
				name: string;
				role: string;
				actions: string;
			};
		}>
	>([]);
	const headerArray = [t("table.name"), t("table.role"), t("table.actions")];

	useEffect(() => {
		const loadData = async () => {
			if (!appStore.isError && appStore.myAccountUsers.length === 0) {
				appStore.ancestorsUsers = [];
				await appStore.loadMyAccountUsers();
			}
		};
		loadData();
	}, []);

	useEffect(() => {
		const newTableData: any = appStore.myAccountUsers.map((user: MyAccountUser) => {
			return {
				rowStyle: {
					backgroundColor: "",
				},
				data: {
					name: getNameContainer(user),
					role: getRoleContainer(user),
					actions: getActionsContainer(user),
				},
			};
		});
		setTableData([...newTableData]);
	}, []);

	function getNameContainer(user: MyAccountUser) {
		return (
			<Box className={styles.nameContainer}>
				<Avatar src={user.profile_picture ?? USER_DEFAULT_AVATAR} />
				<Box className={styles.informationBox}>
					<Typography className={styles.name}>{user.user_name}</Typography>
					<Typography className={styles.email}>{user.email}</Typography>
				</Box>
			</Box>
		);
	}

	function getRoleContainer(user: MyAccountUser) {
		const items = [
			{
				text:
					user.role === USER_ORGANIZATION_ROLES.ADMIN
						? USER_ORGANIZATION_ROLES.USER
						: user.role === USER_ORGANIZATION_ROLES.USER
							? USER_ORGANIZATION_ROLES.ADMIN
							: user.role === USER_ORGANIZATION_ROLES.ADMIN_HIDDEN
								? USER_ORGANIZATION_ROLES.USER_HIDDEN
								: USER_ORGANIZATION_ROLES.ADMIN_HIDDEN,
				onClick: () => {
					const newRole =
						user.role === USER_ORGANIZATION_ROLES.ADMIN
							? USER_ORGANIZATION_ROLES.USER
							: user.role === USER_ORGANIZATION_ROLES.USER
								? USER_ORGANIZATION_ROLES.ADMIN
								: user.role === USER_ORGANIZATION_ROLES.ADMIN_HIDDEN
									? USER_ORGANIZATION_ROLES.USER_HIDDEN
									: USER_ORGANIZATION_ROLES.ADMIN_HIDDEN;

					updateUserRole(user, newRole);
				},
			},
		];

		if (getSelectedOrganization()?.role === USER_ORGANIZATION_ROLES.OWNER) {
			items.push({
				text: USER_ORGANIZATION_ROLES.OWNER,
				onClick: () => {
					setUserToUpdate(user);
					setOwnershipTransferPopup(true);
				},
			});
		}

		return (
			<Box
				className={clsx({
					[styles.disabled]: false,
				})}
			>
				{hasEditPermission(user) ? <RolesDropdown label={user.role} items={items} /> : user.role}
			</Box>
		);
	}

	function getActionsContainer(user: MyAccountUser) {
		return (
			<BriaTooltip
				title={t("delete")}
				className={clsx({
					[styles.actionsContainer]: true,
					[styles.disabledOpacity]: !hasEditPermission(user),
				})}
			>
				<Link
					onClick={(_e: React.MouseEvent<any>) => {
						if (hasEditPermission(user)) {
							setUserToUpdate(user);
							showDeleteConfirmationPopup(true);
						}
					}}
					className={styles.link}
				>
					<DeleteIcon className={styles.deleteIcon} />
				</Link>
			</BriaTooltip>
		);
	}

	const deleteUser = async () => {
		await appStore.deleteMyAccountUser(userToUpdate?.uid ?? "");
		setUserToUpdate(null);
	};

	const updateUserRole = async (user: MyAccountUser, newRole: string) => {
		await appStore.updateMyAccountUser([{ uid: user?.uid, role: newRole }]);
		await appStore.loadMyAccountUsers();
		setUserToUpdate(null);
	};

	const closeConfirmationsPopup = () => {
		showDeleteConfirmationPopup(false);
		setUserToUpdate(null);
	};

	const grantUsersPermission = async () => {
		setShowAncestorsUsersPopup(false);
		appStore.grantUsersPermission(ancestorsSelectedUsers, ancestorsSelectedRole);
	};
	const sendInvitation = async () => {
		const emails = inviteMembersEmails.replace(/\s/g, "").toLowerCase().split(",");
		const areAllEmailsValid = emails.every((email: string) => validateEmail(email));
		if (areAllEmailsValid) {
			const uniqueEmails = [...new Set(emails)];
			if (uniqueEmails.length > 0) {
				const alreadyInvited = appStore.myAccountUsers.some((user) =>
					uniqueEmails.includes(user?.email?.toLowerCase() ?? ""),
				);
				if (!alreadyInvited) {
					const isSent = await appStore.sendUserInvitations(uniqueEmails);
					setInviteMembersEmails("");
					setShowInviteMembersPopup(false);
					showInvitationSentPopup(isSent);
				} else {
					setErrorMessage(t("errors.alreadyInvited"));
				}
			} else {
				setErrorMessage(t("errors.invalidEmails"));
			}
		} else {
			setErrorMessage(t("errors.invalidEmails"));
		}
	};

	const handleOwnershipTransfer = async () => {
		setOwnershipTransferPopup(false);

		const usersToTransfer = [
			{
				uid: userToUpdate?.uid,
				role: USER_ORGANIZATION_ROLES.OWNER,
			},
			{
				uid: selectedOrg?.organization?.owner_uid,
				role: USER_ORGANIZATION_ROLES.ADMIN,
			},
		];

		await appStore.updateMyAccountUser(usersToTransfer);
		await appStore.loadMyAccountUsers();

		await authStore.loadOrganizations();

		setUserToUpdate(null);
	};

	return (
		<>
			<Box className={styles.container}>
				<TableLayout
					title={t("tabName")}
					headerArray={headerArray}
					tableData={tableData}
					primaryButtonText={t("primaryButtonText")}
					primaryButtonClick={() => setShowInviteMembersPopup(true)}
					primaryButtonIconType="email"
					hideSecondaryButton={hideSecondaryButton()}
					secondaryButtonText={t("secondaryButtonText")}
					secondaryButtonIconType="plus"
					secondaryButtonClick={() => {
						setShowAncestorsUsersPopup(true);
						if (appStore.ancestorsUsers.length === 0) {
							appStore.loadAncestorsUsers();
						}
					}}
				/>
			</Box>
			<ConfirmationPopup
				onClose={() => {
					setShowInviteMembersPopup(false);
					setInviteMembersEmails("");
					setErrorMessage(null);
				}}
				title={t("inviteMembersPopup.title")}
				description={t("inviteMembersPopup.description")}
				confirmButtonText={t("inviteMembersPopup.confirmText")}
				open={showInviteMembersPopup}
				onClick={sendInvitation}
				hideCancel={true}
				errorMessage={errorMessage}
				loading={appStore.isPopupLoading}
				disableConfirm={inviteMembersEmails.length === 0 || errorMessage !== null}
			>
				<BriaInput
					alignment={"flex-start"}
					multiline
					className={styles.textFieldStyle}
					height={"108px"}
					type="text"
					placeholder={t("inviteMembersPopup.textFieldPlaceholder")}
					value={inviteMembersEmails}
					onChange={(e: any) => {
						setInviteMembersEmails(e.target.value);
						setErrorMessage(null);
					}}
				/>
			</ConfirmationPopup>
			<ConfirmationPopup
				hideCancel={true}
				onClose={() => showInvitationSentPopup(false)}
				title={t("invitationSentPopup.title")}
				description={t("invitationSentPopup.description")}
				confirmButtonText={t("invitationSentPopup.confirmText")}
				open={invitationSentPopup}
				onClick={() => showInvitationSentPopup(false)}
			/>
			<ConfirmationPopup
				onClose={() => closeConfirmationsPopup()}
				title={t("deleteConfirmationPopup.title", {
					user_name: userToUpdate?.user_name,
				})}
				description={t("deleteConfirmationPopup.description", {
					user_name: userToUpdate?.user_name,
				})}
				confirmButtonText={t("deleteConfirmationPopup.remove")}
				open={deleteConfirmationPopup}
				onClick={() => deleteUser()}
			/>
			<ConfirmationPopup
				onClose={() => setShowAncestorsUsersPopup(false)}
				title={t("ancestorsPopup.title", { organization_name: selectedOrg?.organization?.name })}
				description={t("ancestorsPopup.description")}
				confirmButtonText={t("ancestorsPopup.confirmText")}
				open={showAncestorsUsersPopup}
				disableConfirm={ancestorsSelectedUsers.length === 0}
				onClick={() => {
					grantUsersPermission();
				}}
			>
				<LoadingPlaceholder className={styles.loaderStyle} isLoading={appStore.isPopupLoading}>
					<GrantPermissions
						items={appStore.ancestorsUsers}
						roles={[
							USER_ORGANIZATION_ROLES.ADMIN_HIDDEN,
							USER_ORGANIZATION_ROLES.USER_HIDDEN,
							USER_ORGANIZATION_ROLES.USER,
							USER_ORGANIZATION_ROLES.ADMIN,
						]}
						ancestorsRole={ancestorsSelectedRole}
						setAncestorsRole={setAncestorsSelectedRole}
						ancestorsSelectedUsers={ancestorsSelectedUsers}
						setAncestorsSelectedUsers={setAncestorsSelectedUsers}
					/>
				</LoadingPlaceholder>
			</ConfirmationPopup>
			<ConfirmationPopup
				hideCancel={true}
				onClose={() => setOwnershipTransferPopup(false)}
				title={t("ownershipTransferPopup.title")}
				description={t("ownershipTransferPopup.description", {
					organization: getSelectedOrganization()?.organization?.name,
				})}
				subTitle={t("ownershipTransferPopup.subTitle")}
				confirmButtonText={t("ownershipTransferPopup.confirmText")}
				open={ownershipTransferPopup}
				onClick={handleOwnershipTransfer}
			/>
		</>
	);
};

export default observer(Users);
