import Image1 from "../assets/images/models/2.2 (Latest highest quality).png";
import Image3 from "../assets/images/models/2.2 Fast (1).png";
import Image2_3_Fast from "../assets/images/models/2.3 fast.png";
import Image7 from "../assets/images/models/BRIA 1.4 Inpainting.png";
import Image13 from "../assets/images/models/BRIA 2.3 ControlNet ColorGrid.png";
import Image15 from "../assets/images/models/BRIA 2.3 ControlNet Inpainting Fast.png";
import ImageControlNetCanny2_3 from "../assets/images/models/BRIA 2.3 ControlNet-Canny.png";
import Image2_3_Depth from "../assets/images/models/BRIA 2.3 ControlNet-CannyDepth.png";
import Image2_3_Recoloring from "../assets/images/models/BRIA 2.3 ControlNet-CannyRecoloring.png";
import Image14 from "../assets/images/models/BRIA 2.3 Fast LORA.png";
import Image5 from "../assets/images/models/Background Generation.png";
import ImagePrompt from "../assets/images/models/Image - Prompt.png";
import Image8 from "../assets/images/models/ax-1.png";
import Image11 from "../assets/images/models/ax-4.png";
import GenFill from "../assets/images/pngs/APICards/API-Erase-And-Replace.png";
import HuggingFace from "../assets/images/svgs/huggingface.svg";
import { IModelCardProps } from "../components/Models/ModelCardComponent";
import RouterConstants from "./RouterConstants.ts";

export enum ModelCardType {
	FOUNDATION_MODEL = "foundation-models",
	AUXILIARY_MODEL = "auxiliary-models",
	ACCESS_MODEL = "access-models",
}

export const ModelIntros = [
	{
		type: ModelCardType.FOUNDATION_MODEL,
		title: "foundationModels.intro.title",
		descriptions: ["foundationModels.intro.description"],
	},
	{
		type: ModelCardType.AUXILIARY_MODEL,
		title: "auxiliaryModels.intro.title",
		descriptions: ["auxiliaryModels.intro.description"],
	},
	{
		type: ModelCardType.ACCESS_MODEL,
		title: "accessModel.intro.title",
		descriptions: [
			"accessModel.intro.description1",
			"accessModel.intro.description2",
			"accessModel.intro.description3",
		],
	},
];

export const ModelCards: IModelCardProps[] = [
	{
		type: ModelCardType.FOUNDATION_MODEL,
		title: "foundationModels.titles.bria-2.3-fast",
		description: "foundationModels.descriptions.bria-2.3-fast",
		image: Image2_3_Fast,
		buttons: [
			{
				name: "foundationModels.buttons.viewModel",
				link: "https://huggingface.co/briaai/BRIA-2.3-FAST",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			{
				name: "foundationModels.buttons.demo",
				link: "https://huggingface.co/spaces/briaai/BRIA-2.3-FAST",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			{
				name: "foundationModels.buttons.requestAccess",
				link: RouterConstants.ACCESS_MODELS.fullPath,
				type: "redirect",
			},
		],
	},
	{
		type: ModelCardType.FOUNDATION_MODEL,
		title: "foundationModels.titles.latestHighestQuality-2.2",
		description: "foundationModels.descriptions.latestHighestQuality-2.2",
		image: Image1,
		buttons: [
			{
				name: "foundationModels.buttons.viewModel",
				link: "https://huggingface.co/briaai/BRIA-2.3-BETA",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			{
				name: "foundationModels.buttons.demo",
				link: "https://huggingface.co/spaces/briaai/BRIA-2.3",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			{
				name: "foundationModels.buttons.requestAccess",
				link: RouterConstants.ACCESS_MODELS.fullPath,
				type: "redirect",
			},
		],
	},
	{
		type: ModelCardType.FOUNDATION_MODEL,
		title: "foundationModels.titles.bria-2.2-hd",
		description: "foundationModels.descriptions.bria-2.2-hd",
		image: Image3,
		buttons: [
			{
				name: "foundationModels.buttons.viewModel",
				link: "https://huggingface.co/briaai/BRIA-2.2-HD",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			{
				name: "foundationModels.buttons.demo",
				link: "https://huggingface.co/spaces/briaai/BRIA-2.2-HD",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			{
				name: "foundationModels.buttons.requestAccess",
				link: RouterConstants.ACCESS_MODELS.fullPath,
				type: "redirect",
			},
		],
	},
	{
		type: ModelCardType.FOUNDATION_MODEL,
		title: "foundationModels.titles.bria-2.3-Inpainting",
		description: "foundationModels.descriptions.bria-2.3-Inpainting",
		image: Image7,
		buttons: [
			{
				name: "foundationModels.buttons.viewModel",
				link: "https://huggingface.co/briaai/BRIA-2.3-Inpainting",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			{
				name: "foundationModels.buttons.demo",
				link: "https://huggingface.co/spaces/briaai/BRIA-2.3-Inpainting",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			{
				name: "foundationModels.buttons.requestAccess",
				link: RouterConstants.ACCESS_MODELS.fullPath,
				type: "redirect",
			},
		],
	},
	{
		type: ModelCardType.AUXILIARY_MODEL,
		title: "auxiliaryModels.titles.bria-2.3-fast-LORA",
		description: "auxiliaryModels.descriptions.bria-2.3-fast-LORA",
		image: Image14,
		buttons: [
			{
				name: "auxiliaryModels.buttons.viewModel",
				link: "https://huggingface.co/briaai/BRIA-2.3-FAST-LORA",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			{
				name: "auxiliaryModels.buttons.downloadCode",
				link: "https://huggingface.co/briaai/BRIA-2.3-FAST-LORA/tree/main",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			{
				name: "auxiliaryModels.buttons.demo",
				link: "https://huggingface.co/spaces/briaai/BRIA-2.3-FAST-LORA",
				type: "_blank",
				iconSrc: HuggingFace,
			},
		],
	},
	{
		type: ModelCardType.AUXILIARY_MODEL,
		title: "auxiliaryModels.titles.bria-2.3-image-prompt",
		description: "auxiliaryModels.descriptions.bria-2.3-image-prompt",
		image: ImagePrompt,
		buttons: [
			{
				name: "auxiliaryModels.buttons.viewModel",
				link: "https://huggingface.co/briaai/Image-Prompt",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			{
				name: "auxiliaryModels.buttons.downloadCode",
				link: "https://huggingface.co/briaai/Image-Prompt/tree/main",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			{
				name: "auxiliaryModels.buttons.demo",
				link: "https://huggingface.co/spaces/briaai/Image-Prompt",
				type: "_blank",
				iconSrc: HuggingFace,
			},
		],
	},
	{
		type: ModelCardType.AUXILIARY_MODEL,
		title: "auxiliaryModels.titles.gen_fill",
		description: "auxiliaryModels.descriptions.gen_fill",
		image: GenFill,
		buttons: [
			{
				name: "auxiliaryModels.buttons.viewModel",
				link: "https://huggingface.co/briaai/BRIA-2.3-ControlNet-Generative-Fill",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			{
				name: "auxiliaryModels.buttons.downloadCode",
				link: "https://huggingface.co/briaai/BRIA-2.3-ControlNet-Generative-Fill/tree/main",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			{
				name: "auxiliaryModels.buttons.demo",
				link: "https://huggingface.co/spaces/briaai/BRIA-Generative-Fill-API",
				type: "_blank",
				iconSrc: HuggingFace,
			},
		],
	},
	{
		type: ModelCardType.AUXILIARY_MODEL,
		title: "auxiliaryModels.titles.backgroundGeneration",
		description: "auxiliaryModels.descriptions.backgroundGeneration",
		image: Image5,
		buttons: [
			{
				name: "auxiliaryModels.buttons.viewModel",
				link: "https://huggingface.co/briaai/BRIA-2.3-ControlNet-BG-Gen",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			{
				name: "auxiliaryModels.buttons.demo",
				link: "https://huggingface.co/spaces/briaai/BRIA-Background-Generation",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			{
				name: "auxiliaryModels.buttons.requestAccess",
				link: RouterConstants.ACCESS_MODELS.fullPath,
				type: "redirect",
			},
		],
	},
	{
		type: ModelCardType.AUXILIARY_MODEL,
		title: "auxiliaryModels.titles.removeBackground",
		description: "auxiliaryModels.descriptions.removeBackground",
		image: Image8,
		buttons: [
			{
				name: "auxiliaryModels.buttons.viewModel",
				link: "https://huggingface.co/briaai/RMBG-2.0",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			{
				name: "auxiliaryModels.buttons.downloadCode",
				link: "https://huggingface.co/briaai/RMBG-2.0/tree/main",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			{
				name: "auxiliaryModels.buttons.demo",
				link: "https://huggingface.co/spaces/briaai/BRIA-RMBG-2.0",
				type: "_blank",
				iconSrc: HuggingFace,
			},
		],
	},
	{
		type: ModelCardType.AUXILIARY_MODEL,
		title: "auxiliaryModels.titles.increaseResolution",
		description: "auxiliaryModels.descriptions.increaseResolution",
		image: Image11,
		buttons: [
			{ name: "auxiliaryModels.buttons.viewModel", link: "", type: "_blank" },
			{ name: "auxiliaryModels.buttons.downloadCode", link: "", type: "redirect" },
			{ name: "auxiliaryModels.buttons.demo", link: "", type: "_blank" },
		],
	},
	{
		type: ModelCardType.AUXILIARY_MODEL,
		title: "auxiliaryModels.titles.bria-2.3-controlNetColorGrid",
		description: "auxiliaryModels.descriptions.bria-2.3-controlNetColorGrid",
		image: Image13,
		buttons: [
			{
				name: "auxiliaryModels.buttons.viewModel",
				link: "https://huggingface.co/briaai/BRIA-2.3-ControlNet-ColorGrid",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			{
				name: "auxiliaryModels.buttons.downloadCode",
				link: "https://huggingface.co/briaai/BRIA-2.3-ControlNet-ColorGrid/tree/main",
				type: "_blank",
				iconSrc: HuggingFace,
			},
		],
	},
	{
		type: ModelCardType.AUXILIARY_MODEL,
		title: "auxiliaryModels.titles.bria-2.3-controlNetCanny",
		description: "auxiliaryModels.descriptions.bria-2.3-controlNetCanny",
		image: ImageControlNetCanny2_3,
		buttons: [
			{
				name: "auxiliaryModels.buttons.viewModel",
				link: "https://huggingface.co/briaai/BRIA-2.3-ControlNet-Canny",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			{
				name: "auxiliaryModels.buttons.downloadCode",
				link: "https://huggingface.co/briaai/BRIA-2.3-ControlNet-Canny/tree/main",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			// {
			// 	name: "auxiliaryModels.buttons.demo",
			// 	link: "https://huggingface.co/spaces/briaai/BRIA-2.3-ControlNets",
			// 	type: "_blank",
			// 	iconSrc: HuggingFace,
			// },
		],
	},
	{
		type: ModelCardType.AUXILIARY_MODEL,
		title: "auxiliaryModels.titles.bria-2.3-depthModel",
		description: "auxiliaryModels.descriptions.bria-2.3-depthModel",
		image: Image2_3_Depth,
		buttons: [
			{
				name: "auxiliaryModels.buttons.viewModel",
				link: "https://huggingface.co/briaai/BRIA-2.3-ControlNet-Depth",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			{
				name: "auxiliaryModels.buttons.downloadCode",
				link: "https://huggingface.co/briaai/BRIA-2.3-ControlNet-Depth/tree/main",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			// {
			// 	name: "auxiliaryModels.buttons.demo",
			// 	link: "https://huggingface.co/spaces/briaai/BRIA-2.3-ControlNet-Depth",
			// 	type: "_blank",
			// 	iconSrc: HuggingFace,
			// },
		],
	},
	{
		type: ModelCardType.AUXILIARY_MODEL,
		title: "auxiliaryModels.titles.bria-2.3-controlNetInpaintingFast",
		description: "auxiliaryModels.descriptions.bria-2.3-controlNetInpaintingFast",
		image: Image15,
		buttons: [
			{
				name: "auxiliaryModels.buttons.viewModel",
				link: "https://huggingface.co/briaai/DEV-ControlNetInpaintingFast",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			{
				name: "auxiliaryModels.buttons.downloadCode",
				link: "https://huggingface.co/briaai/DEV-ControlNetInpaintingFast/tree/main",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			// {
			// 	name: "auxiliaryModels.buttons.demo",
			// 	link: "https://huggingface.co/spaces/briaai/BRIA-2.3-FAST-LORA",
			// 	type: "_blank",
			// 	iconSrc: HuggingFace,
			// },
		],
	},
	{
		type: ModelCardType.AUXILIARY_MODEL,
		title: "auxiliaryModels.titles.bria-2.3-recoloringModel",
		description: "auxiliaryModels.descriptions.bria-2.3-recoloringModel",
		image: Image2_3_Recoloring,
		buttons: [
			{
				name: "auxiliaryModels.buttons.viewModel",
				link: "https://huggingface.co/briaai/BRIA-2.3-ControlNet-Recoloring",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			{
				name: "auxiliaryModels.buttons.downloadCode",
				link: "https://huggingface.co/briaai/BRIA-2.3-ControlNet-Recoloring/tree/main",
				type: "_blank",
				iconSrc: HuggingFace,
			},
			// {
			// 	name: "auxiliaryModels.buttons.demo",
			// 	link: "https://huggingface.co/spaces/briaai/BRIA-2.3-ControlNet-Recoloring",
			// 	type: "_blank",
			// 	iconSrc: HuggingFace,
			// },
		],
	},
];
