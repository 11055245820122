import CloseIcon from "@mui/icons-material/CloseTwoTone";
import { Alert, Box, Snackbar, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../components/common/BriaButton/BriaButton.tsx";
import CustomDialog from "../components/common/CustomDialog/CustomDialog.tsx";
import HorizontalLinearStepper from "../components/common/HorizontalLinearStepper/HorizontalLinearStepper.tsx";
import LoadingPlaceholder from "../components/common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import { useAppStore } from "../hooks/useStores.tsx";
import BriaDesignEditor from "./BriaDesignEditor.tsx";
import useCampaignHeaderUtils from "./CustomUtils/CampaignHeaderUtils.tsx";
import { PopUpSteps } from "./constants/mock-data.ts";
import useDesignEditorContext from "./hooks/useDesignEditorContext.ts";
import { useEditor } from "./hooks/useEditor.tsx";
import styles from "./styles/DesignEditorPopup.module.scss";
import { ObjectsEnum } from "./views/DesignEditor/components/Panels/panelItems/index.ts";
import { useSmartImageUtils } from "./views/DesignEditor/utils/smartImageUtils.ts";

enum StepperActions {
	Back = "back",
	Next = "next",
}

const BriaDesignEditorPopup = () => {
	const editor = useEditor();
	const { setCurrentScene } = useDesignEditorContext();
	const { saveNewCampaign, isLoading } = useCampaignHeaderUtils();
	const { designEditorStore, brandsDefinitionStore, campaignStore, brandFontStore, imagesStore, playgroundStore } =
		useAppStore();
	const { resetRectangleInAGroup } = useSmartImageUtils();
	const { t } = useTranslation("translation", { keyPrefix: "editor.popup" });
	const [successMessage, setSuccessMessage] = useState<string | null>(null);
	const BRANDS_INDEX = PopUpSteps.indexOf("brands");
	const SMART_IMAGE_INDEX = PopUpSteps.indexOf("images");
	const TEMPLATES_INDEX = PopUpSteps.indexOf("templates");
	const CAMPAIGN_INDEX = PopUpSteps.indexOf("campaigns");
	const isLastStep = designEditorStore.activeStep + 1 === designEditorStore.editorStepperSteps.length;
	const isFirstStep = designEditorStore.activeStep === TEMPLATES_INDEX;
	const isSmartImageStepAndHasNoSmartImage =
		designEditorStore.activeStep === SMART_IMAGE_INDEX && !designEditorStore.hasSmartImages;
	const isButtonDisabled =
		designEditorStore.isTemplateEmptyState ||
		brandsDefinitionStore.isApplyingBrandConfigurations ||
		brandsDefinitionStore.isResettingBrandConfigurations ||
		designEditorStore.isLoading ||
		campaignStore.isLoadingTemplates ||
		campaignStore.isLoadingPlacements ||
		brandFontStore.isLoading ||
		imagesStore.isGeneratingImages ||
		designEditorStore.isGenerateAdsLoading ||
		designEditorStore.isLoadingSmartImageStep ||
		imagesStore.isUploadingSmartImageMainObject ||
		(designEditorStore.activeStep === BRANDS_INDEX && brandsDefinitionStore.isLoading) ||
		imagesStore.isLoadingWizardImages ||
		designEditorStore.isResettingWizardImages;

	const handleCloseClick = () => {
		designEditorStore.setProperty("designEditorPopup", false);
		imagesStore.clearSmartImageForm();
		playgroundStore.setTextToGenerate("");
	};

	const pressStepperAction = (action: StepperActions) => {
		let newStep = designEditorStore.activeStep;

		if (action === StepperActions.Next) {
			newStep++;
			if (newStep === designEditorStore.editorStepperDisabledStep) {
				newStep++;
			}
		} else if (action === StepperActions.Back) {
			newStep--;
			if (newStep === designEditorStore.editorStepperDisabledStep) {
				newStep--;
			}
		}

		// Ensure the new step is within valid bounds
		const maxSteps = designEditorStore.editorStepperSteps.length - 1;
		if (newStep < 0 || newStep > maxSteps) {
			return;
		}

		setActiveStep(newStep);
	};

	const setActiveStep = (newStep: number) => {
		designEditorStore.setProperty("newStep", newStep);
	};

	const renderPopupActionsHeader = () => (
		<Box className={styles.popupActionsHeader}>
			<Box className={styles.row1}>
				<Typography className={styles.title}>{t("title")}</Typography>
				<CloseIcon onClick={handleCloseClick} className={styles.closeIcon} />
			</Box>
			<Box className={styles.separator} />
			<Box className={styles.row2}>
				<HorizontalLinearStepper
					steps={designEditorStore.editorStepperSteps.map((step) => step.title)}
					activeStep={designEditorStore.activeStep}
					readonly
					setActiveStep={setActiveStep}
					disabledStep={designEditorStore.editorStepperDisabledStep}
					disableAll={designEditorStore.activeStep === CAMPAIGN_INDEX}
				/>
				<Box className={styles.buttons}>
					{designEditorStore.isLoadingTemplate ? (
						<Box className={styles.loadingContainer}>
							<Typography>{t("buttons.templateLoading")}</Typography>
							<LoadingPlaceholder className={styles.loadingPlaceholder} isLoading={true} size={15} />
						</Box>
					) : (
						<>
							{!designEditorStore.hideCampaignResultContent ? (
								<BriaButton
									onClick={async () => {
										campaignStore.setProperty("isSavingToMyCampaign", true);
										const newTemplateId = await saveNewCampaign({
											templateName: designEditorStore.campaignName,
										});
										newTemplateId && designEditorStore.setProperty("designEditorPopup", false);
									}}
									className={styles.saveToMyCampaignButton}
									buttonType="primary"
									disabled={designEditorStore.campaignUpdatedTemplate === undefined || isLoading}
									loading={isLoading}
								>
									{t("buttons.saveToMyCampaign")}
								</BriaButton>
							) : (
								<>
									<BriaButton
										onClick={async () => {
											pressStepperAction(StepperActions.Back);
											if (designEditorStore.activeStep === SMART_IMAGE_INDEX) {
												if (editor) {
													const _currentScene = editor.scene.exportToJSON();
													await resetRectangleInAGroup(
														_currentScene,
														ObjectsEnum.OuterRectangle,
													);
													await resetRectangleInAGroup(
														_currentScene,
														ObjectsEnum.InnerRectangle,
													);
													const updatedPreview = (await editor?.renderer.render(
														_currentScene,
													)) as string;
													const updatedScene = {
														..._currentScene,
														preview: updatedPreview,
														duration: 1000,
													};
													setCurrentScene(updatedScene);
												}
											}
										}}
										className={styles.button}
										buttonType="textMedium"
										disabled={isFirstStep || isButtonDisabled}
									>
										{t("buttons.previous")}
									</BriaButton>
									<BriaButton
										onClick={() => {
											pressStepperAction(StepperActions.Next);
										}}
										className={styles.button}
										buttonType="primary"
										disabled={isLastStep || isButtonDisabled || isSmartImageStepAndHasNoSmartImage}
										loading={designEditorStore.isLoadingSmartImageStep}
									>
										{t("buttons.next")}
									</BriaButton>
								</>
							)}
						</>
					)}
				</Box>
			</Box>
			<Box className={styles.separator} />
		</Box>
	);

	return (
		<CustomDialog
			open={designEditorStore.designEditorPopup}
			onClose={() => {
				handleCloseClick();
			}}
			containerClassName={styles.container}
		>
			{renderPopupActionsHeader()}
			<BriaDesignEditor isPopupView={true} />
			<Snackbar
				autoHideDuration={2000}
				open={!!successMessage}
				onClose={() => setSuccessMessage(null)}
				className={styles.saveSuccessToast}
			>
				<Alert severity="success">{successMessage}</Alert>
			</Snackbar>
		</CustomDialog>
	);
};

const ObservedComponent = observer(BriaDesignEditorPopup);
export default ObservedComponent;
