import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Divider } from "rsuite";
import TextToImagePopup from "../../../../../../../components/TextToImage/TextToImagePopup/TextToImagePopup";
import BriaButton from "../../../../../../../components/common/BriaButton/BriaButton";
import { useAppStore } from "../../../../../../../hooks/useStores";
import useDesignEditorUtils from "../../../../../../CustomUtils/UseDesignEditor";
import styles from "./WizardImages.module.scss";

const WizardImages = () => {
	const { t } = useTranslation("translation", { keyPrefix: "editor.tabs.wizardImages" });
	const { resetWizardImages } = useDesignEditorUtils();
	const { designEditorStore } = useAppStore();

	return (
		<Box className={styles.container}>
			<Typography className={styles.title}> {t("replaceImages")} </Typography>
			<Typography className={styles.description}>{t("descriptions")}</Typography>
			<Divider className={styles.divider} />
			<BriaButton
				className={styles.restAllImagesButton}
				buttonType="textMedium"
				loading={designEditorStore.isResettingWizardImages}
				onClick={resetWizardImages}
			>
				{t("restAllImages")}
			</BriaButton>
			<TextToImagePopup />
		</Box>
	);
};

const ObservedComponent = observer(WizardImages);
export default ObservedComponent;
