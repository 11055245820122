import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { MouseEvent, useEffect, useRef } from "react";
import InfoIcon from "../../../../../assets/images/icons/InfoIcon";
import BriaTooltip from "../../../../../components/common/BriaTooltip/BriaTooltip";
import styles from "./ConfigButtonLabel.module.scss";

interface IProps {
	label?: string;
	labelClassName?: string;
	labelSize?: string;
	info?: string;
	className?: string;
	isOpen: boolean;
	onToggle: () => void;
}

const ConfigButtonLabel = ({ label, labelClassName, labelSize, info, className, isOpen, onToggle }: IProps) => {
	const infoIconRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (infoIconRef.current && !infoIconRef.current.contains(event.target as Node)) {
				onToggle();
			}
		};

		document.addEventListener("click", handleClickOutside as unknown as EventListener);
		return () => {
			document.removeEventListener("click", handleClickOutside as unknown as EventListener);
		};
	}, []);

	const handleIconClick = (event: MouseEvent<SVGSVGElement>) => {
		event.stopPropagation();
		onToggle();
	};

	return (
		<Box className={clsx(styles.container, className)}>
			<Box className={clsx(styles.textLabel, labelClassName)}>
				<Typography
					className={clsx(styles.inputLabel, {
						[styles.largeLabel]: labelSize === "large",
					})}
				>
					{label || <span>&nbsp;</span>}
				</Typography>
				{info && (
					<BriaTooltip
						title={info}
						open={isOpen}
						className={styles.info}
						componentsProps={{ tooltip: { className: styles.tooltip, ref: infoIconRef } }}
					>
						<InfoIcon className={styles.infoIcon} onClick={handleIconClick} />
					</BriaTooltip>
				)}
			</Box>
		</Box>
	);
};

export default observer(ConfigButtonLabel);
