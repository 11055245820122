import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import SubscribedLabel from "../../../assets/images/svgs/subscribed-label.svg";
import LinkConstants from "../../../constants/LinkConstants.ts";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { OrgFeatures } from "../../../models/billing.ts";
import { EventLogTypesEnum } from "../../../models/common.ts";
import RequestAccessContainer from "../../TailoredGeneration/RequestAccessContainer/RequestAccessContainer.tsx";
import BriaButton from "../../common/BriaButton/BriaButton.tsx";
import styles from "./ModelAccess.module.scss";

const ModelAccess = () => {
	const { authStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "models_page.accessModel" });
	return (
		<Box className={styles.container}>
			<Box className={styles.cards}>
				<Typography className={styles.title}>{t("cards.title")}</Typography>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
						<Box
							className={clsx({
								[styles.card]: true,
								[styles.disableCard]: authStore.isFeatureEnabled(OrgFeatures.FOUNDATION_MODELS),
							})}
						>
							<Typography className={styles.cardStep}>{t("cards.card1.step")}</Typography>
							<Typography className={styles.cardTitle}>{t("cards.card1.title")}</Typography>
							<Typography className={styles.cardDescription}>{t("cards.card1.description")}</Typography>
							{authStore.isFeatureEnabled(OrgFeatures.FOUNDATION_MODELS) ? (
								<Box className={styles.subscribed}>
									<img className={styles.image} src={SubscribedLabel} alt="" />
								</Box>
							) : (
								<Box className={styles.quoteContainer}>
									<RequestAccessContainer
										showCrown={false}
										requestFeature={t("key")}
										eventLog={{ eventType: EventLogTypesEnum.REQUEST_FOR_FOUNDATION_MODELS }}
										buttonType="secondaryMedium"
										buttonMessage={t("cards.buttons.requestAQuote")}
										requestTitle={t("cards.card1.requestTitle")}
										requestMessage={t("cards.card1.requestMessage")}
										submissionMessage={t("cards.card1.submissionMessage")}
									/>
								</Box>
							)}
						</Box>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
						<Box className={styles.card}>
							<Typography className={styles.cardStep}>{t("cards.card2.step")}</Typography>
							<Typography className={styles.cardTitle}>{t("cards.card2.title")}</Typography>
							<Typography className={styles.cardDescription}>{t("cards.card2.description")}</Typography>
							<BriaButton
								className={styles.cardButton}
								onClick={() => {
									window.open(LinkConstants.BRIA_HUGGING_FACE, "_blank");
								}}
								buttonType="secondaryMedium"
							>
								{t("cards.buttons.downloadSourceCode")}
							</BriaButton>
						</Box>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
						<Box className={styles.card}>
							<Typography className={styles.cardStep}>{t("cards.card3.step")}</Typography>
							<Typography className={styles.cardTitle}>{t("cards.card3.title")}</Typography>
							<Typography className={styles.cardDescription}>{t("cards.card3.description")}</Typography>
							<BriaButton
								className={styles.cardButton}
								onClick={() => {
									window.open(LinkConstants.BRIA_AGENT, "_blank");
								}}
								buttonType="secondaryMedium"
							>
								{t("cards.buttons.installBriaAgent")}
							</BriaButton>
						</Box>
					</Grid>
				</Grid>
			</Box>
			<Box className={styles.faqs}>
				<Typography className={styles.title}>{t("faqs.title")}</Typography>
				<Typography className={styles.firstQuestion}>{t("faqs.question1")}</Typography>
				<Typography className={styles.answer}>{t("faqs.answer1")}</Typography>
				<Typography className={styles.question}>{t("faqs.question2")}</Typography>
				<Typography className={styles.answer}>{t("faqs.answer2")}</Typography>
				<Typography className={styles.question}>{t("faqs.question3")}</Typography>
				<Typography className={styles.answer}>{t("faqs.answer3")}</Typography>
				<Typography className={styles.question}>{t("faqs.question4")}</Typography>
				<Typography className={styles.answer}>{t("faqs.answer4")}</Typography>
			</Box>
		</Box>
	);
};

const ObservedComponent = observer(ModelAccess);
export default ObservedComponent;
