import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../../assets/images/icons/DeleteIcon";
import BriaTooltip from "../../../../components/common/BriaTooltip/BriaTooltip.tsx";
import LoadingAnimation from "../../../../components/common/LoadingAnimation/LoadingAnimation";
import Video from "../../../../components/common/Video/Video.tsx";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import { PlaygroundVideoResult } from "../../../../models/playground.ts";
import styles from "./VideoResults.module.scss";

type Props = {
	playgroundResult: PlaygroundVideoResult;
};

const VideoResults = ({ playgroundResult }: Props) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.sandboxAPI.result" });
	const { playgroundStore, sandboxAPIStore } = useAppStore();
	return (
		playgroundResult && (
			<Box className={styles.result}>
				{sandboxAPIStore.isGeneratingResults && (
					<Box className={styles.overlay}>
						<LoadingAnimation
							className={styles.loaderBar}
							loading={sandboxAPIStore.isGeneratingResults}
							progressBarTime={300}
							showAnimation={true}
						/>
					</Box>
				)}
				<BriaTooltip className={styles.deleteButton} title={t("delete")}>
					<DeleteIcon
						className={styles.icon}
						onClick={() => {
							playgroundStore.clearVideoResults();
							sandboxAPIStore.abortResultsGeneration();
							sandboxAPIStore.isGeneratingResults = false;
						}}
					/>
				</BriaTooltip>
				<Video video={playgroundResult}></Video>
			</Box>
		)
	);
};

export default observer(VideoResults);
