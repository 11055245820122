import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BriaDropDown, { DropDownItem } from "../../../../../../../../components/common/BriaDropDown/BriaDropDown.tsx";
import styles from "./MediaComponent.module.scss";

export type IProps = {
	selectedPlacements: string[] | string;
	items: DropDownItem[];
	onChangePlacementDropdown: (value: string[] | string) => void;
	loading: boolean;
	multiple?: boolean;
	selectMultipleCheckbox?: boolean;
	disabled?: boolean;
};

const MediaComponent = ({
	selectedPlacements,
	items,
	onChangePlacementDropdown,
	loading,
	multiple = true,
	selectMultipleCheckbox = true,
	disabled = false,
}: IProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "editor.tabs.templates" });

	return (
		<>
			<Box className={styles.mediaContainer}>
				<Typography className={styles.mediaLabel}>{t("mediaDestination")} </Typography>
				<BriaDropDown
					value={Array.isArray(selectedPlacements) ? selectedPlacements : [selectedPlacements]}
					items={items}
					height={"32px"}
					maxHeight={"32px"}
					multiple={multiple}
					width={"100%"}
					menuMaxHeight={"300px"}
					menuMaxWidth={"300px"}
					placeholder={t("selectMediaDestination")}
					selectMultipleCheckbox={selectMultipleCheckbox}
					loading={loading}
					disabled={disabled}
					onChange={(e: any) => {
						const value = e.target.value;
						onChangePlacementDropdown(value);
					}}
					renderValue={(selected: any[]) => {
						if (selected.length === 0 || (selected.length == 1 && selected[0] == "")) {
							return <>{t("selectMediaDestination")}</>;
						}
						if (multiple) {
							return selected.length > 1 ? `${selected[0]} ...` : selected[0];
						}
						return selected;
					}}
				/>
			</Box>
		</>
	);
};

export default MediaComponent;
