import { Box } from "@mui/material";
import clsx from "clsx";
import LoadingAnimation from "../../../../../components/common/LoadingAnimation/LoadingAnimation.tsx";
import { useAppStore } from "../../../../../hooks/useStores.tsx";
import Canvas from "../../../../components/Canvas";
import { ICanvasProps } from "../../../../components/Canvas/canvas.tsx";
import useDesignEditorContext from "../../../../hooks/useDesignEditorContext";
import { useEditor } from "../../../../hooks/useEditor";
import ContextMenu from "../ContextMenu";
import Playback from "../Playback";
import styles from "./Canvas.module.scss";
export default function (props: ICanvasProps) {
	const { displayPlayback } = useDesignEditorContext();
	const editor = useEditor();
	editor?.canvas?.setBackgroundColor("#FAFAFA");
	const { campaignStore } = useAppStore();
	return (
		<>
			<div
				className={clsx({
					[styles.canvasContainer]: true,
					[styles.canvasContainerEffects]: campaignStore.isResizingCanvas,
				})}
			>
				{displayPlayback && <Playback />}
				<ContextMenu />
				<Canvas
					id={props.id}
					config={
						props.config ?? {
							background: "#f1f2f6",
							controlsPosition: {
								rotation: "BOTTOM",
							},
							shadow: {
								blur: 4,
								color: "#fcfcfc",
								offsetX: 0,
								offsetY: 0,
							},
						}
					}
				/>
			</div>
			{campaignStore.isResizingCanvas && (
				<Box
					className={clsx({
						[styles.loadingContainer]: campaignStore.isResizingCanvas,
					})}
				>
					<LoadingAnimation loading={campaignStore.isResizingCanvas} progressBarTime={10} />
				</Box>
			)}
		</>
	);
}
