import imageCompression from "browser-image-compression";
import { makeAutoObservable, runInAction } from "mobx";
import { ANALYTICS_EVENTS } from "../../analytics-store.tsx";
import { IMAGE_COMPRESSION_CONFIG } from "../../config/image.ts";
import { IRootStore } from "../../mobx/root-store.tsx";
import { BoxGranularityEnum, ObjectsRefine, ObjectsRemoveOrReplace, RectType } from "../../models/aiEditor.ts";
import { ImageToImageConfigType } from "../../models/image-to-image.ts";
import QueryService from "../../utils/QueryService.ts";
import useImageUtils from "../../utils/useImageUtils.tsx";

export interface IObjectsStore {
	box: RectType;
	refine: ObjectsRefine;
	removeOrReplace: ObjectsRemoveOrReplace;
	isLoading: boolean;
	isUploadingOriginal: boolean;
	isError: boolean;

	setProperty<K extends keyof ObjectsStore>(key: K, value: ObjectsStore[K]): void;

	handleUpload(e: React.ChangeEvent<HTMLInputElement>): Promise<void>;

	handleDelete(): Promise<void>;

	refineObject(): Promise<void>;
}

export default class ObjectsStore implements IObjectsStore {
	private queryService: QueryService = new QueryService("/ai-editor/objects");
	private imageUtils = useImageUtils();
	rootStore: IRootStore;
	refine: ObjectsRefine = {};
	removeOrReplace: ObjectsRemoveOrReplace = {};
	box: RectType = {
		x: 0,
		y: 0,
		width: BoxGranularityEnum.Max / 2,
		height: BoxGranularityEnum.Max / 2,
	};
	isLoading: boolean = false;
	isUploadingOriginal: boolean = false;
	isError: boolean = false;

	constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.rootStore = rootStore;
	}

	handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		try {
			this.isUploadingOriginal = true;
			const imgFile: File | null = e.target.files && e.target.files[0];
			e.target.value = "";
			if (!imgFile) {
				throw new Error("No file selected");
			}
			this.refine.originalImage = URL.createObjectURL(imgFile);
			const imageUrl = await this.uploadOriginalImage(imgFile);

			runInAction(() => {
				this.refine.originalImage = imageUrl;
				this.isUploadingOriginal = false;
			});
		} catch (e: any) {
			runInAction(() => {
				this.isUploadingOriginal = true;
				this.isLoading = false;
			});
			return Promise.reject(`Error uploading original image: ${e.message || e.toString()}`);
		}
	};

	private async uploadOriginalImage(imgFile: File): Promise<string> {
		try {
			const compressedFile = await imageCompression(imgFile, IMAGE_COMPRESSION_CONFIG);
			const formData = new FormData();
			formData.append("file", compressedFile);
			const path = await this.queryService.post(`/upload-original`, formData, {
				"Content-Type": "multipart/form-data",
			});
			return path;
		} catch (error: any) {
			throw new Error(`Error uploading image file: ${error.message || error.toString()}`);
		}
	}

	setProperty = async <K extends keyof ObjectsStore>(key: K, value: ObjectsStore[K]) => {
		(this as ObjectsStore)[key] = value;
	};

	handleDelete = async () => {
		this.refine.originalImage = undefined;
	};

	refineObject = async () => {
		try {
			this.isLoading = true;
			const newGeneratedImageUrl = await this.queryService.post(`/refine`, {
				...this.refine,
				image_url: this.rootStore.aiEditorStore.selectedImageUrl,
				original_image_url: this.refine.originalImage,
				box: this.box,
			});
			const itiImage = this.rootStore.playgroundStore
				.getAvailableImages()
				.find((image) => this.rootStore.aiEditorStore.selectedImageUrl === image?.url);
			if (itiImage) {
				this.rootStore.analyticsStore.logImageToImageEvent(ANALYTICS_EVENTS.ITI_REFINE_IMAGE, {
					...itiImage,
					config: {
						...(itiImage.config as ImageToImageConfigType),
						style: {
							...(itiImage.config as ImageToImageConfigType).style,
							tailored_style: this.refine.tailored_style,
							sub_style: this.refine.sub_style,
						},
					} as ImageToImageConfigType,
				});
			}
			await this.imageUtils.pullUntilAvailable(newGeneratedImageUrl.image_url);
			runInAction(async () => {
				this.rootStore.aiEditorStore.setProperty("selectedImageUrl", newGeneratedImageUrl.image_url);
				this.isError = false;
				this.isLoading = false;
			});
		} catch (e: any) {
			runInAction(() => {
				this.isError = true;
				this.isLoading = false;
			});
			return Promise.reject(`Error refining the object: ${e.message || e.toString()}`);
		}
	};
}
