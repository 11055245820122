import { fabric } from "fabric";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useRef } from "react";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { CampaignEntityStatus } from "../../../models/common.ts";
import { CampaignPreviewBtn, UrlPathEntities } from "../../campaign-store.tsx";
import { PanelType } from "../../constants/app-options.ts";
import { PopUpSteps } from "../../constants/mock-data.ts";
import { DesignEditorContext } from "../../contexts/DesignEditor.tsx";
import { useActiveObject } from "../../hooks/useActiveObject.tsx";
import useAppContext from "../../hooks/useAppContext.tsx";
import useDesignEditorContext from "../../hooks/useDesignEditorContext";
import { useEditor } from "../../hooks/useEditor.tsx";
import { ILayer } from "../../types";
import GraphicEditor from "./GraphicEditor";
import { OBJECT_TYPES } from "./components/ContextMenu/ContextMenusData.tsx";
import { ObjectsEnum } from "./components/Panels/panelItems";
import Preview from "./components/Preview";
import { useSmartImageUtils } from "./utils/smartImageUtils.ts";

function DesignEditor(props: { isPopup?: boolean }) {
	const { setActivePanel, activePanel } = useAppContext();
	const pathParts = window.location.pathname.split("/");
	const selectedType = pathParts[3];
	const editor = useEditor();
	const { campaignStore, playgroundStore } = useAppStore();
	let activeObject = useActiveObject() as any;
	const { findObjectFromScene } = useSmartImageUtils();
	const draggedObjectRef = useRef<ILayer | undefined>(undefined);
	const { designEditorStore, uiStore, imagesStore } = useAppStore();
	const { displayPreview, setDisplayPreview } = useDesignEditorContext();
	const { isPopupView } = useContext(DesignEditorContext);
	const SMART_IMAGE_INDEX = PopUpSteps.indexOf("images");
	useEffect(() => {
		const handleTemplateLoading = async () => {
			uiStore.hideSideBar();
			if (designEditorStore.designEditorPopup && designEditorStore.activeStep !== 1) {
				imagesStore.resetSmartImage();
				designEditorStore.setProperty("editorStepperDisabledStep", undefined);
			} else {
				if (campaignStore.isManualTemplateTabSelection && !playgroundStore.isText2ImagePopupOpened) {
					campaignStore.setProperty("isManualTemplateTabSelection", false);
					setActivePanel(PanelType.CAMPAIGNS);
					campaignStore.handleSetSelectedCampaignPreviewButton(CampaignPreviewBtn.PRIVATE);
					if (
						(activePanel !== PanelType.TEMPLATES ||
							(activePanel === PanelType.TEMPLATES &&
								(selectedType === UrlPathEntities.CAMPAIGNS ||
									selectedType === UrlPathEntities.TEMPLATES))) &&
						!campaignStore.isSavingToMyCampaign
					) {
						await campaignStore.loadTemplates(CampaignEntityStatus.DRAFT, false, false);
					}
				}
			}
		};

		handleTemplateLoading();
	}, [designEditorStore.designEditorPopup]);

	useEffect(() => {
		if (editor) {
			editor.canvas.canvas.on("object:added", onAddedObject);
			if (!isPopupView) {
				// @ts-ignore
				editor?.canvas.canvas.on({
					"mouse:up": onMouseUp,
					"object:scaling": onMouseScaling,
					"object:added": onAddedObject,
					"object:moving": onObjectMoving,
				});
			}
		}

		return () => {
			if (designEditorStore.activeStep !== SMART_IMAGE_INDEX && isPopupView) {
				imagesStore.resetSmartImage();
			}

			// @ts-ignore
			editor?.canvas.canvas.on({
				"mouse:up": onMouseUp,
				"object:scaling": onMouseScaling,
				"object:added": onAddedObject,
				"object:moving": onObjectMoving,
			});
		};
	}, [editor]);

	useEffect(() => {
		if (editor) {
			if (!isPopupView) {
				// @ts-ignore
				editor?.canvas.canvas.on({
					"object:modified": handleUpdateActiveObject,
				});
			}
		}
		return () => {
			// @ts-ignore
			editor?.canvas.canvas.on({
				"object:modified": handleUpdateActiveObject,
			});
		};
	}, [editor, activeObject, editor?.canvas?.canvas.getActiveObject()]);

	const onAddedObject = async (e: fabric.IEvent<MouseEvent>) => {
		// Set hasSmartImage variable
		if (!editor) return;
		const _currentScene = editor.scene.exportToJSON();
		const originalImage = await findObjectFromScene(_currentScene, ObjectsEnum.OriginalImage);
		const smartImage = await findObjectFromScene(_currentScene, ObjectsEnum.SmartImage);
		designEditorStore.setProperty("hasSmartImages", !!smartImage && !!originalImage);

		// set originalInnerRectangle: This will save the original values for the reset functionality
		const obj: ILayer = await editor.objects.exportFabricObjectAsLayer(e.target as fabric.Object);
		if (obj && obj.id === ObjectsEnum.InnerRectangle) {
			designEditorStore.setProperty("originalInnerRectangle", obj);
		}
	};

	const onMouseScaling = async (e: fabric.IEvent<MouseEvent>) => {
		if (!editor) return;
		const obj: ILayer = await editor.objects.exportFabricObjectAsLayer(e.target as fabric.Object);
		if (obj?.id === ObjectsEnum.InnerRectangle) {
			designEditorStore.setProperty("originalInnerRectangle", obj);
		}
		const object = e.target;
		// this snippt of code to control both Horizontal border radius and  Vertical border radius to keep it
		// consistant when resizing the rectangle shape
		const isValidScalableStaticRect =
			object &&
			object.type === "StaticRect" &&
			typeof object.width === "number" &&
			typeof object.scaleX === "number" &&
			typeof object.height === "number" &&
			typeof object.scaleY === "number" &&
			typeof object.rx === "number" &&
			typeof object.ry === "number";
		if (isValidScalableStaticRect) {
			const obj = object as {
				width: number;
				scaleX: number;
				height: number;
				scaleY: number;
				rx: number;
				ry: number;
				set: (props: Partial<typeof object>) => void;
				setCoords: () => void;
			};

			let horizontalBorderRadius = obj.rx * obj.scaleX;
			let verticalBorderRadius = obj.ry * obj.scaleY;
			if (obj.rx != 0 && obj.rx < 20) {
				horizontalBorderRadius = 20;
				verticalBorderRadius = 20;
			}
			const newWidth = obj.width * obj.scaleX;
			const newHeight = obj.height * obj.scaleY;
			const inverseScale = Math.min(horizontalBorderRadius, verticalBorderRadius);
			object.set({
				width: newWidth,
				height: newHeight,
				scaleX: 1,
				scaleY: 1,
				rx: inverseScale,
				ry: inverseScale,
			});
			object.setCoords();
		}
	};

	const onMouseUp = async () => {
		if (!editor) return;
		if (draggedObjectRef.current?.id === ObjectsEnum.InnerRectangle) {
			designEditorStore.setProperty("originalInnerRectangle", draggedObjectRef.current);
		}

		draggedObjectRef.current = undefined;
	};

	const onObjectMoving = async (e: fabric.IEvent<MouseEvent>) => {
		if (!editor) return;
		const fabricObject = e.target;
		if (fabricObject?.id === ObjectsEnum.InnerRectangle) {
			const obj: ILayer = await editor.objects.exportFabricObjectAsLayer(fabricObject as fabric.Object);
			draggedObjectRef.current = obj ?? undefined;
		}
	};

	const handleUpdateActiveObject = () => {
		if (activeObject?.name == OBJECT_TYPES.INITIAL_FRAME) {
			activeObject = editor?.objects.findOneById(ObjectsEnum.InitialFrame);
		}
		designEditorStore.setProperty("activeObjectSettings", {
			colorNumber: activeObject?.colorNumber,
			fontType: activeObject?.fontType,
			logoType: activeObject?.logoType,
			horizontalAlignment: activeObject?.horizontalAlignment,
			verticalAlignment: activeObject?.verticalAlignment,
			strokeColorNumber: activeObject?.strokeColorNumber,
		});
	};

	return (
		<>
			{displayPreview && <Preview isOpen={displayPreview} setIsOpen={setDisplayPreview} />}
			<GraphicEditor isPopup={props.isPopup} />
		</>
	);
}

const ObservedComponent = observer(DesignEditor);
export default ObservedComponent;
