import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CrownIcon } from "../../../../assets/images/icons/CrownIcon";
import { useAppStore } from "../../../../hooks/useStores";

import i18n from "i18next";
import RouterConstants from "../../../../constants/RouterConstants.ts";
import { TAILORED_GENERATION_MODEL_STATUS } from "../../../../constants/TGConstants.ts";
import { IMAGE_REFERENCE_DISABLED_MODELS } from "../../../../constants/TextToImageConstants.ts";
import { OrgFeatures } from "../../../../models/billing.ts";
import { ITailoredGenerationModel } from "../../../../models/tailoredGeneration.ts";
import iframeStore from "../../../../pages/IframeNew/iframe-store.tsx";
import { isBriaMember } from "../../../../utils";
import CustomSingleSelect, {
	IGroup,
	IGroupOption,
	SingleSelectGroupedDropDownIProps,
} from "../../CustomSingleSelectDropdown/SingleSelectGroupedDropDown.tsx";
import styles from "./TextToImageModelsDropdown.module.scss";

type TextToImageModelsDropdownProps = {
	onFoundationModelsSuccess?: (foundationModels: IGroupOption[]) => void;
	onTailoredModelsSuccess?: (tailoredModels: IGroupOption[]) => void;
	isVector?: boolean;
};

const TextToImageModelsDropdown = ({
	handleChange,
	onFoundationModelsSuccess,
	onTailoredModelsSuccess,
	isVector = false,
	showFoundationModels = true,
	...rest
}: Partial<SingleSelectGroupedDropDownIProps<string | string[]>> & TextToImageModelsDropdownProps) => {
	const { t } = useTranslation("translation", {
		keyPrefix: "playground.textToImage.config.models.dropdownLabels",
	});
	const { textToImageStore, tailoredGenerationStore, authStore } = useAppStore();

	const [foundationModelsItems, setFoundationModelsItems] = useState<IGroupOption[]>([]);
	const [tailoredModelsItems, setTailoredModelsItems] = useState<IGroupOption[]>([]);
	const modelGroups: IGroup[] = [
		{
			name: t("foundationModels"),
			options: foundationModelsItems,
			loading: textToImageStore.loadingModels,
		},
		{
			name: t("tailoredModels"),
			options: tailoredModelsItems,
			loading: tailoredGenerationStore.isLoading || authStore.isLoadingOrgSubscriptions,
		},
	];

	useEffect(() => {
		const fetchModels = async () => {
			const foundationItems = showFoundationModels ? await createFoundationItems() : [];
			if (isVector && showFoundationModels) {
				setFoundationModelsItems(foundationItems.filter((item) => item.id !== "hd_2.2"));
			} else {
				setFoundationModelsItems(foundationItems);
			}
			onFoundationModelsSuccess?.(foundationItems);

			const tailoredItems = await createTailoredItems();
			setTailoredModelsItems(tailoredItems);
			onTailoredModelsSuccess?.(tailoredItems);
		};
		if (!authStore.isLoadingOrgSubscriptions) {
			fetchModels();
		}
	}, [textToImageStore.imageReference, authStore.isLoadingOrgSubscriptions]);

	useEffect(() => {
		if (Object.keys(textToImageStore.models).length) {
			if (
				(!textToImageStore.config.model || !textToImageStore.config.model_version) &&
				!textToImageStore.config.tailored_model_id
			) {
				textToImageStore.handleConfigChange("model", "fast");
				textToImageStore.handleConfigChange("model_version", "2.3");
			}
		}
	}, [textToImageStore.models]);

	const handleModelChange = (event: any) => {
		const selectedOption = modelGroups
			.map((group) => group.options)
			.flat()
			.find((option) => option?.id === event.target.value);

		if (selectedOption) {
			if (
				!selectedOption.extraData?.isTailoredModel ||
				(selectedOption.extraData?.isTailoredModel &&
					(authStore.isFeatureEnabled(OrgFeatures.TAILORED_GENERATION) || isBriaMember(authStore.user)))
			) {
				handleChange(event, selectedOption);
			} else {
				window.open(`${RouterConstants.CONSOLE.path}?pricing=true`);
			}
		}
	};

	const createFoundationItems = async (): Promise<IGroupOption[]> => {
		return Object.entries(await textToImageStore.getModels())
			.flatMap(([modelName, model]) =>
				model.versions.map((version: string) => ({
					id: getModelKey(modelName, version),
					key:
						iframeStore?.iframe?.config.image_generation_config?.enabled_foundation_models?.[modelName][
							version
						] ?? getModelDefaultLabel(modelName, version),
					value: getModelKey(modelName, version),
					label:
						iframeStore?.iframe?.config.image_generation_config?.enabled_foundation_models?.[modelName][
							version
						] ?? getModelDefaultLabel(modelName, version),
					disabled:
						textToImageStore.isImageReferenceEnabled() &&
						IMAGE_REFERENCE_DISABLED_MODELS.includes(modelName)
							? true
							: false,
				})),
			)
			.slice()
			.sort(customfoundationModelsSort);
	};

	const createTailoredItems = async (): Promise<IGroupOption[]> => {
		const createItem = (model: ITailoredGenerationModel, id: string): IGroupOption => ({
			id,
			value: id,
			key: model.name ?? "",
			label: model.name ?? "",
			description: model.description ?? "",
			extraData: {
				endpointModelName: model.model_url ?? "",
				isTailoredModel: true,
				model_id: model.id ?? "",
			},
			icon:
				!isBriaMember(authStore.user) && !authStore.isFeatureEnabled(OrgFeatures.TAILORED_GENERATION) ? (
					<CrownIcon />
				) : (
					""
				),
		});

		try {
			if (tailoredGenerationStore.models?.length) {
				return tailoredGenerationStore.models.map((model, id) => createItem(model, id.toString()));
			}

			const loadedModels = await tailoredGenerationStore.loadModels(TAILORED_GENERATION_MODEL_STATUS.COMPLETED);

			if (!loadedModels) return [];

			return Object.entries(loadedModels).map(([id, model]) => createItem(model, id));
		} catch (error) {
			console.error("Error loading tailored models:", error);
			return [];
		}
	};

	function customfoundationModelsSort(item1: IGroupOption, item2: IGroupOption) {
		const order = ["fast_2.3", "fast_2.2", "base_2.3", "hd_2.2"];
		return order.indexOf(item1.id) - order.indexOf(item2.id);
	}

	return (
		<CustomSingleSelect
			handleChange={handleModelChange}
			groups={modelGroups}
			paddingClass={styles.customSingleSelectRoot}
			selectStyle={styles.selectStyle}
			menuMaxHeight="50vh"
			className={styles.menuItemRoot}
			sortAlphabetically={false}
			hideEmptyGroups={true}
			{...rest}
		/>
	);
};

export default observer(TextToImageModelsDropdown);

export type TextToImageModels = {
	[model: string]: {
		versions: string[];
		steps: { min: number; max: number; default: number };
		max_results: number;
	};
};

export const getModelKey = (model: string, version: string) => {
	return `${model}_${version}`;
};

export const getModelName = (modelKey: string) => {
	return modelKey.split("_")[0];
};
export const getModelVersion = (modelKey: string) => {
	return modelKey.split("_")[1];
};

export const getModelDefaultLabel = (model: string, version: string) => {
	return `${i18n.t(`playground.textToImage.config.models.dropdownLabels.${model}`, { version })}`;
};
