import { Box, CircularProgress, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import foxLogo from "../../../assets/images/pngs/FS HIGH RES.png";
import Logo from "../../../assets/images/svgs/Bria-logo.svg";
import BriaButton from "../../../components/common/BriaButton/BriaButton.tsx";
import ExportButton from "../../../components/common/ExportButton/ExportButton.tsx";
import IframeCloseButton from "../../../components/common/IframeCloseButton/IframeCloseButton.tsx";
import OrganizationsDropdown from "../../../components/common/OrganizationsDropdown/OrganizationsDropdown.tsx";
import UserDropdown from "../../../components/common/UserDropdown/UserDropdown.tsx";
import { APPS, IMAGES_FORMATS } from "../../../constants/AppsConstants.ts";
import LinkConstants from "../../../constants/LinkConstants.ts";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { useLogDownloadEvents } from "../../../hooks/useLogDownloadEvents.ts";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { IframePostMessageActionTypes, IframePostMessageItemTypes } from "../../../models/new-iframe.ts";
import { TextToImageConfigType } from "../../../models/text-to-image.ts";
import iframeStore from "../../../pages/IframeNew/iframe-store.tsx";
import { hasOrganization, isFoxOrg, isPlatformHubPage } from "../../../utils";
import HeaderLayout from "../HeaderLayout.tsx";
import styles from "./HubHeader.module.scss";

const HubHeader = () => {
	const navigate = useSecureNavigate();
	const { logImageDownloadEvents } = useLogDownloadEvents();
	const { t } = useTranslation("translation", { keyPrefix: "headers.hub" });
	const { playgroundStore, textToImageStore } = useAppStore();
	const selectedImages = playgroundStore.getSelectedImages();
	const availableImages = playgroundStore.getAvailableImages();
	const handleBriaLogoClick = () => {
		location.replace(LinkConstants.BRIA_WEBSITE);
	};
	const handleFoxLogoClick = () => {
		// navigate(RouterConstants.APPS.path);
	};

	const productPlacementAppMatch = window.location.pathname.match(/\/(product-placement)\/?([^/]*)/);
	const designEditorAppMatch = window.location.pathname.match(/\/(campaign-editor)\/?([^/]*)/);

	if (designEditorAppMatch) {
		return null;
	}

	const onDownload = async () => {
		const images = selectedImages.length ? selectedImages : availableImages;
		if (images.length) {
			playgroundStore.exportImages(images);
			const firstImage = images[0];
			const defaultFileType = textToImageStore.isTextToVector() ? IMAGES_FORMATS.SVG : IMAGES_FORMATS.PNG;
			const fileType = (firstImage?.config as TextToImageConfigType)?.format?.toUpperCase() || defaultFileType;
			logImageDownloadEvents(images, fileType);
		}
	};

	const onIframeSave = async () => {
		const images = selectedImages.length ? selectedImages : availableImages;
		if (images.length) {
			iframeStore.sendExportPostMessage(
				IframePostMessageActionTypes.Save,
				IframePostMessageItemTypes.Image,
				images.map((image) => ({
					type: IframePostMessageItemTypes.Image,
					src: image.url,
				})),
			);
		}
	};
	const selectedImage = selectedImages.length === 1 ? selectedImages[0] : undefined;
	return (
		<HeaderLayout
			className={styles.header}
			logo={isFoxOrg() ? foxLogo : Logo}
			onLogoClick={!isFoxOrg() ? handleBriaLogoClick : handleFoxLogoClick}
		>
			{!isPlatformHubPage() && (
				<>
					{!iframeStore.isIframe() && playgroundStore.selectedConfig === APPS.IMAGE_TO_IMAGE && (
						<Box className={styles.singleModeBanner}>
							<Typography
								className={styles.bannerText}
								dangerouslySetInnerHTML={{ __html: t("singleModeBanner") }}
							></Typography>
							<BriaButton
								className={styles.bannerBtn}
								onClick={() => {
									if (selectedImage) {
										playgroundStore.onAiEditorButtonClick(selectedImage, navigate, true);
									} else {
										window.open(LinkConstants.AI_EDITOR, "_blank");
									}
								}}
								size="small"
							>
								{t("pressHere")}
							</BriaButton>
							{selectedImage?.aiEditorButtonLoading && (
								<CircularProgress color="inherit" size={12} className={styles.loader} />
							)}
						</Box>
					)}
					{playgroundStore.selectedConfig === APPS.PRODUCT_PLACEMENT && (
						<ExportButton onDownload={onDownload} onIframeSave={onIframeSave} />
					)}
				</>
			)}

			{!isFoxOrg() && !productPlacementAppMatch && (
				<>
					{playgroundStore.selectedConfig === APPS.SANDBOX_API && (
						<BriaButton
							buttonType="textSmall"
							onClick={() => window.open(LinkConstants.BRIA_API, "_blank")}
							size="small"
						>
							{t("apiDocumentation")}
						</BriaButton>
					)}
					{!iframeStore.isIframe() && (
						<>
							<BriaButton
								buttonType="secondaryMedium"
								onClick={() => window.open(LinkConstants.CONTACT_US_FORM_LINK, "_blank")}
								size="small"
							>
								{t("contactSales")}
							</BriaButton>
							<BriaButton
								buttonType="primaryMedium"
								onClick={() => navigate(RouterConstants.CONSOLE.path)}
								size="small"
							>
								{hasOrganization() ? t("console") : t("getApi")}
							</BriaButton>
						</>
					)}
					<IframeCloseButton />
				</>
			)}
			<OrganizationsDropdown labelFontSize="14px" />
			<UserDropdown />
		</HeaderLayout>
	);
};

export default observer(HubHeader);
