import { Box, Grid, Typography, useTheme } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ClockStop from "../../../assets/images/svgs/clock-stop.svg";
import LinkConstants from "../../../constants/LinkConstants.ts";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { TAILORED_GENERATION_MODEL_STATUS } from "../../../constants/TGConstants.ts";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { OrgFeatures } from "../../../models/billing.ts";
import { EventLogTypesEnum } from "../../../models/common.ts";
import { ITailoredGenerationModel } from "../../../models/tailoredGeneration.ts";
import ActionsDotsDropdown from "../../common/ActionsDotsDropdown/ActionsDotsDropdown.tsx";
import BriaButton from "../../common/BriaButton/BriaButton.tsx";
import ConfirmationPopup from "../../common/ConfirmationPopup/ConfirmationPopup.tsx";
import CopyToClipboard from "../../common/CopyToClipboard/CopyToClipboard.tsx";
import { LinearType, LinearWithValueLabel } from "../../common/LinearProgressBar/LinearProgressBar.tsx";
import RequestAccessContainer from "../RequestAccessContainer/RequestAccessContainer.tsx";
import styles from "./ModelCard.module.scss";

export interface IModelProps {
	model: ITailoredGenerationModel;
}

export const ModelCard = ({ model }: IModelProps) => {
	const navigate = useSecureNavigate();
	const { tailoredGenerationStore, authStore } = useAppStore();
	const [open, setOpen] = useState(false);

	const { t } = useTranslation("translation", { keyPrefix: "tailored_generation" });
	const { t: t1 } = useTranslation("translation");
	const [openDeletePopup, setOpenDeletePopup] = useState(false);
	const getRemainingHours = (progress: number) => {
		const remainingHours = Math.floor(4 - (progress / 100) * 4);
		return (
			<Box className={styles.remainingBox}>
				<img src={ClockStop} />
				<span className={styles.bold}>
					{t("models.remainingDays", {
						count: remainingHours,
						days: remainingHours === 1 ? "hour" : "hours",
					})}
				</span>
				{t("models.remaining")}
			</Box>
		);
	};
	const getProgressBarSection = (model: ITailoredGenerationModel) => {
		return (
			<Box>
				<Box className={styles.statusMessages}>
					<Typography className={styles.status}>{model.status}</Typography>

					<Typography className={styles.remainingDays}>
						{getRemainingHours(model.trainingProgress)}
					</Typography>
				</Box>

				<LinearWithValueLabel linearType={LinearType.BOLD} progress={model.trainingProgress} />
			</Box>
		);
	};
	const getModelActions = (model: ITailoredGenerationModel) => {
		const items = [
			{
				text: t("models.actions.playground"),
				onClick: () => {
					tailoredGenerationStore.selectedModelUsedToControlThePlayGroundTaps = model;
					navigate(`${RouterConstants.TG_PLAYGROUND.editPath}/${model.id}`);
				},
			},
			{
				text: t("models.actions.dataset"),
				onClick: () => {
					tailoredGenerationStore.selectedModelUsedToControlThePlayGroundTaps = model;
					if (model?.dataset?.id) {
						navigate(`${RouterConstants.DATASET.editPath}/${model?.dataset?.id}`);
					}
				},
			},
			{
				text: t("models.actions.delete"),
				onClick: () => {
					setOpenDeletePopup(true);
				},
			},
		];
		model.status !== TAILORED_GENERATION_MODEL_STATUS.COMPLETED && items.shift();
		return <ActionsDotsDropdown items={items} transformOrigin={{ vertical: "top", horizontal: "right" }} />;
	};
	const getInProgressAndDraftedModels = (model: ITailoredGenerationModel) => {
		const theme = useTheme();
		return (
			<>
				{model.status !== TAILORED_GENERATION_MODEL_STATUS.FAILED &&
					model.status !== TAILORED_GENERATION_MODEL_STATUS.STOPPED && (
						<Box className={styles.statusBox}>{getProgressBarSection(model)}</Box>
					)}

				{(model.status === TAILORED_GENERATION_MODEL_STATUS.STOPPED ||
					model.status === TAILORED_GENERATION_MODEL_STATUS.FAILED) && (
					<Box className={styles.noteBox}>
						<Typography className={styles.failed}>
							{model.status === TAILORED_GENERATION_MODEL_STATUS.FAILED
								? t("models.validation.failedTraining")
								: t("models.validation.cancelledTraining")}
						</Typography>
					</Box>
				)}

				{(model.status === TAILORED_GENERATION_MODEL_STATUS.IN_PROGRESS ||
					model.status === TAILORED_GENERATION_MODEL_STATUS.STOPPING) && (
					<BriaButton
						className={clsx({
							[styles.submit]: true,
						})}
						disabledColor={theme.palette.primary.light}
						buttonType="secondaryMedium"
						disabled={model.status === TAILORED_GENERATION_MODEL_STATUS.STOPPING}
						onClick={async () => {
							await tailoredGenerationStore.updateModel(model);
						}}
					>
						{t("models.buttons.stopTraining")}
					</BriaButton>
				)}
			</>
		);
	};
	const getCompletedModels = (model: ITailoredGenerationModel) => {
		return (
			<Box className={styles.buttonsGroup}>
				<RequestAccessContainer
					requestFeature={"Tailored Models"}
					eventLog={{ eventType: EventLogTypesEnum.REQUEST_FOR_TAILORED_MODELS }}
					buttonMessage={t1("requestAccessContainer.requestAccessToAPI")}
					buttonLoading={authStore.isLoadingOrgSubscriptions}
					condition={authStore.isFeatureEnabled(OrgFeatures.TAILORED_GENERATION)}
				>
					<BriaButton
						className={styles.primaryButton}
						buttonType="primary"
						onClick={() => {
							setOpen(true);
						}}
					>
						{t("models.actions.getAPIEndpoint")}
					</BriaButton>
				</RequestAccessContainer>
				<BriaButton
					className={styles.secondaryButton}
					buttonType="tertiaryMedium"
					onClick={() => {
						tailoredGenerationStore.selectedModelUsedToControlThePlayGroundTaps = model;
						navigate(`${RouterConstants.TG_PLAYGROUND.editPath}/${model.id}`);
					}}
				>
					{t("models.actions.playground")}
				</BriaButton>
			</Box>
		);
	};
	const closeDeletePopup = () => {
		setOpenDeletePopup(false);
	};
	return (
		<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
			<Box className={styles.container}>
				<Box className={styles.actionsBox}>{getModelActions(model)}</Box>
				<Typography className={styles.title}>{model.name} </Typography>
				<Typography className={styles.description}>{model.description}</Typography>

				{model.status === TAILORED_GENERATION_MODEL_STATUS.COMPLETED
					? getCompletedModels(model)
					: getInProgressAndDraftedModels(model)}
			</Box>
			<ConfirmationPopup
				onClose={() => {
					setOpen(false);
				}}
				title={t("models.apiEndpointPopup.title")}
				description={t("models.apiEndpointPopup.description")}
				confirmButtonText={t("models.apiEndpointPopup.primaryButton")}
				firstButtonText={t("models.apiEndpointPopup.secondaryButton")}
				open={open}
				onClick={async () => {
					setOpen(false);
				}}
				onSecondaryClick={async () => {
					window.open(LinkConstants.BRIA_API, "_blank");
					setOpen(false);
				}}
			>
				<CopyToClipboard className={styles.keyContainer} textToCopy={model.model_url} />
			</ConfirmationPopup>
			<ConfirmationPopup
				onClose={closeDeletePopup}
				title={t("models.deletePopup.title")}
				description={t("models.deletePopup.description")}
				confirmButtonText={t("models.deletePopup.confirmText")}
				open={openDeletePopup}
				onClick={() => tailoredGenerationStore.deleteModel(model)}
				loading={tailoredGenerationStore.isLoading}
				maxWidth={"544px"}
			/>
		</Grid>
	);
};

const ObservedComponent = observer(ModelCard);
export default ObservedComponent;
