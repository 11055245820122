import { FoxTeam } from "../../../models/aiEditor";
import BriaDropDown, { BriaDropDownProps } from "../BriaDropDown/BriaDropDown";
import useStaticDropdown from "./useStaticDropdown";

type Props<T> = {
	type:
		| "aspectRatio"
		| "maskAspectRatio"
		| "tailoredStyles"
		| "tailoredSubStyles"
		| "genModels"
		| "desiredResolution"
		| "sandboxAPIFamily";
	excludedItems?: T[];
} & Partial<BriaDropDownProps<T>>;

const StaticDropdown = <T,>({ type, excludedItems, ...rest }: Props<T>) => {
	return <BriaDropDown<T> {...rest} {...useStaticDropdown<T>({ tKeyPrefix: type, excludedItems })} />;
};

export default StaticDropdown;

export type AspectRatio = "original" | "4:3" | "3:4" | "1:1" | "16:9" | "9:16" | "5:4" | "4:5" | "3:2" | "2:3";
export type MaskAspectRatio =
	| "1:1"
	| "1:1.25"
	| "1:1.5"
	| "1:2"
	| "1:3"
	| "1:4"
	| "1:5"
	| "1:6"
	| "6:1"
	| "5:1"
	| "4:1"
	| "3:1"
	| "2:1"
	| "1.5:1"
	| "1.25:1";
export type TailoredStyles = "fox_nfl";
export type TailoredSubStyles = FoxTeam;
export type GenModel = "bria2_3_fast" | "bria2_2_hd" | "bria2_3";
export type DesiredResolutionType =
	| "original"
	| "nhd"
	| "svga"
	| "xga"
	| "hd"
	| "wuxga"
	| "uwfhd"
	| "wqxga"
	| "uwqhd"
	| "uhd";
