import { Box, SelectChangeEvent, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Image } from "../../../../../../../DesignEditor/models/image.ts";
import TextToImagePopup from "../../../../../../../components/TextToImage/TextToImagePopup/TextToImagePopup.tsx";
import BriaButton from "../../../../../../../components/common/BriaButton/BriaButton.tsx";
import { AspectRatio } from "../../../../../../../components/common/DropDowns/StaticDropdown.tsx";
import ImageCarousel from "../../../../../../../components/common/ImageCarousel/ImageCarousel.tsx";
import { useAppStore } from "../../../../../../../hooks/useStores.tsx";
import useImageUtils from "../../../../../../../utils/useImageUtils.tsx";
import useDesignEditorUtils from "../../../../../../CustomUtils/UseDesignEditor.tsx";
import Download from "../../../../../../assets/svgs/download.svg";
import trash from "../../../../../../assets/svgs/trash.svg";
import GenerateImagesSection from "../../../../../../components/common/ImagesTab/GenerateImagesSection/GenerateImagesSection.tsx";
import MainTabHeader from "../../../../../../components/common/MainTabHeader/MainTabHeader.tsx";
import { DesignEditorContext } from "../../../../../../contexts/DesignEditor.tsx";
import { useEditor } from "../../../../../../hooks/useEditor.tsx";
import { ILayer, IScene } from "../../../../../../types";
import { useSmartImageUtils } from "../../../../utils/smartImageUtils.ts";
import { ObjectsEnum, SecondaryTabTypeEnum } from "../index.ts";
import GridTab from "./GridTab/GridTab.tsx";
import SmartImage from "./SmartImage/SmartImage.tsx";
import TabHeader from "./TabHeader/TabHeader.tsx";
import styles from "./images.module.scss";

export interface GalleryData {
	showroomImages: {
		totalPages?: number;
		images: Image[];
	};
}

export interface IMenuItem {
	label: string;
	icon: string;
	onClick: (image: Image, userImage?: boolean, orgImage?: boolean) => void;
}

export enum imageCarouselType {
	sharedImages = "sharedImages",
	userImages = "userImages",
	Unsplash = "Unsplash",
	Logos = "Logos",
}

export const imagesPerPage = 20;

const Images = () => {
	const embeddingName = "425";
	const numberOfColumns = 2;
	const editor = useEditor();
	const { isPopupView, setCurrentScene, scenes, setScenes } = useContext(DesignEditorContext);
	const tabsRef = useRef<HTMLDivElement>(null);
	const { exportImage, convertS3UriToHttpsCloudFrontUrl, isS3Uri } = useImageUtils();
	const { resetRectangleInAGroup, findObjectFromScene, initializeSmartImage } = useSmartImageUtils();
	const { designEditorStore, imagesStore, playgroundStore, campaignStore } = useAppStore();
	const [searchBoxVisible, setSearchBoxVisible] = useState(true);
	const { t } = useTranslation("translation", { keyPrefix: "editor.images" });
	const [pageNumber, setPageNumber] = useState<number>(1);
	const [searchTerm, setSearchTerm] = useState<string | undefined>();
	const [hasSmartImage, setHasSmartImage] = useState<boolean>(false);
	const { addImageObjectToCanvas } = useDesignEditorUtils();

	useEffect(() => {
		if (!isPopupView) {
			imagesStore.updateGalleryOrgData();
			imagesStore.updateGalleryUserData();
			imagesStore.updateGalleryUnsplashData();
			imagesStore.updateLogoData();
		}
		return () => {
			imagesStore.secondaryTabType = null;
			imagesStore.setProperty("smartImageBackgroundOptions", []);
		};
	}, []);

	useEffect(() => {
		const loadSmartImage = async () => {
			const _hasSmartImage = await hasSmartImageObjects();
			setHasSmartImage(_hasSmartImage);
		};
		if (editor) {
			loadSmartImage();
		}
	}, [editor, imagesStore.secondaryTabType]);

	useEffect(() => {
		const updateGallery = async () => {
			if (imagesStore.uploadProcessDone) {
				if (imagesStore.isLogoUploading) {
					imagesStore.updateLogoData();
					imagesStore.tabGallery.showroomImages.images = imagesStore.logos.images;
					imagesStore.isLogoUploading = false;
				} else {
					if (imagesStore.uploadToUserGallery) {
						imagesStore.updateGalleryUserData();
						imagesStore.tabGallery.showroomImages.images = imagesStore.user_images.images;
					} else {
						imagesStore.updateGalleryOrgData();
						imagesStore.tabGallery.showroomImages.images = imagesStore.org_images.images;
					}
				}
			}
		};
		updateGallery();
	}, [imagesStore.uploadProcessDone]);

	useEffect(() => {
		setPageNumber(1);
	}, [imagesStore.secondaryTabType]);

	useEffect(() => {
		imagesStore.loadMoreImages(imagesStore.secondaryTabType, imagesPerPage, pageNumber, searchTerm);
	}, [pageNumber]);

	const handleGenAspectRatioChange = (e: SelectChangeEvent<AspectRatio | AspectRatio[]>) => {
		playgroundStore.setSelectedAspectRatio(e.target.value as AspectRatio);
	};

	const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		imagesStore.tabGallery.showroomImages.images = [];
		setSearchTerm(e.target.value);
		setPageNumber(1);
		if (e.target.value) {
			await imagesStore.searchImages(imagesPerPage, 1, e.target.value, embeddingName);
			imagesStore.tabGallery.showroomImages.images = imagesStore.search_result_images.images;
		} else {
			imagesStore.tabGallery.showroomImages.images = imagesStore.unsplashImages.images;
			await imagesStore.updateGalleryUnsplashData();
		}
	};

	const loadMore = async () => {
		const pageNum = pageNumber + 1;
		setPageNumber(pageNum);
	};

	const addObject = React.useCallback(async () => {
		if (editor) {
			const _currentScene = editor.scene.exportToJSON();
			await initializeSmartImage(_currentScene);
			await resetRectangleInAGroup(_currentScene, ObjectsEnum.OuterRectangle);
			await resetRectangleInAGroup(_currentScene, ObjectsEnum.InnerRectangle);
			const innerRectangle = await findObjectFromScene(_currentScene, ObjectsEnum.InnerRectangle);
			designEditorStore.setProperty("originalInnerRectangle", innerRectangle as ILayer);
			imagesStore.secondaryTabType = SecondaryTabTypeEnum.SMART_IMAGE;

			const updatedPreview = (await editor?.renderer.render(_currentScene)) as string;
			const updatedScene = { ..._currentScene, preview: updatedPreview, duration: 1000 };
			const updatedScenes = scenes.map((scene: IScene) => {
				if (scene.id === updatedScene.id) {
					return { ...updatedScene, preview: updatedPreview };
				}
				return editor.scene.formalizeSceneAttributes(scene);
			}) as IScene[];
			setScenes(updatedScenes);
			setCurrentScene(updatedScene);
		}
	}, [editor]);

	const hasSmartImageObjects = async (_currentScene?: IScene) => {
		if (!editor) {
			return false;
		}
		if (!_currentScene) {
			_currentScene = editor.scene.exportToJSON();
		}
		const outerRectangle = await findObjectFromScene(_currentScene, ObjectsEnum.InnerRectangle);
		const innerRectangle = await findObjectFromScene(_currentScene, ObjectsEnum.InnerRectangle);

		return !!(innerRectangle && outerRectangle);
	};

	//  TODO implement Edit using AI
	const menuItems: IMenuItem[] = [
		// {
		// 	label: "Edit Using AI Editor",
		// 	icon: aiActions,
		// 	onClick: () => console.log("Edit Using AI Editor"),
		// },
		{
			label: t("delete"),
			icon: trash,
			onClick: (image: Image, userImage?: boolean, orgImage?: boolean) => {
				if (userImage) {
					imagesStore.deleteUserImage(image.visual_hash ?? image.visual_id);
				} else if (orgImage) {
					imagesStore.deleteOrgImage(image.visual_hash ?? image.visual_id);
				}
			},
		},
		{
			label: t("download"),
			icon: Download,
			onClick: (image: Image) => {
				{
					exportImage(
						isS3Uri(image.url) ? convertS3UriToHttpsCloudFrontUrl(image.url) : image.url,
						image.visual_hash ?? image.visual_id,
					);
				}
			},
		},
	];
	const getTabName = (type: SecondaryTabTypeEnum): string | undefined => {
		switch (type) {
			case SecondaryTabTypeEnum.SHARED_IMAGES:
				return t("sharedImages");
			case SecondaryTabTypeEnum.USER_IMAGES:
				return t("myImages");
			case SecondaryTabTypeEnum.UNSPLASH:
				return t("Unsplash");
			case SecondaryTabTypeEnum.SMART_IMAGE:
				return t("smartImg");
			case SecondaryTabTypeEnum.LOGOS:
				return t("logos");
			default:
				return undefined;
		}
	};

	return (
		<Box className={styles.container}>
			{!imagesStore.secondaryTabType ? (
				<>
					<MainTabHeader title={t("title")} />
				</>
			) : (
				<Box className={styles.tabHeader}>
					<TabHeader
						headerText={getTabName(imagesStore.secondaryTabType)}
						setSearchBoxVisible={setSearchBoxVisible}
						searchBoxVisible={searchBoxVisible}
						enableSearch={imagesStore.secondaryTabType == SecondaryTabTypeEnum.UNSPLASH}
						headerDescription={
							imagesStore.secondaryTabType === SecondaryTabTypeEnum.SMART_IMAGE
								? t("smartImgDescription")
								: ""
						}
					/>
				</Box>
			)}

			<Box className={styles.tabs} ref={tabsRef}>
				{imagesStore.secondaryTabType === SecondaryTabTypeEnum.SMART_IMAGE ? (
					<Box className={styles.secondaryTab}>
						<SmartImage
							handleGenAspectRatioChange={handleGenAspectRatioChange}
							openText2ImagePopup={() => designEditorStore.openText2ImagePopup(isPopupView ?? false)}
						/>
					</Box>
				) : imagesStore.secondaryTabType != null ? (
					<GridTab
						menuItems={menuItems}
						numberOfColumns={numberOfColumns}
						addImageObject={addImageObjectToCanvas}
						loadMore={loadMore}
						secondaryTabType={imagesStore.secondaryTabType}
						searchBoxVisible={searchBoxVisible}
						handleSearchChange={handleSearchChange}
					/>
				) : (
					<Box className={styles.mainTab}>
						{!isPopupView && (
							<>
								<Box className={styles.smartImage}>
									<Typography className={styles.title}>{t("smartImage.title")}</Typography>
									<Typography className={styles.description}>
										{t("smartImage.description")}
									</Typography>
									<BriaButton
										className={styles.primaryButton}
										buttonType="primary"
										onClick={addObject}
									>
										{hasSmartImage ? t("smartImage.button2") : t("smartImage.button")}
									</BriaButton>
								</Box>
								<Box className={styles.separator_20} />
								<GenerateImagesSection
									hideTextField={true}
									hideModelsDropdownSelector={true}
									hideResolutionDropdownSelector={true}
									textToGenerate={playgroundStore.textToGenerate}
									setTextToGenerate={playgroundStore.setTextToGenerate}
									selectedAspectRatio={playgroundStore.aspectRatio}
									handleGenAspectRatioChange={handleGenAspectRatioChange}
									handleGenSubmit={() => designEditorStore.openText2ImagePopup(isPopupView ?? false)}
									tooltip={t("smartImage.tooltips.generate1")}
								/>
								<Box className={styles.separator_20} />
								<ImageCarousel
									title={t("myImages")}
									images={imagesStore.galleryUserData.showroomImages.images}
									onClick={() => {
										imagesStore.tabGallery.showroomImages.images = imagesStore.user_images.images;
										imagesStore.secondaryTabType = SecondaryTabTypeEnum.USER_IMAGES;
									}}
									type={imageCarouselType.userImages}
									menuItems={menuItems}
									addImageObject={addImageObjectToCanvas}
								/>
								<ImageCarousel
									title={t("sharedImages")}
									images={imagesStore.galleryOrgData.showroomImages.images}
									onClick={() => {
										imagesStore.tabGallery.showroomImages.images = imagesStore.org_images.images;
										imagesStore.secondaryTabType = SecondaryTabTypeEnum.SHARED_IMAGES;
									}}
									menuItems={menuItems}
									type={imageCarouselType.sharedImages}
									addImageObject={addImageObjectToCanvas}
									hideUploadIcon={!campaignStore.isAdminMode}
								/>
								{/* TODO: To Add this Section Later */}
								{/* <ImageCarousel
									title={t("Unsplash")}
									images={imagesStore.unsplashGallery.showroomImages.images}
									hideUploadIcon
									onClick={() => {
										imagesStore.tabGallery.showroomImages.images =
											imagesStore.unsplashImages.images;
										imagesStore.secondaryTabType = SecondaryTabTypeEnum.UNSPLASH;
									}}
									menuItems={menuItems}
									type={imageCarouselType.Unsplash}
									addImageObject={addImageObject}
								/> */}
								<ImageCarousel
									title={t("logos")}
									images={imagesStore.galleryLogoData.showroomImages.images.filter(
										(image) => image?.url !== "",
									)}
									onClick={() => {
										imagesStore.tabGallery.showroomImages.images =
											imagesStore.galleryLogoData.showroomImages.images;

										imagesStore.secondaryTabType = SecondaryTabTypeEnum.LOGOS;
									}}
									menuItems={menuItems}
									type={imageCarouselType.Logos}
									addImageObject={addImageObjectToCanvas}
								/>
							</>
						)}
					</Box>
				)}
			</Box>
			<TextToImagePopup />
		</Box>
	);
};

export default observer(Images);
