import { closestCenter, DndContext, DragOverlay, PointerSensor, useSensor } from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor, restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { arrayMove, horizontalListSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../../../../../../hooks/useStores.tsx";
import Add from "../../../../../../components/Icons/Add";
import { PanelType } from "../../../../../../constants/app-options.ts";
import { getDefaultTemplate } from "../../../../../../constants/design-editor";
import { DesignEditorContext } from "../../../../../../contexts/DesignEditor";
import useScenesUtils from "../../../../../../CustomUtils/ScenesUtils.tsx";
import useAppContext from "../../../../../../hooks/useAppContext.tsx";
import { useEditor } from "../../../../../../hooks/useEditor";
import { useFrame } from "../../../../../../hooks/useFrame";
import type { IScene } from "../../../../../../types";
import { useSmartImageUtils } from "../../../../utils/smartImageUtils.ts";
import SceneItem from "../SceneItem/SceneItem";
import styles from "./Scenes.module.scss";

const Scenes = () => {
	const frame = useFrame();
	const editor = useEditor();
	const { updateScenes } = useScenesUtils();
	const { changePage } = useSmartImageUtils();
	const { imagesStore, campaignStore } = useAppStore();
	const [isLoading, setIsLoading] = useState(false);
	const [currentPreview, setCurrentPreview] = React.useState("");
	const { t } = useTranslation("translation", { keyPrefix: "campaignTab.scenes" });
	const [draggedScene, setDraggedScene] = React.useState<IScene | null>(null);
	const { scenes, setScenes, setCurrentScene, currentScene, setCurrentDesign, currentDesign, invokeSave } =
		React.useContext(DesignEditorContext);
	const { setActivePanel } = useAppContext();

	const sensors = [
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 5,
			},
		}),
	];

	React.useEffect(() => {
		if (editor && scenes && currentScene) {
			const isCurrentSceneLoaded = scenes.find((s) => s.id === currentScene?.id);
			if (!isCurrentSceneLoaded) {
				setCurrentScene(scenes[0]);
			}
		}
	}, [editor, scenes, currentScene]);

	React.useEffect(() => {
		const watcher = async () => {
			const updatedTemplate = editor?.scene.exportToJSON();
			const updatedPreview = updatedTemplate && ((await editor?.renderer.render(updatedTemplate)) as string);
			updatedPreview && setCurrentPreview(updatedPreview);
		};
		if (editor) {
			editor.on("history:changed", watcher);
		}
		return () => {
			if (editor) {
				editor.off("history:changed", watcher);
			}
		};
	}, [editor]);

	React.useEffect(() => {
		if (editor) {
			editor?.history.getUpdatedScenes(updateScenes);
			if (currentScene) {
				updateCurrentScene(currentScene);
			} else {
				const defaultTemplate = getDefaultTemplate({
					width: 1080,
					height: 1080,
				});
				setCurrentDesign({
					id: nanoid(),
					frame: defaultTemplate.frame,
					metadata: {},
					name: "Untitled Design",
					preview: "",
					scenes: [],
				});
				editor.scene
					.importFromJSON(defaultTemplate, invokeSave)
					.then(() => {
						const initialDesign = editor.scene.exportToJSON() as any;
						editor.renderer.render(initialDesign).then((data) => {
							setCurrentScene({ ...initialDesign, preview: data });
							setScenes([{ ...initialDesign, preview: data }]);
						});
					})
					.catch(console.log);
			}
		}
	}, [editor, currentScene]);

	const updateCurrentScene = React.useCallback(
		async (design: IScene) => {
			await editor?.scene.importFromJSON(design, invokeSave);
			const updatedPreview = (await editor?.renderer.render(design)) as string;
			setCurrentPreview(updatedPreview);
		},
		[editor, currentScene],
	);

	const addScene = React.useCallback(async () => {
		setCurrentPreview("");
		const updatedTemplate = editor?.scene.exportToJSON();
		const updatedPreview = updatedTemplate && (await editor?.renderer.render(updatedTemplate));

		const updatedPages = scenes.map((p) => {
			if (updatedTemplate && p.id === updatedTemplate.id) {
				return { ...updatedTemplate, preview: updatedPreview };
			}
			return p;
		});

		const defaultTemplate = getDefaultTemplate({
			width: 1080,
			height: 1080,
		});
		const newPreview = await editor?.renderer.render(defaultTemplate);
		const newPage = { ...defaultTemplate, id: nanoid(), preview: newPreview } as any;
		const newPages = [...updatedPages, newPage] as any[];
		setScenes(newPages);
		setCurrentScene(newPage);
		editor?.canvas.canvas.disableEvents();
		editor?.canvas.canvas.discardActiveObject();
		editor?.canvas.canvas.enableEvents();
		setActivePanel(PanelType.RESIZE);
		campaignStore.originalScenesBeforeResizing = null;
	}, [scenes, currentDesign]);

	function handleDragStart(event: any) {
		const draggedScene = scenes.find((s) => s.id === event.active.id);
		if (draggedScene) {
			setDraggedScene(draggedScene);
		}
	}

	function handleDragEnd(event: any) {
		const { active, over } = event;

		if (active.id !== over.id) {
			setScenes((items) => {
				const oldIndex = items.findIndex((item) => item.id === active.id);
				const newIndex = items.findIndex((item) => item.id === over.id);

				return arrayMove(items, oldIndex, newIndex);
			});
		}
		setDraggedScene(null);
	}

	return (
		<DndContext
			modifiers={[restrictToFirstScrollableAncestor, restrictToHorizontalAxis]}
			sensors={sensors}
			collisionDetection={closestCenter}
			onDragEnd={handleDragEnd}
			onDragStart={handleDragStart}
		>
			<Box className={styles.timelineItemsContainer} id="TimelineItemsContainer">
				<div className={styles.timelineItemsWrapper}>
					<SortableContext items={scenes} strategy={horizontalListSortingStrategy}>
						{scenes.map((page, index) => (
							<SceneItem
								key={index}
								isCurrentScene={page.id === currentScene?.id}
								scene={page}
								index={index}
								changePage={async () => {
									setIsLoading(true);
									setCurrentPreview("");
									changePage(page)
										.then(() => {
											imagesStore.setProperty("smartImageBackgroundOptions", []);
											setIsLoading(false);
										})
										.catch((error) => {
											imagesStore.setProperty("smartImageBackgroundOptions", []);
											setIsLoading(false);
											console.error("Error changing page:", error);
										});
									campaignStore.originalScenesBeforeResizing = null;
								}}
								containerClassName={
									isLoading || imagesStore.isUploadingSmartImageMainObject ? styles.disabled : ""
								}
								preview={
									currentPreview && page.id === currentScene?.id
										? currentPreview
										: page.preview
										? page.preview
										: ""
								}
								isLoadingSceneItem={isLoading}
							/>
						))}
						<div className={styles.addSceneContainer} onClick={addScene}>
							<div className={styles.addSceneIconWrapper}>
								<Add size={20} />
							</div>
							<Typography className={styles.addNewLabel}>{t("addNewScene")}</Typography>
						</div>
					</SortableContext>
					<DragOverlay>
						{draggedScene ? (
							<Box
								className={styles.draggedScene}
								sx={{
									backgroundImage: `url(${draggedScene.preview})`,
									backgroundSize: `${frame ? (frame.width * 70) / frame.height : 70}px 70px`,
								}}
							></Box>
						) : null}
					</DragOverlay>
				</div>
			</Box>
		</DndContext>
	);
};

const ObservedComponent = observer(Scenes);
export default ObservedComponent;
