import ErrorIcon from "@mui/icons-material/Error";
import { Box, InputBaseComponentProps } from "@mui/material";
import clsx from "clsx";
import React, { ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";
import LoadingAnimation from "../../../components/common/LoadingAnimation/LoadingAnimation";
import { VisuallyHiddenInput } from "../VisuallyHiddenInput";
import styles from "./VideoUploader.module.scss";

interface VideoUploadProps {
	onUpload: (file: File, maxDuration?: number) => Promise<void>;
	description?: string | ReactNode;
	loading?: boolean;
	className?: string;
	inputProps?: InputBaseComponentProps;
	icon?: string;
	maxDuration?: number;
	error?: string | null;
}

const supportedVideoFileTypes = ["mp4", "avi", "mov", "gif", "webm"];

const VideoUploader: React.FC<VideoUploadProps> = ({
	onUpload,
	description,
	className,
	inputProps,
	icon,
	loading,
	maxDuration,
	error,
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground" });
	const defaultAcceptTypes = supportedVideoFileTypes
		.map((type) => {
			if (type === "mov") return "video/quicktime";
			return `video/${type}`;
		})
		.join(", ");
	const supportedFiles = supportedVideoFileTypes.map((type: string) => type.trim().toUpperCase()).join(", ");

	const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		event.target.value = "";
		if (file) {
			onUpload(file, maxDuration);
		}
	};

	const handleDragOver = useCallback((event: React.DragEvent) => {
		event.preventDefault();
	}, []);

	const handleDrop = useCallback(
		(event: React.DragEvent) => {
			event.preventDefault();

			const file = event.dataTransfer.files[0];
			if (file) {
				onUpload(file, maxDuration);
			}
		},
		[onUpload],
	);

	const renderFileInput = useCallback(
		() => (
			<VisuallyHiddenInput
				type="file"
				id="fileInput"
				inputProps={{ ...inputProps, accept: inputProps?.accept ?? defaultAcceptTypes }}
				onChange={handleFileInputChange}
			/>
		),
		[handleFileInputChange],
	);

	return (
		<>
			<Box
				className={clsx(className, styles.container)}
				component={"label"}
				onDragOver={handleDragOver}
				onDrop={handleDrop}
			>
				{renderFileInput()}
				{loading ? (
					<LoadingAnimation
						className={styles.videoLoader}
						loading={loading}
						progressBarTime={6}
						showAnimation={true}
					/>
				) : (
					<Box className={styles.emptyState}>
						{icon && <img className={styles.image} src={icon} alt={t("iconAlt")} />}
						<Box className={styles.contentWrapper}>
							<Box className={styles.description}>{description}</Box>
							<Box className={styles.support}>
								{t("emptyState.supportedFiles")} {supportedFiles}
							</Box>
							{error && (
								<Box className={styles.error}>
									<ErrorIcon />
									{error}
								</Box>
							)}
						</Box>
					</Box>
				)}
			</Box>
		</>
	);
};

export default VideoUploader;
