import { Box, Link, SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import StaticDropdown from "../../../../components/common/DropDowns/StaticDropdown.tsx";
import RouterConstants from "../../../../constants/RouterConstants";
import useSecureNavigate from "../../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import InputLayout from "../../../../layout/InputLayout/InputLayout.tsx";
import {
	APIS_REQUIRE_ORG,
	ImageModificationsSubAPIType,
	SandboxAPIType,
	SandboxSubAPITypes,
} from "../../../../models/sandboxAPI.ts";
import { hasOrganization } from "../../../../utils";
import styles from "./APIFamilyDropdown.module.scss";

const APIFamilyDropdown = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.sandboxAPI" });
	const { sandboxAPIStore, playgroundStore } = useAppStore();
	const navigate = useSecureNavigate();
	const { subAPI } = useParams<{ subAPI: SandboxSubAPITypes }>();

	useEffect(() => {
		if (window.location.pathname.includes(RouterConstants.CONSOLE.path)) {
			sandboxAPIStore.setProperty("isConsole", true);
		} else {
			sandboxAPIStore.setProperty("isConsole", false);
		}
		// Select the correct parent API family on first load
		if (subAPI) {
			for (const apiFamily in sandboxAPIStore.JsonConfigs) {
				const subApiList = sandboxAPIStore.JsonConfigs[apiFamily as SandboxAPIType];
				if (!hasOrganization()) {
					APIS_REQUIRE_ORG.forEach((api) => subApiList.hasOwnProperty(api) && delete subApiList[api]);
				}
				if (subApiList.hasOwnProperty(subAPI)) {
					sandboxAPIStore.handleAPIConfigChange("selectedAPI", apiFamily as SandboxAPIType);
					break;
				}
			}
			sandboxAPIStore.handleAPIConfigChange("selectedSubAPI", subAPI);
		}
	}, []);

	const handleSubAPIChange = (e: SelectChangeEvent<SandboxSubAPITypes>) => {
		const newSubAPI = e.target.value as SandboxSubAPITypes;

		if (
			playgroundStore.playgroundResults.length > 0 &&
			(newSubAPI === ImageModificationsSubAPIType.Eraser ||
				newSubAPI === ImageModificationsSubAPIType.GenFill ||
				sandboxAPIStore.config.selectedSubAPI === ImageModificationsSubAPIType.Eraser ||
				sandboxAPIStore.config.selectedSubAPI === ImageModificationsSubAPIType.GenFill)
		) {
			playgroundStore.playgroundResults = [];
		}

		playgroundStore.clearTextResults();
		sandboxAPIStore.handleAPIConfigChange("selectedSubAPI", newSubAPI);

		const modelPathParam = sandboxAPIStore.config.apiConfig?.pathParameter?.["model"];
		if (modelPathParam) {
			if (sandboxAPIStore.isConsole) {
				navigate(`${RouterConstants.API.fullPath}/${newSubAPI}/${modelPathParam}`, { replace: true });
			} else {
				navigate(`/${RouterConstants.APPS.path}/${newSubAPI}/${modelPathParam}`, { replace: true });
			}
		} else {
			if (sandboxAPIStore.isConsole) {
				navigate(`${RouterConstants.API.fullPath}/${newSubAPI}`, { replace: true });
			} else {
				navigate(`/${RouterConstants.APPS.path}/${newSubAPI}`, { replace: true });
			}
		}
	};

	return (
		<Box className={styles.root}>
			<InputLayout label={t("selectAPILabel")}>
				<StaticDropdown<SandboxAPIType>
					type="sandboxAPIFamily"
					value={sandboxAPIStore.config.selectedAPI}
					onChange={(e) => {
						sandboxAPIStore.handleAPIConfigChange("selectedAPI", e.target.value as SandboxAPIType);
					}}
					placeholder={t("selectAPIFamilyPlaceholder")}
				/>
			</InputLayout>

			<InputLayout labelClassName={styles.hidden}>
				<StaticDropdown<SandboxSubAPITypes>
					type={`${sandboxAPIStore.config?.selectedAPI}` as any}
					value={sandboxAPIStore.config?.selectedSubAPI}
					onChange={handleSubAPIChange}
					placeholder={t("selectSubAPIFamilyPlaceholder")}
					excludedItems={!hasOrganization() ? APIS_REQUIRE_ORG : []}
				/>
			</InputLayout>

			{sandboxAPIStore.config?.apiConfig?.docLink && (
				<Link
					className={styles.apiDocLink}
					href={sandboxAPIStore.config.apiConfig.docLink}
					target={"_blank"}
					rel="noopener"
				>
					{t("apiDoc")}
				</Link>
			)}
		</Box>
	);
};

export default observer(APIFamilyDropdown);
