import _ from "lodash";
import { useContext, useState } from "react";
import { firebaseAuth } from "../../config/firebase.ts";
import { getSelectedOrganization } from "../../helpers/localStorage.ts";
import useSecureNavigate from "../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../hooks/useStores.tsx";
import { CampaignEntityStatus } from "../../models/common.ts";
import { CampaignPreviewBtn, TemplatePreviewBtn } from "../campaign-store.tsx";
import { PanelType } from "../constants/app-options.ts";
import { DesignEditorContext } from "../contexts/DesignEditor.tsx";
import useAppContext from "../hooks/useAppContext.tsx";
import { useEditor } from "../hooks/useEditor.tsx";
import { IDesign } from "../interfaces/DesignEditor.ts";
import { Template } from "../models/template.ts";
import { IScene } from "../types";
import useTemplateUtils from "./UseTemplateUtils.tsx";
interface SaveTemplateProps {
	templateName: string;
	setIsValidTemplateName?: (value: boolean) => void;
}

type CampaignHeaderUtils = {
	isLoading: boolean;
	saveCampaign: () => Promise<void>;
	saveTemplate: () => Promise<void>;
	saveNewTemplate: ({ templateName, setIsValidTemplateName }: SaveTemplateProps) => Promise<void>;
	saveNewCampaign: ({ templateName, setIsValidTemplateName }: SaveTemplateProps) => Promise<number | null>;
	buildUpdatedTemplate(
		updatedScenes?: IScene[],
		status?: CampaignEntityStatus,
		isCampaign?: boolean,
		templateName?: string,
	): Promise<Template | undefined>;
	getPreviewImageForFirstScene: (ads_json: IDesign) => Promise<string | undefined>;
};

const useCampaignHeaderUtils = (): CampaignHeaderUtils => {
	const navigate = useSecureNavigate();
	const { activePanel } = useAppContext();
	const { campaignStore, designEditorStore, brandsDefinitionStore } = useAppStore();
	const { exportTemplate } = useTemplateUtils();
	const editor = useEditor();
	const [isLoading, setIsLoading] = useState(false);
	const { isPopupView } = useContext(DesignEditorContext);
	const { setActivePanel } = useAppContext();
	const buildUpdatedTemplate = async (
		updatedScenes?: IScene[],
		status?: CampaignEntityStatus,
		isCampaign?: boolean,
		templateName?: string,
	): Promise<Template | undefined> => {
		let selectedTemplate = !isPopupView ? campaignStore.selectedTemplate : campaignStore.selectedWizardTemplate;
		let updatedJson = await exportTemplate(updatedScenes);
		if (campaignStore.templateToDuplicate) {
			selectedTemplate = campaignStore.templateToDuplicate;
			if (
				!(
					campaignStore.selectedTemplate &&
					campaignStore?.selectedTemplate.id === campaignStore.templateToDuplicate.id
				)
			) {
				updatedJson = campaignStore.templateToDuplicate?.ads_json;
			}
		}
		if (selectedTemplate && updatedJson) {
			return {
				...selectedTemplate,
				ads_json: updatedJson,
				preview: (await getPreviewImageForFirstScene(updatedJson)) || "",
				status: status || selectedTemplate.status,
				name: templateName || selectedTemplate.name,
				org_id: isCampaign ? null : getSelectedOrganization()?.organization?.uid,
				user_id: isCampaign ? firebaseAuth.currentUser?.uid : null,
			};
		}
		return undefined;
	};

	const saveCampaign = async (): Promise<void> => {
		const selectedTemplate = !isPopupView ? campaignStore.selectedTemplate : campaignStore.selectedWizardTemplate;
		if (selectedTemplate && !selectedTemplate?.id) {
			const newTemplate = await buildUpdatedTemplate(undefined, CampaignEntityStatus.DRAFT, true);
			if (newTemplate) await campaignStore.createTemplate(newTemplate, navigate);
		} else if (selectedTemplate?.status === CampaignEntityStatus.DRAFT) {
			const updatedTemplate = await buildUpdatedTemplate(undefined, undefined, true);

			if (updatedTemplate) {
				await campaignStore.updateTemplate(updatedTemplate);
				brandsDefinitionStore.templateScenesBeforeApplyBrand = _.cloneDeep(updatedTemplate.ads_json as IDesign);
			}
		}
		if (activePanel == PanelType.CAMPAIGNS || activePanel == PanelType.TEMPLATES) {
			if (activePanel == PanelType.TEMPLATES) {
				setActivePanel(PanelType.CAMPAIGNS);
			}
			campaignStore.handleSetSelectedCampaignPreviewButton(CampaignPreviewBtn.PRIVATE);
			await campaignStore.loadTemplates(CampaignEntityStatus.DRAFT, false, false);
		}
	};

	const saveTemplate = async () => {
		const selectedTemplate = !isPopupView ? campaignStore.selectedTemplate : campaignStore.selectedWizardTemplate;
		if (selectedTemplate && !selectedTemplate?.id) {
			const newTemplate = await buildUpdatedTemplate(undefined, CampaignEntityStatus.ACTIVE, false);
			if (newTemplate) await campaignStore.createTemplate(newTemplate, navigate);
		} else if (selectedTemplate?.status === CampaignEntityStatus.ACTIVE) {
			const updatedTemplate = await buildUpdatedTemplate();
			if (updatedTemplate) {
				await campaignStore.updateTemplate(updatedTemplate);
				brandsDefinitionStore.templateScenesBeforeApplyBrand = _.cloneDeep(updatedTemplate.ads_json as IDesign);
			}
		}
		if (activePanel === PanelType.TEMPLATES || activePanel === PanelType.CAMPAIGNS) {
			if (activePanel === PanelType.CAMPAIGNS) {
				setActivePanel(PanelType.TEMPLATES);
			}
			campaignStore.handleSetSelectedTemplatePreviewButton(TemplatePreviewBtn.ORGANIZATION);
			await campaignStore.loadTemplates(CampaignEntityStatus.ACTIVE, true, false);
		}
	};

	const saveNewTemplate = async ({ templateName, setIsValidTemplateName }: SaveTemplateProps) => {
		if (!templateName) {
			setIsValidTemplateName && setIsValidTemplateName(false);
			return;
		}

		const updatedTemplate = await buildUpdatedTemplate(undefined, CampaignEntityStatus.ACTIVE, false, templateName);
		if (updatedTemplate) await campaignStore.duplicateTemplate(updatedTemplate, navigate);
		campaignStore.setProperty("openDuplicateCampaignOrTemplateModal", false);
		campaignStore.setProperty("templateToDuplicate", null);
		if (activePanel === PanelType.TEMPLATES || activePanel === PanelType.CAMPAIGNS) {
			if (activePanel === PanelType.CAMPAIGNS) {
				setActivePanel(PanelType.TEMPLATES);
			}
			campaignStore.handleSetSelectedTemplatePreviewButton(TemplatePreviewBtn.ORGANIZATION);
			await campaignStore.loadTemplates(CampaignEntityStatus.ACTIVE, true, false);
		}

		campaignStore.setProperty("openDuplicateCampaignOrTemplateModal", false);
	};

	const saveNewCampaign = async ({
		templateName,
		setIsValidTemplateName,
	}: SaveTemplateProps): Promise<number | null> => {
		setIsLoading(true);
		let newTemplateId = null;
		if (!templateName) {
			setIsValidTemplateName && setIsValidTemplateName(false);
			return null;
		}

		let updatedTemplate = await buildUpdatedTemplate(undefined, CampaignEntityStatus.DRAFT, true, templateName);

		if (!updatedTemplate) {
			updatedTemplate = designEditorStore.campaignUpdatedTemplate;
		}
		if (updatedTemplate) {
			newTemplateId = await campaignStore.duplicateTemplate(updatedTemplate, navigate);
		}
		campaignStore.setProperty("templateToDuplicate", null);

		campaignStore.setProperty("openDuplicateCampaignOrTemplateModal", false);
		if (activePanel == PanelType.CAMPAIGNS || activePanel == PanelType.TEMPLATES) {
			if (activePanel == PanelType.TEMPLATES) {
				setActivePanel(PanelType.CAMPAIGNS);
			}
			campaignStore.handleSetSelectedCampaignPreviewButton(CampaignPreviewBtn.PRIVATE);
		}
		designEditorStore.setProperty("campaignUpdatedTemplate", undefined);
		setIsLoading(false);
		return newTemplateId;
	};

	const getPreviewImageForFirstScene = async (ads_json: IDesign) => {
		const firstScene = ads_json.scenes[0];
		const firstLayer = firstScene.layers[0];
		const width = firstLayer.width;
		const height = firstLayer.height;
		firstScene.frame = {
			width: width,
			height: height,
		};
		const renderedImage: string | undefined = await editor?.renderer.render(firstScene);
		return renderedImage;
	};

	return {
		isLoading,
		saveCampaign,
		saveTemplate,
		saveNewTemplate,
		saveNewCampaign,
		buildUpdatedTemplate,
		getPreviewImageForFirstScene,
	};
};

export default useCampaignHeaderUtils;
