import JSZip from "jszip";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../hooks/useStores";
import { CampaignEntityStatus } from "../../models/common.ts";
import { IframePostMessageActionTypes, IframePostMessageItemTypes } from "../../models/new-iframe.ts";
import iframeStore from "../../pages/IframeNew/iframe-store.tsx";
import useDesignEditorContext from "../hooks/useDesignEditorContext";
import { useEditor } from "../hooks/useEditor";
import { IDesign } from "../interfaces/DesignEditor";
import { Template } from "../models/template";
import { IFrame, IScene } from "../types";
import { loadTemplateFonts } from "../utils/fonts";
import { loadVideoEditorAssets } from "../utils/video";
import useScenesUtils from "./ScenesUtils";

type URLDetails = {
	target: string | null;
	selectedType: string | null;
	id: string | null;
};

type TemplateUtils = {
	loadGraphicTemplate: (payload: IDesign) => Promise<{
		scenes: IScene[];
		design: { id: string; name: string; frame: IFrame; preview: string; metadata: any };
	}>;
	loadTemplate: (template: Template) => Promise<void>;
	handleDownloadTemplate: (template: Template) => Promise<void>;
	handleIframeSaveTemplate: (template: Template) => Promise<void>;
	exportTemplate: (l_scenes?: IScene[]) => Promise<IDesign | undefined>;
	getCampaignDetailsFromURL: () => URLDetails;
};

const UseTemplateUtils = (): TemplateUtils => {
	const editor = useEditor();
	const { campaignStore } = useAppStore();
	const { updateScenes } = useScenesUtils();
	const { t } = useTranslation("translation", { keyPrefix: "editor.tabs.templates" });
	const { currentScene, setScenes, setCurrentDesign, scenes, currentDesign, setCurrentScene } =
		useDesignEditorContext();

	const loadGraphicTemplate = async (payload: IDesign) => {
		if (!editor) {
			return {
				scenes: [] as IScene[],
				design: {
					id: "",
					name: "",
					frame: { width: 0, height: 0 } as IFrame,
					preview: "",
					metadata: {},
				},
			};
		}

		const { scenes: scns, ...design } = payload;
		const scenes: IScene[] = new Array(scns.length);

		const scenePromises = scns.map((scn, index) => {
			const scene: IScene = {
				name: scn.name,
				frame: {
					width: scn.layers[0].width,
					height: scn.layers[0].height,
				},
				id: scn.id,
				layers: scn.layers,
				metadata: {},
			};

			return loadVideoEditorAssets(scene)
				.then((loadedScene) => loadTemplateFonts(loadedScene).then(() => loadedScene))
				.then((loadedScene) =>
					editor.renderer.render(loadedScene).then((preview: string) => {
						scenes[index] = { ...loadedScene, preview };
					}),
				);
		});

		return Promise.all(scenePromises).then(() => ({
			scenes: scenes.filter((scene) => scene !== null),
			design,
		}));
	};

	const handleImportTemplate = React.useCallback(
		(data: IDesign) => {
			return new Promise<void>((resolve, reject) => {
				loadGraphicTemplate(data)
					.then((template) => {
						if (template) {
							setScenes(template.scenes as IScene[]);
							setCurrentScene(template.scenes[0]);
							setCurrentDesign(template.design as IDesign);
							resolve();
						} else {
							reject(new Error("Template is undefined"));
						}
					})
					.catch((error) => {
						console.error("Error importing template:", error);
						reject(error);
					});
			});
		},
		[editor],
	);

	function base64toBlob(base64Data: string, contentType: string) {
		const sliceSize = 1024;
		const base64WithoutPrefix = base64Data.split(",")[1];
		const byteCharacters = atob(base64WithoutPrefix);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);
			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		return new Blob(byteArrays, { type: contentType });
	}

	const prepareTemplateForExport = async (template: Template) => {
		let storedScenes;
		if (campaignStore.selectedTemplate && campaignStore?.selectedTemplate?.id == template.id) {
			const scenes = await updateScenes();
			storedScenes = scenes.map((scene) => ({
				...scene,
				preview: scene.preview ?? "",
			}));
		} else {
			const templateToImport = await loadGraphicTemplate(template.ads_json);
			if (templateToImport) {
				storedScenes = templateToImport.scenes.map((scene) => ({
					...scene,
					preview: scene?.preview ?? "",
				}));
			}
		}
		return storedScenes;
	};

	const handleDownloadTemplate = async (template: Template) => {
		const zip = new JSZip();
		const storedScenes = await prepareTemplateForExport(template);

		const imagesBase64 = [];
		let i = 0;
		for (const scene of storedScenes ?? []) {
			i += 1;
			const imageData = await editor?.renderer.render(scene as IScene);
			imagesBase64.push(imageData);
			const blob = base64toBlob(imageData ?? "", "image/png");
			const imageName = scene?.name ? `${scene.name + i}.png` : `scene${i}.png`;
			zip.file(imageName, blob);
		}

		const metadataObj = {
			id: template.id,
			name: template.name,
		};
		const items = imagesBase64.map((base64) => ({
			type: IframePostMessageItemTypes.Image,
			src: base64 ?? "",
		}));
		if (template.status === CampaignEntityStatus.DRAFT) {
			iframeStore.sendExportPostMessage(
				IframePostMessageActionTypes.Download,
				IframePostMessageItemTypes.Campaign,
				items,
				{
					campaign: metadataObj,
				},
			);
		} else {
			iframeStore.sendExportPostMessage(
				IframePostMessageActionTypes.Download,
				IframePostMessageItemTypes.Template,
				items,
				{
					template: metadataObj,
				},
			);
		}
		const zipContent = await zip.generateAsync({ type: "blob" });
		const zipUrl = URL.createObjectURL(zipContent);
		const link = document.createElement("a");
		link.href = zipUrl;
		link.download = "scenes.zip";
		document.body.appendChild(link);
		link.click();

		// Clean up
		URL.revokeObjectURL(zipUrl);
		document.body.removeChild(link);
	};

	const handleIframeSaveTemplate = async (template: Template) => {
		const storedScenes = await prepareTemplateForExport(template);

		const imagesBase64 = [];
		for (const scene of storedScenes ?? []) {
			const imageData = await editor?.renderer.render(scene as IScene);
			imagesBase64.push(imageData);
		}

		const metadataObj = {
			id: template.id,
			name: template.name,
		};
		const items = imagesBase64.map((base64) => ({
			type: IframePostMessageItemTypes.Image,
			src: base64 ?? "",
		}));
		if (template.status === CampaignEntityStatus.DRAFT) {
			iframeStore.sendExportPostMessage(
				IframePostMessageActionTypes.Save,
				IframePostMessageItemTypes.Campaign,
				items,
				{
					campaign: metadataObj,
				},
			);
		} else {
			iframeStore.sendExportPostMessage(
				IframePostMessageActionTypes.Save,
				IframePostMessageItemTypes.Template,
				items,
				{
					template: metadataObj,
				},
			);
		}
	};

	const loadTemplate = React.useCallback(
		(template: any) => {
			return new Promise<void>((resolve, reject) => {
				if (editor) {
					handleImportTemplate(template)
						.then(() => {
							editor?.canvas.canvas.requestRenderAll();
							resolve();
						})
						.catch((error) => {
							console.error("Error loading template: ", error);
							reject(error);
						});
				} else {
					reject(new Error("Editor not initialized"));
				}
			});
		},
		[editor, currentScene],
	);

	const exportTemplate = async (l_scenes?: IScene[]) => {
		if (!l_scenes) {
			l_scenes = scenes;
		}

		const currentScene = editor && editor.scene.exportToJSON();
		if (currentScene) {
			const updatedScenes = l_scenes.map((scene) => {
				if (scene.id === currentScene.id) {
					return {
						id: currentScene.id,
						layers: currentScene.layers,
						name: currentScene.name,
					};
				}
				return {
					id: scene.id,
					layers: scene.layers,
					name: scene.name,
				};
			});
			if (currentDesign) {
				const graphicTemplate: IDesign = {
					id: currentDesign.id,
					name: currentDesign.name,
					frame: currentDesign.frame,
					scenes: updatedScenes,
					metadata: {},
					preview: "",
				};
				return graphicTemplate;
			} else {
				console.log(t("NoCurrentdesign"));
				return undefined;
			}
		}
	};

	const getCampaignDetailsFromURL = () => {
		const pathParts = window.location.pathname.split("/");
		const selectedType = pathParts[3];
		const fullId = pathParts[4];

		if (!selectedType || !fullId) {
			return { target: null, selectedType: null, id: null };
		}

		const target = fullId.charAt(0);
		const id = fullId.substring(1);

		return { target, selectedType: selectedType, id };
	};

	return {
		loadGraphicTemplate,
		loadTemplate,
		handleDownloadTemplate,
		handleIframeSaveTemplate,
		exportTemplate,
		getCampaignDetailsFromURL,
	};
};

export default UseTemplateUtils;
