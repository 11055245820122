import { Box, Divider } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../../components/common/BriaButton/BriaButton";
import BriaSlider from "../../../../../components/common/BriaSlider/BriaSlider.tsx";
import { MAX_ALLOWED_SELECTED_IMAGES } from "../../../../../constants/ImageToImageConstants.ts";
import { useImageToImageConfig } from "../../../../../hooks/useImageToImageConfig";
import { useAppStore } from "../../../../../hooks/useStores";
import InputLayout from "../../../../../layout/InputLayout/InputLayout";
import SwipableMenuButton from "../../../../../layout/SwipableMenu/Button/SwipableMenuButton";
import SwipableMenuLayout from "../../../../../layout/SwipableMenu/Menu/SwipableMenuLayout";
import { ImageEditingConfigsProps } from "../../../../../models/image-to-image";
import StyleConfig from "../Features/Style/StyleConfig";
import styles from "../ImageToImageConfig.module.scss";

const FoxConfigs = observer(({ handleGenerateImageToImage }: ImageEditingConfigsProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.imageToImage.config" });
	const { imageToImageStore, authStore } = useAppStore();
	const { isGenerateEnabled } = useImageToImageConfig();
	return (
		<>
			<SwipableMenuLayout className={styles.config}>
				<SwipableMenuButton
					swipeTo={<StyleConfig />}
					subSwipableMenuProps={{ title: t("features.style.title") }}
				>
					<Box className={styles.buttonLabel}>{t("features.style.button")}</Box>
				</SwipableMenuButton>
				<Divider />
				<InputLayout label={t("numberOfImages.label")} labelClassName={styles.inputLabel}>
					<BriaSlider
						value={imageToImageStore.config.num_results}
						onChange={(_, value) => imageToImageStore.handleConfigChange("num_results", value as number)}
						min={1}
						step={1}
						max={4}
						marks={Array.from({ length: 4 }, (_, index) => ({
							value: index + 1,
							label: `${index + 1}`,
						}))}
					/>
				</InputLayout>
			</SwipableMenuLayout>
			<BriaButton
				buttonType="primaryMedium"
				disabled={!isGenerateEnabled()}
				loading={authStore.isLoadingOrgSubscriptions}
				fullWidth
				onClick={handleGenerateImageToImage}
			>
				{imageToImageStore.getGeneratedImagesCount() > MAX_ALLOWED_SELECTED_IMAGES
					? t("tooManySelected")
					: t("button")}
			</BriaButton>
		</>
	);
});

export default FoxConfigs;
