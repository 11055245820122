import { BriaAxios } from "../config/axios.tsx";
import { IEventLog } from "../models/eventsLog.ts";

const BASE_URL = "/events";

class EventsLogService {
	logEvent = async (eventLog: IEventLog) => {
		const response = await (await BriaAxios()).post(`${BASE_URL}/`, eventLog);
		return response.data;
	};
}

export default EventsLogService;
