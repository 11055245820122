import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import ResizeObserver from "resize-observer-polyfill";
import LoadingPlaceholder from "../components/common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import { useAppStore } from "../hooks/useStores.tsx";
import { editorFonts } from "./constants/fonts";
import { DesignEditorContext } from "./contexts/DesignEditor.tsx";
import useAppContext from "./hooks/useAppContext";

function Container({ children }: { children: React.ReactNode }) {
	const { isPopupView } = useContext(DesignEditorContext);
	const { isMobile, setIsMobile } = useAppContext();
	const [loaded, setLoaded] = useState(false);
	const { designEditorStore, playgroundStore } = useAppStore();
	const updateMediaQuery = (value: number) => {
		if (!isMobile && value >= 800) {
			setIsMobile(false);
		} else if (!isMobile && value < 800) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};
	useEffect(() => {
		const containerElement = designEditorStore.containerRef.current!;
		const containerWidth = containerElement.clientWidth;
		updateMediaQuery(containerWidth);
		const resizeObserver = new ResizeObserver((entries) => {
			const { width = containerWidth } = (entries[0] && entries[0].contentRect) || {};
			updateMediaQuery(width);
		});
		resizeObserver.observe(containerElement);
		return () => {
			if (containerElement) {
				resizeObserver.unobserve(containerElement);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		loadFonts();
		setTimeout(() => {
			setLoaded(true);
		}, 1000);
	}, []);

	const loadFonts = () => {
		const promisesList = editorFonts.map((font) => {
			// @ts-ignore
			return new FontFace(font.name, `url(${font.url})`, font.options).load().catch((err) => err);
		});
		Promise.all(promisesList)
			.then((res) => {
				res.forEach((uniqueFont) => {
					if (uniqueFont && uniqueFont.family) {
						document.fonts.add(uniqueFont);
					}
				});
			})
			.catch((err) => console.log({ err }));
	};

	return (
		<div
			ref={designEditorStore.containerRef}
			style={{
				flex: 1,
				height: !isPopupView ? "100vh" : "75vh",
				background: isPopupView && designEditorStore.isTemplateEmptyState ? "" : "#FAFAFA",
				alignItems: loaded ? undefined : "center",
				overflow: loaded ? undefined : "hidden",
				display: isPopupView && playgroundStore.isText2ImagePopupOpened ? "none" : "flex",
			}}
		>
			<LoadingPlaceholder
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					width: "100vw",
				}}
				isLoading={!loaded}
			>
				{children}
			</LoadingPlaceholder>
		</div>
	);
}

const ObservedComponent = observer(Container);
export default ObservedComponent;
