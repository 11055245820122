import BackgroundGeneration from "../assets/images/pngs/APICards/API-Background-Generation.png";
import BaseModel from "../assets/images/pngs/APICards/API-Base-Model.png";
import backgroundBlur from "../assets/images/pngs/APICards/API-Blur-Background.png";
// import BrandedGeneration from "../assets/images/pngs/APICards/API-Branded-Generation.png";
import consistentStudioShotsPng from "../assets/images/pngs/APICards/API-Consistent-Studio-Shots.png";
import contextualKeywordExtractionPng from "../assets/images/pngs/APICards/API-Contextual-Keyword-Extraction.png";
import CropOutForeground from "../assets/images/pngs/APICards/API-Crop-Out-Foreground.png";
import DelayImage from "../assets/images/pngs/APICards/API-Delayer-Image.png";
import GenFill from "../assets/images/pngs/APICards/API-Erase-And-Replace.png";
import EraseElements from "../assets/images/pngs/APICards/API-Erase-Elements.png";
// import EraseForeground from "../assets/images/pngs/APICards/API-Erase-Foreground.png";
import FastModel from "../assets/images/pngs/APICards/API-Fast-Model.png";
import ImageReference from "../assets/images/pngs/APICards/API-Generate-image-ref.png";
import HDModel from "../assets/images/pngs/APICards/API-HD-Model.png";
import imageExpansion from "../assets/images/pngs/APICards/API-Image-Expansion.png";
import increase_res from "../assets/images/pngs/APICards/API-Increase-Resolution.png";
import lifestyleProductShotByImagePng from "../assets/images/pngs/APICards/API-Lifestyle-Product-Image.png";
import lifestyleProductTextPng from "../assets/images/pngs/APICards/API-Lifestyle-Product-Text.png";
import masksGenerator from "../assets/images/pngs/APICards/API-Masks-Generator.png";
// import modifyPresenter from "../assets/images/pngs/APICards/API-Modify-Presenter.png";
import productCutoutsPng from "../assets/images/pngs/APICards/API-Product-Cutouts.png";
import productPackShotsPng from "../assets/images/pngs/APICards/API-Product-Pack-Shots.png";
import productShadowPng from "../assets/images/pngs/APICards/API-Product-Shadow.png";
import PromptEnhancement from "../assets/images/pngs/APICards/API-Prompt-enhancement.png";
import backgroundRemoval from "../assets/images/pngs/APICards/API-Remove-Background.png";
import VectorFast from "../assets/images/pngs/APICards/API-Vector-Fast.png";
import VectorBase from "../assets/images/pngs/APICards/API-Vector-base.png";
import VideoBackgroundRemove from "../assets/images/pngs/APICards/API-Video-Background-Remove.png";
import RouterConstants from "./RouterConstants";

export const SandboxAPIsConstants = {
	imageGeneration: [
		{
			title: "baseModel",
			description: "baseModelDescription",
			link: RouterConstants.TEXT_TO_IMAGE_BASE.path,
			image: BaseModel,
			cardClickable: true,
		},
		{
			title: "fastModel",
			description: "fastModelDescription",
			link: RouterConstants.TEXT_TO_IMAGE_FAST.path,
			image: FastModel,
			cardClickable: true,
		},
		{
			title: "HDModel",
			description: "HDModelDescription",
			link: RouterConstants.TEXT_TO_IMAGE_HD.path,
			image: HDModel,
			cardClickable: true,
		},
		{
			title: "vectorGraphicsBase",
			description: "vectorGraphicsBaseDescription",
			link: RouterConstants.TEXT_TO_VECTOR_BASE.path,
			image: VectorBase,
			cardClickable: true,
		},
		{
			title: "vectorGraphicsFast",
			description: "vectorGraphicsFastDescription",
			link: RouterConstants.TEXT_TO_VECTOR_FAST.path,
			image: VectorFast,
			cardClickable: true,
		},
		{
			title: "generateImageWithReference",
			description: "generateImageWithReferenceDescription",
			link: RouterConstants.GENERATE_IMAGE_WITH_REFERENCE.path,
			image: ImageReference,
			cardClickable: true,
		},
		{
			title: "promptEnhancement",
			description: "promptEnhancementDescription",
			link: RouterConstants.PROMPT_ENHANCEMENT.path,
			image: PromptEnhancement,
			cardClickable: true,
		},
		// {
		// 	title: "brandedGeneration",
		// 	description: "brandedGenerationDescription",
		// 	link: RouterConstants.BRANDED_GENERATION.path,
		// 	image: BrandedGeneration,
		// 	cardClickable: true,
		// },
	],
	imageModification: [
		{
			title: "genFill",
			description: "genFillDescription",
			link: RouterConstants.ERASE_AND_REPLACE_APP.path,
			image: GenFill,
			cardClickable: true,
		},
		{
			title: "eraseElements",
			description: "eraseElementsDescription",
			link: RouterConstants.ERASER_APP.path,
			image: EraseElements,
			cardClickable: true,
		},
		{
			title: "backgroundGeneration",
			description: "backgroundGenerationDescription",
			link: RouterConstants.GENERATE_BACKGROUND_APP.path,
			image: BackgroundGeneration,
			cardClickable: true,
		},
		{
			title: "image_Expansion",
			description: "imageExpansionDescription",
			link: RouterConstants.EXPAND_IMAGE_APP.path,
			image: imageExpansion,
			cardClickable: true,
		},

		{
			title: "background_Removal",
			description: "backgroundRemovalDescription",
			link: RouterConstants.REMOVE_BACKGROUND_APP.path,
			image: backgroundRemoval,
			cardClickable: true,
		},
		{
			title: "blur_Background",
			description: "blurBackgroundDescription",
			link: RouterConstants.BLUR_BACKGROUND_APP.path,
			image: backgroundBlur,
			cardClickable: true,
		},
		{
			title: "increase_Resolution",
			description: "increaseResolutionDescription",
			link: RouterConstants.INCREASE_RESOLUTION_APP.path,
			image: increase_res,
			cardClickable: true,
		},
		{
			title: "cropOutForeground",
			description: "cropOutForegroundDescription",
			link: RouterConstants.CROP_OUT_FOREGROUND_APP.path,
			image: CropOutForeground,
			cardClickable: true,
		},
		// {
		// 	title: "eraseForeground",
		// 	description: "eraseForegroundDescription",
		// 	image: EraseForeground,
		// 	cardClickable: true,
		// },
		{
			title: "masks_Generator",
			description: "masksGeneratorDescription",
			link: RouterConstants.GET_MASKS_APP.path,
			image: masksGenerator,
			cardClickable: true,
		},
		{
			title: "delayerImage",
			description: "delayerImageDescription",
			link: RouterConstants.DELAYER_IMAGE_APP.path,
			image: DelayImage,
			cardClickable: true,
		},
		// {
		// 	title: "modify_Presenter",
		// 	description: "modifyPresenterDescription",
		// 	image: modifyPresenter,
		// 	cardClickable: true,
		// },
	],
	eCommerceSuite: [
		{
			title: "productCutouts",
			description: "productCutoutsDescription",
			link: RouterConstants.PRODUCT_CUTOUT_APP.path,
			image: productCutoutsPng,
			cardClickable: true,
		},
		{
			title: "productPackShots",
			description: "productPackShotsDescription",
			link: RouterConstants.PRODUCT_PACKSHOT_APP.path,
			image: productPackShotsPng,
			cardClickable: true,
		},
		{
			title: "productShadows",
			description: "productShadowDescription",
			link: RouterConstants.PRODUCT_SHADOW_APP.path,
			image: productShadowPng,
			cardClickable: true,
		},
		{
			title: "lifestyleProductShotByText",
			description: "lifestyleProductShotByTextDescription",
			link: RouterConstants.LIFESTYLE_PRODUCT_SHOT_BY_TEXT_APP.path,
			image: lifestyleProductTextPng,
			cardClickable: true,
		},
		{
			title: "lifestyleProductShotByImage",
			description: "lifestyleProductShotByImageDescription",
			image: lifestyleProductShotByImagePng,
			badges: ["comingSoon"],
			cardClickable: true,
		},
		{
			title: "consistentStudioShots",
			description: "consistentStudioShotsDescription",
			image: consistentStudioShotsPng,
			badges: ["comingSoon"],
			cardClickable: true,
		},
		{
			title: "contextualKeywordExtraction",
			description: "contextualKeywordExtractionDescription",
			image: contextualKeywordExtractionPng,
			badges: ["comingSoon"],
			cardClickable: true,
		},
	],
	videoEditing: [
		{
			title: "videoBackgroundRemove",
			description: "videoBackgroundRemoveDescription",
			image: VideoBackgroundRemove,
			link: RouterConstants.VIDEO_BACKGROUND_REMOVE_APP.path,
			cardClickable: true,
		},
	],
};
