import { makeAutoObservable, runInAction } from "mobx";
import { BriaAxios } from "../config/axios";
import { IRootStore } from "../mobx/root-store.tsx";
import { InvitedUser } from "../models/Invitation.ts";
import { IAgent } from "../models/agent.ts";
import { IEventLog } from "../models/eventsLog.ts";
import { ApiKey, MyAccountUser } from "../models/organization";
import User from "../models/user.ts";
import EventsService from "../services/EventsService.ts";
import ModelsService from "../services/ModelsService.ts";
import { hasOrganization } from "../utils/index.ts";

export interface IAppStore {
	isLoading: boolean;
	isSendRequestAccessLoading: boolean;
	isUserDeletionLoading: boolean;
	isPopupLoading: boolean;
	isQuoteLoading: boolean;
	isVerifyingAgentInstallation: boolean;
	apiKeys: ApiKey[];
	isError: boolean;
	invitedUser: InvitedUser | null;
	ancestorsUsers: MyAccountUser[];
	myAccountUsers: MyAccountUser[];
	agentInstallation: IAgent | null;
	dismissWarningAgentMessage: boolean;

	loadAncestorsUsers(): Promise<void>;

	grantUsersPermission(selectedUsers: string[], role: string): Promise<void>;

	loadMyAccountUsers(): Promise<void>;

	sendUserInvitations(emails: string[]): Promise<boolean>;

	sendRequestAccess(
		userName: string,
		userEmail: string,
		orgName: string,
		message: string,
		phoneNumber: string,
		requestFeature: string,
	): Promise<boolean>;

	getUserInvitation(token: string): Promise<void>;

	acceptUserInvitation(): Promise<void>;

	updateMyAccountUser(usersToUpdate: any[]): Promise<void>;

	deleteMyAccountUser(user_uid: string): Promise<void>;

	deleteUser(): Promise<any>;

	validateUserDeletion(): Promise<any>;

	loadApiKeys(): Promise<void>;

	createApiKey(keyType: string): Promise<void>;

	updateApiKey(id: string, status: string): Promise<void>;

	logEvent(eventLog: IEventLog): Promise<void>;

	verifyAgentInstallation(): Promise<void>;

	deleteUserAndOrganization(): Promise<void>;

	updateUserInfo(user: User, user_name: string, profile_picture?: string): Promise<void>;
}

export default class AppStore implements IAppStore {
	rootStore: IRootStore;
	apiKeys: ApiKey[] = [];
	ancestorsUsers: MyAccountUser[] = [];
	myAccountUsers: MyAccountUser[] = [];
	isLoading: boolean = false;
	isError: boolean = false;
	isVerifyingAgentInstallation: boolean = false;
	invitedUser: InvitedUser | null = null;
	isPopupLoading: boolean = false;
	isSendRequestAccessLoading: boolean = false;
	isUserDeletionLoading: boolean = false;
	isQuoteLoading: boolean = false;
	eventsService = new EventsService();
	modelsService = new ModelsService();
	agentInstallation: IAgent | null = null;
	dismissWarningAgentMessage: boolean = false;

	constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.rootStore = rootStore;
	}

	loadMyAccountUsers = async (): Promise<any> => {
		this.isLoading = true;
		this.isError = false;
		try {
			(await BriaAxios()).get(`/my_account_users/`).then((res) => {
				runInAction(() => {
					if (res.data.length === 0) {
						this.isError = true;
					} else {
						this.myAccountUsers = res.data;
						this.isError = false;
					}
					this.isLoading = false;
				});
				return Promise.resolve(res.data);
			});
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	};

	loadAncestorsUsers = async (): Promise<any> => {
		try {
			this.isPopupLoading = true;
			const response = await (await BriaAxios()).get(`/my_account_users/ancestors/`);
			runInAction(() => {
				this.ancestorsUsers = response.data;
				this.isPopupLoading = false;
			});
		} catch (e) {
			this.isPopupLoading = false;
			this.isError = true;
			return Promise.reject(e);
		}
	};

	grantUsersPermission = async (selectedUsers: string[], role: string): Promise<void> => {
		try {
			this.isLoading = true;
			const data = {
				users: selectedUsers,
				role: role,
			};
			const response = await (await BriaAxios()).post(`/my_account_users/grant_permission/`, data);
			runInAction(() => {
				if (response.data.length > 0) {
					this.myAccountUsers = [...this.myAccountUsers, ...response.data];
				}
				this.isLoading = false;
				this.isError = false;
				this.ancestorsUsers = [];
				return Promise.resolve(true);
			});
			return Promise.resolve(response.data);
		} catch (e) {
			this.isPopupLoading = false;
			this.isError = true;
			return Promise.reject(e);
		}
	};

	updateMyAccountUser = async (usersToUpdate: any[]): Promise<any> => {
		this.isLoading = true;
		this.isError = false;
		try {
			const response = await (await BriaAxios()).post(`/my_account_user/update/`, usersToUpdate);
			runInAction(() => {
				this.myAccountUsers = [
					...this.myAccountUsers.map((user: MyAccountUser) => {
						if (user.uid === response.data.uid) {
							return response.data;
						}
						return user;
					}),
				];
				this.isLoading = false;
				this.isError = false;
				return Promise.resolve(true);
			});
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	};

	deleteMyAccountUser = async (user_uid: string): Promise<any> => {
		this.isLoading = true;
		this.isError = false;
		try {
			const data = {
				uid: user_uid,
			};
			(await BriaAxios()).post(`/my_account_user/delete/`, data).then((res) => {
				runInAction(() => {
					this.myAccountUsers = this.myAccountUsers.filter((user) => user.uid !== res.data.uid);

					this.isLoading = false;
					this.isError = false;
					return Promise.resolve(true);
				});
			});
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	};

	deleteUser = async (): Promise<any> => {
		this.isLoading = true;
		this.isError = false;
		try {
			const response = await (await BriaAxios()).delete(`/users/`);
			if (response.status === 200) {
				return Promise.resolve(response.data);
			}
		} catch (e) {
			return Promise.reject(e);
		}
	};

	validateUserDeletion = async (): Promise<any> => {
		try {
			const response = await (await BriaAxios()).get(`/users/validate_deletion/`);
			if (response.status === 200) {
				return Promise.resolve(response.data);
			}
		} catch (e) {
			return Promise.reject(e);
		}
	};

	async sendUserInvitations(emails: string[]): Promise<boolean> {
		this.isError = false;
		this.isPopupLoading = true;
		const data = {
			emails: emails,
		};
		try {
			const response = await (await BriaAxios()).post(`/my_account_user/invitation/`, data);
			runInAction(() => {
				this.isError = false;
				this.isPopupLoading = false;
			});
			return response.status === 200;
		} catch (e) {
			runInAction(() => {
				this.isPopupLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	}

	async sendRequestAccess(
		userName: string,
		userEmail: string,
		orgName: string,
		message: string,
		phoneNumber: string,
		requestFeature: string,
	): Promise<boolean> {
		this.isError = false;
		this.isSendRequestAccessLoading = true;
		const data = {
			userName: userName,
			userEmail: userEmail,
			orgName: orgName,
			message: message,
			phoneNumber: phoneNumber,
			requestFeature: requestFeature,
		};
		try {
			const response = await (await BriaAxios()).post(`/request_access/`, data);
			runInAction(() => {
				this.isError = false;
				this.isSendRequestAccessLoading = false;
			});
			return response.status === 200;
		} catch (e) {
			runInAction(() => {
				this.isSendRequestAccessLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	}

	async getUserInvitation(token: string | undefined): Promise<void> {
		this.isError = false;
		try {
			const response = await (await BriaAxios()).get(`/my_account_user/invitation/${token}/`);
			runInAction(() => {
				if (response.status === 200 && response?.data?.org_name !== undefined) {
					this.invitedUser = response?.data;
				} else {
					this.isError = true;
				}
				this.isLoading = false;
				if (this.invitedUser) {
					this.invitedUser.flow_step = 1;
				}
			});
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	}

	async acceptUserInvitation(): Promise<void> {
		try {
			await (await BriaAxios()).post(`/my_account_user/invitation/${this.invitedUser?.token}/`, {});
			runInAction(() => {
				this.isError = false;
				this.isLoading = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	}

	async loadApiKeys(): Promise<any> {
		this.isLoading = true;
		this.isError = false;
		try {
			await (await BriaAxios()).get("/api_keys/").then((res) => {
				runInAction(() => {
					this.apiKeys = res.data;
					this.isLoading = false;
					this.isError = false;
				});
				return Promise.resolve(res.data);
			});
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	}

	async createApiKey(keyType: string): Promise<any> {
		this.isLoading = true;
		this.isError = false;
		try {
			await (await BriaAxios()).post(`/create_api_key/`, { key_type: keyType }).then((res) => {
				runInAction(() => {
					this.apiKeys = [...this.apiKeys, res.data];
					this.isLoading = false;
					this.isError = false;
				});
				return Promise.resolve(true);
			});
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	}

	async updateApiKey(api_token: string, status: string): Promise<any> {
		this.isLoading = true;
		this.isError = false;
		const data = {
			api_token: api_token,
			status: status,
		};
		try {
			await (await BriaAxios()).post(`/update_api_key/`, data).then((res) => {
				runInAction(() => {
					this.apiKeys = [
						...this.apiKeys.map((key: ApiKey) => {
							if (key.api_token === res.data.api_token) {
								return res.data;
							}
							return key;
						}),
					];
					this.isLoading = false;
					this.isError = false;
					return Promise.resolve(true);
				});
			});
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	}

	async logEvent(model: IEventLog): Promise<void> {
		this.isError = false;
		this.isLoading = false;
		const hasOrg = hasOrganization();

		try {
			await this.eventsService.logEvent(model);
			if (!hasOrg) {
				await this.rootStore.analyticsStore.logHubspotEvent(model.eventType, true);
			}
			runInAction(() => {
				this.isLoading = false;
				this.isError = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	}

	verifyAgentInstallation = async () => {
		this.isError = false;
		this.isVerifyingAgentInstallation = true;
		try {
			const response = await this.modelsService.verifyAgentInstallation();
			runInAction(() => {
				console.log(response);
				this.agentInstallation = response;
				this.isError = false;
				this.isVerifyingAgentInstallation = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isVerifyingAgentInstallation = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	};

	deleteUserAndOrganization = async (): Promise<void> => {
		this.isUserDeletionLoading = true;
		this.isError = false;
		try {
			const response = await (await BriaAxios()).post(`/delete_organization/`);
			if (response.status === 200) {
				runInAction(() => {
					this.isUserDeletionLoading = false;
					this.isError = false;
				});
				return Promise.resolve();
			} else {
				throw new Error("Failed to delete organization");
			}
		} catch (error) {
			console.error("Error deleting organization:", error);
			runInAction(() => {
				this.isUserDeletionLoading = false;
				this.isError = true;
			});
			return Promise.reject(error);
		}
	};

	updateUserInfo = async (user: User, user_name: string, profile_picture?: string): Promise<void> => {
		runInAction(() => {
			this.isError = false;
		});
		try {
			const data = {
				user_name,
				profile_picture: profile_picture ?? user.profilePicture,
			};
			const response = await (await BriaAxios()).put(`/update_user_info`, data);
			if (response.status === 200) {
				runInAction(() => {
					this.isError = false;
				});
				return Promise.resolve();
			} else {
				throw new Error("Failed to update user info");
			}
		} catch (error) {
			console.error("Error updating user info:", error);
			runInAction(() => {
				this.isError = true;
			});
			return Promise.reject(error);
		}
	};
}
