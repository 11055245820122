import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../../../assets/images/icons/DeleteIcon";
import DownloadIcon from "../../../../../assets/images/icons/DownloadIcon";
import FullscreenIcon from "../../../../../assets/images/icons/Image/FullscreenIcon";
import BriaTooltip from "../../../../../components/common/BriaTooltip/BriaTooltip";
import { BriaImageProps } from "../../../../../components/common/Galleries/BriaImage.tsx";
import ScrollableGallery from "../../../../../components/common/Galleries/ScrollableGallery";
import { useAppStore } from "../../../../../hooks/useStores";
import { ImageToImageConfigType, PlaygroundImage, PlaygroundResult } from "../../../../../models/image-to-image";
import iframeStore from "../../../../IframeNew/iframe-store.tsx";
import PlaygroundImageOverlay from "../../../Images/Overlay/PlaygroundImageOverlay";
import styles from "./NewCollectionResult.module.scss";

type Props = {
	playgroundResult: PlaygroundResult;
};

const NewCollectionResult = ({ playgroundResult }: Props) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.newCollection.result" });
	const { playgroundStore, uiStore } = useAppStore();
	const [openFullScreen, setOpenFullScreen] = useState(false);

	const handleDownloadImages = () => {
		uiStore.showExportImagePopup({ images: playgroundResult.images });
	};

	return (
		<Box className={clsx(styles.newCollectionGalleryWrapper, "galleryWrapper")}>
			<Box className={styles.header}>
				<Box className={styles.headerText}>{t("header")}</Box>
				<Box className={styles.actionBtns}>
					{(!iframeStore.isIframe() ||
						iframeStore.iframe.config.general_config?.enable_download ||
						iframeStore.iframe.config.general_config?.enable_save) && (
						<BriaTooltip title={t("download")}>
							<DownloadIcon className={styles.iconButton} onClick={handleDownloadImages} />
						</BriaTooltip>
					)}
					<BriaTooltip title={"Delete"}>
						<DeleteIcon
							className={styles.iconButton}
							onClick={() => playgroundStore.deleteResult(playgroundResult.id)}
						/>
					</BriaTooltip>
					<BriaTooltip title={t("fullScreen")}>
						<FullscreenIcon className={styles.iconButton} onClick={() => setOpenFullScreen(true)} />
					</BriaTooltip>
				</Box>
			</Box>
			<ScrollableGallery
				images={playgroundResult.images.map(
					(image) =>
						({
							ImageOverlay: <PlaygroundImageOverlay {...{ image }} />,
							displayOverlay: "customHover",
							selectable: true,
							htmlJsonData: image.id,
							fullScreenProps: {
								compareTo: (image.config as ImageToImageConfigType)?.original_image?.url,
								fileName: playgroundStore.getFileName(image),
							},
							...image,
						}) as BriaImageProps & PlaygroundImage,
				)}
				fullScreenProps={{
					open: openFullScreen,
					onClose: () => setOpenFullScreen(false),
					displayMode: "scroll",
				}}
				className={styles.gallery}
			/>
		</Box>
	);
};

export default observer(NewCollectionResult);
