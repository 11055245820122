import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ANALYTICS_EVENTS } from "../../../../../../analytics-store.tsx";
import ResetIcon from "../../../../../../assets/images/svgs/reset.svg";
import BriaButton from "../../../../../../components/common/BriaButton/BriaButton.tsx";
import BriaIconButton from "../../../../../../components/common/BriaIconButton/BriaIconButton.tsx";
import BriaInput from "../../../../../../components/common/BriaInput/BriaInput.tsx";
import BriaSlider from "../../../../../../components/common/BriaSlider/BriaSlider.tsx";
import { useAppStore } from "../../../../../../hooks/useStores.tsx";
import InputLayout from "../../../../../../layout/InputLayout/InputLayout.tsx";
import {
	ConfigValue,
	ImageEditingConfigComponentProps,
	ImageEditingViewMode,
} from "../../../../../../models/image-to-image.ts";
import Config from "../../Config/Config.tsx";
import styles from "./EraseObject.module.scss";

const EraseObject = observer(({ onBackButtonClick }: ImageEditingConfigComponentProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.imageToImage.config.features.eraseObject" });
	const { imageToImageStore, playgroundStore, analyticsStore } = useAppStore();
	const [isSingleMode] = useState<boolean>(playgroundStore.imageEditingViewMode === ImageEditingViewMode.SINGLE);
	const isGenerateFillDisabled =
		imageToImageStore.isErasingImages ||
		!imageToImageStore.isMaskApplied ||
		!imageToImageStore.config.object_manipulation?.prompt;

	useEffect(() => {
		if (!isSingleMode) {
			playgroundStore.togglePlaygroundViewMode();
		}
		playgroundStore.togglePlaygroundBrushMode(true);
		imageToImageStore.toggleEraseConfigs();

		return () => {
			playgroundStore.togglePlaygroundBrushMode(false);
			imageToImageStore.toggleEraseConfigs();
			imageToImageStore.toggleIsMaskApplied(false);
		};
	}, []);

	useEffect(() => {
		if (!(playgroundStore.imageEditingViewMode === ImageEditingViewMode.SINGLE)) {
			onBackButtonClick?.();
		}
	}, [playgroundStore.imageEditingViewMode]);

	const handleEraseClick = async () => {
		const selectedImage =
			playgroundStore.singleSelectedImage || playgroundStore.getAvailableImages().find((image) => image.selected);
		if (selectedImage) {
			try {
				playgroundStore.togglePlaygroundBrushMode(false);
				await imageToImageStore.eraseObject(selectedImage);
				analyticsStore.logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_ERASE);
			} catch (e) {
				throw new Error(`Error erasing object: ${e}`);
			} finally {
				imageToImageStore.isEraseConfigsEnabled && playgroundStore.togglePlaygroundBrushMode(true);
			}
		}
	};

	const handleEraseConfigChange = (field: string, value: ConfigValue) => {
		imageToImageStore.handleConfigChange("object_manipulation", {
			...imageToImageStore.config.object_manipulation,
			[field]: value,
		});
	};

	const handleEraseWithReplaceByTextClick = async () => {
		const selectedImage = playgroundStore.singleSelectedImage || playgroundStore.getAvailableImages()[0];
		if (selectedImage) {
			try {
				playgroundStore.togglePlaygroundBrushMode(false);
				await imageToImageStore.callGenFillApi([selectedImage], imageToImageStore.config);
				analyticsStore.logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_ERASE);
			} catch (e) {
				throw new Error(`Error erasing object: ${e}`);
			} finally {
				imageToImageStore.isEraseConfigsEnabled && playgroundStore.togglePlaygroundBrushMode(true);
			}
		}
	};

	return (
		<Box className={styles.eraseObjectConfigContainer}>
			<Config title={t("brush")} titleOnly isActive className={styles.brushConfig}>
				<BriaSlider
					value={imageToImageStore.brushConfigs.lineWidth}
					onChange={(e: any) => {
						imageToImageStore.brushConfigs.lineWidth = Number(e.target.value ?? 0);
					}}
					min={0}
					max={100}
					valueLabelDisplay={"on"}
					fieldLabels={[t("minimum"), t("maximum")]}
					className={styles.brushSlider}
				/>
				<BriaIconButton
					className={styles.resetBrush}
					onClick={async () => {
						imageToImageStore.handleResetBrusher();
						imageToImageStore.toggleIsMaskApplied(false);
					}}
					size="small"
				>
					<img src={ResetIcon} />
				</BriaIconButton>
			</Config>
			<Config title={t("removeObject")} titleOnly isActive className={styles.removeObjectConfig}>
				<BriaButton
					buttonType="primaryMedium"
					disabled={imageToImageStore.isErasingImages || !imageToImageStore.isMaskApplied}
					onClick={handleEraseClick}
					size="small"
					className={styles.eraseObjectBtn}
				>
					{t("remove")}
				</BriaButton>
			</Config>
			<Config
				title={t("replaceObject")}
				titleOnly
				isActive
				className={styles.replaceObjectConfig}
				tooltip={t("replaceObjectTooltip")}
			>
				<BriaInput
					value={imageToImageStore.config.object_manipulation?.prompt}
					placeholder={t("replaceObjectPlaceholder")}
					onChange={(e) => {
						const target = e.target as HTMLInputElement;
						handleEraseConfigChange("prompt", target.value.replace(/\n/g, ""));
					}}
					multiline
					minRows={2}
					className={styles.replaceObjectPrompt}
					{...(!isGenerateFillDisabled && { onEnterPress: handleEraseWithReplaceByTextClick })}
				/>
				<InputLayout label={t("numOfResults")} labelClassName={styles.inputLabel}>
					<BriaSlider
						value={imageToImageStore.config.object_manipulation?.num_results}
						className={styles.numberSlider}
						onChange={(_e, value) => {
							handleEraseConfigChange("num_results", value as number);
						}}
						min={1}
						step={1}
						max={4}
						marks={Array.from({ length: 4 }, (_, index) => ({
							value: index + 1,
							label: `${index + 1}`,
						}))}
					/>
				</InputLayout>
				<Box className={styles.eraseObjectGenerate}>
					<BriaButton
						buttonType="primaryMedium"
						disabled={isGenerateFillDisabled}
						onClick={handleEraseWithReplaceByTextClick}
						size="small"
						className={styles.eraseObjectBtn}
					>
						{t("generate")}
					</BriaButton>
				</Box>
			</Config>
		</Box>
	);
});

export default EraseObject;
