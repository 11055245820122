import { ANALYTICS_EVENTS } from "../analytics-store";
import { APPS } from "../constants/AppsConstants";
import { PlaygroundImage } from "../models/playground.ts";
import { modelTypes } from "../models/sandboxAPI";
import { TextToImageConfigType } from "../models/text-to-image.ts";
import { isFoxApps } from "../utils";
import { useImageToImageConfig } from "./useImageToImageConfig";
import { useAppStore } from "./useStores";

export const useLogDownloadEvents = () => {
	const {
		canExpandBackground,
		canRemoveBackground,
		canBlurBackground,
		canApplySolidBackground,
		canGenerateBackgroundByText,
		canApplyAspectRatio,
		canAppleCustomSize,
		canAppleMediumDestination,
		canGenerateForegroundPlacement,
	} = useImageToImageConfig();

	const { analyticsStore, textToImageStore } = useAppStore();

	const logImageDownloadEvents = (images: PlaygroundImage[], fileType: string, resolution?: number) => {
		const { logEvent } = analyticsStore;
		images.forEach((image) => {
			const config = image.config as TextToImageConfigType;
			const additionalParams: { fileType: string; app?: string; model_name?: string } = {
				fileType: fileType,
				app: image.type,
			};
			if (image.type === APPS.TEXT_TO_IMAGE || image.type === APPS.TEXT_TO_VECTOR) {
				additionalParams.model_name = config.model_name;

				logEvent(ANALYTICS_EVENTS.IMAGE_GENERATION_DOWNLOAD_GENERAL, additionalParams);

				if (config.tailored_model_id) {
					logEvent(ANALYTICS_EVENTS.IMAGE_GENERATION_DOWNLOAD_TAILORED_MODELS, {
						tailoredModelId: config.tailored_model_id,
						...additionalParams,
					});
				} else {
					const modelEventMap = {
						[modelTypes.Fast]: ANALYTICS_EVENTS.IMAGE_GENERATION_DOWNLOAD_BRIA_FAST,
						[modelTypes.Base]: ANALYTICS_EVENTS.IMAGE_GENERATION_DOWNLOAD_BRIA_BASE,
						[modelTypes.Hd]: ANALYTICS_EVENTS.IMAGE_GENERATION_DOWNLOAD_BRIA_HD,
					};

					const modelEvent = modelEventMap[config.model as keyof typeof modelEventMap];
					if (modelEvent) {
						logEvent(modelEvent, additionalParams);
					}

					if (textToImageStore.isImageReferenceEnabled()) {
						logEvent(ANALYTICS_EVENTS.IMAGE_GENERATION_DOWNLOAD_IMAGE_REFERENCE, additionalParams);
					}

					if (resolution && resolution > 1) {
						logEvent(ANALYTICS_EVENTS.IMAGE_GENERATION_INCREASE_RESOLUTION, additionalParams);
					}
				}
			} else if (image.type === APPS.IMAGE_TO_IMAGE) {
				if (isFoxApps()) {
					analyticsStore.logImageToImageEvent(ANALYTICS_EVENTS.IMAGE_DOWNLOAD_ITI, image);
				} else {
					// Background configs events
					canExpandBackground?.() &&
						logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_DOWNLOAD_EXPAND_IMAGE, additionalParams);
					canRemoveBackground?.() &&
						logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_DOWNLOAD_REMOVE_BACKGROUND, additionalParams);
					canBlurBackground?.() &&
						logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_DOWNLOAD_BLUR_BACKGROUND, additionalParams);
					canApplySolidBackground?.() &&
						logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_DOWNLOAD_SOLID_BACKGROUND, additionalParams);
					canGenerateBackgroundByText?.() &&
						logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_DOWNLOAD_GENERATE_BACKGROUND_BY_TEXT, additionalParams);

					// Size configs events
					canApplyAspectRatio?.() &&
						logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_DOWNLOAD_ASPECT_RATIO, additionalParams);
					canAppleCustomSize?.() &&
						logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_DOWNLOAD_CUSTOM_SIZE, additionalParams);
					canAppleMediumDestination?.() &&
						logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_DOWNLOAD_MEDIUM_DESTINATION, additionalParams);

					// Foreground placement configs events
					canGenerateForegroundPlacement?.() &&
						logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_DOWNLOAD_FOREGROUND, additionalParams);

					if (resolution && resolution > 1) {
						logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_INCREASE_RESOLUTION, additionalParams);
					}
				}
			} else if (image.type === APPS.PRODUCT_PLACEMENT) {
				analyticsStore.logEvent(ANALYTICS_EVENTS.IMAGE_DOWNLOAD_PP, additionalParams);
			} else {
				analyticsStore.logEvent(ANALYTICS_EVENTS.IMAGE_DOWNLOAD, additionalParams);
			}
		});
	};

	return { logImageDownloadEvents };
};
