import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useAppStore } from "../../../../../../../../hooks/useStores.tsx";
import Search from "../../../../../../../assets/svgs/search.svg";
import { DesignEditorContext } from "../../../../../../../contexts/DesignEditor.tsx";
import { SecondaryTabTypeEnum } from "../../index.ts";
import styles from "./TabHeader.module.scss";

export type IProps = {
	setSearchBoxVisible: (value: boolean) => void;
	searchBoxVisible: boolean;
	headerText?: string;
	enableSearch?: boolean;
	headerDescription?: string;
};

const TabHeader = ({
	setSearchBoxVisible,
	searchBoxVisible,
	headerText,
	enableSearch = false,
	headerDescription = "",
}: IProps) => {
	const { imagesStore } = useAppStore();
	const { isPopupView } = useContext(DesignEditorContext);

	return (
		<>
			<Box className={styles.tapHeader}>
				<Box className={styles.titleHeader}>
					<Typography
						className={clsx(styles.headerTitle, {
							[styles.headerTitleExtraFontSize]:
								imagesStore.secondaryTabType === SecondaryTabTypeEnum.SMART_IMAGE,
						})}
					>
						{headerText}
					</Typography>
					<Box className={styles.iconsContainer}>
						{enableSearch && (
							<img
								className={styles.searchImg}
								onClick={() => {
									setSearchBoxVisible(!searchBoxVisible);
								}}
								src={Search}
							/>
						)}

						{!isPopupView && (
							<Box className={styles.backButtonBox} onClick={() => (imagesStore.secondaryTabType = null)}>
								<ChevronLeftIcon className={styles.backButton} />
							</Box>
						)}
					</Box>
				</Box>
				{headerDescription && <Typography className={styles.headerDescription}>{headerDescription}</Typography>}
			</Box>
		</>
	);
};

export default observer(TabHeader);
