import { Box, Slider, Typography } from "@mui/material";
import { styled } from "baseui";
import { Theme } from "baseui/theme";
import { useEffect, useState } from "react";
import useContextMenuTimelineRequest from "../../../../../hooks/useContextMenuTimelineRequest";
import { useEditor } from "../../../../../hooks/useEditor";
import { useZoomRatio } from "../../../../../hooks/useZoomRatio";
import styles from "./Graphic.module.scss";
import SceneContextMenu from "./SceneContextMenu/SceneContextMenu";
import Scenes from "./Scenes/Scenes";

const Container = styled<"div", {}, Theme>("div", ({ $theme }) => ({
	background: $theme.colors.white,
	marginRight: "8px",
	marginBottom: "8px",
	borderRadius: "8px",
	border: "1px solid #e7e7e7",
	overflowX: "auto",
	maxWidth: "calc(-268px + 90vw)",
	overflowY: "hidden",
}));
interface Options {
	zoomRatio: number;
}
export default function () {
	const contextMenuTimelineRequest = useContextMenuTimelineRequest();
	const editor = useEditor();
	const zoomMin = 10;
	const zoomMax = 240;
	const [options, setOptions] = useState<Options>({
		zoomRatio: 50,
	});
	const zoomRatio: number = useZoomRatio();

	useEffect(() => {
		setOptions({ ...options, zoomRatio: Math.round(zoomRatio * 100) });
	}, [zoomRatio]);

	return (
		<>
			<Box className={styles.sliderContainer}>
				<Box className={styles.sliderInput}>
					<Slider
						min={zoomMin}
						max={zoomMax}
						defaultValue={50}
						value={Number([options.zoomRatio])}
						onChange={(_event, value) => {
							const zoomValue = value as number;
							if (zoomValue < 0) {
								editor?.zoom.zoomToRatio(zoomMin / 100);
							} else if (zoomValue > zoomMax) {
								editor?.zoom.zoomToRatio(zoomMax / 100);
							} else {
								editor?.zoom.zoomToRatio(zoomValue / 100);
							}
						}}
						className={styles.slider}
					/>
					<Typography className={styles.zoomValueLabel}> {[options.zoomRatio]}%</Typography>
				</Box>
			</Box>
			<Container>
				<Scenes />
				{/* <Common /> */}
			</Container>
			{contextMenuTimelineRequest.visible && <SceneContextMenu />}
		</>
	);
}
