import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import CreateOrganizationPopup from "../../components/common/CreateOrganizationPopup/CreateOrganizationPopup.tsx";
import WelcomePopup from "../../components/common/WelcomePopup/WelcomePopup.tsx";
import { USER_SETTINGS } from "../../constants/UserConstants.ts";
import { useAppStore } from "../../hooks/useStores.tsx";
import AppHeader from "../HeaderLayout/AppHeader/AppHeader.tsx";

import PricingFlow from "../../components/Popups/Pricing/PricingFlow.tsx";
import SubscriptionLinkGeneratorPopup from "../../components/Popups/SubscriptionLinkGeneratorPopup/SubscriptionLinkGeneratorPopup.tsx";
import ApiLimitReachedBanner from "../../components/common/ApiLimitReachedBanner/ApiLimitReachedBanner.tsx";
import ExportImagePopup from "../../components/common/ExportImagePopup/ExportImagePopup.tsx";
import Sidebar from "../Sidebar/Sidebar.tsx";
import styles from "./AppLayout.module.scss";

type LayoutProps = {
	hideSidebar?: boolean;
};

const Layout = ({ hideSidebar = false }: LayoutProps) => {
	const { authStore, uiStore, pricingStore } = useAppStore();
	const [showWelcomePopup, setShowWelcomePopup] = useState(false);

	useEffect(() => {
		if (!pricingStore.isPricingFlowOpen) {
			setShowWelcomePopup(!authStore.user?.getSettings(USER_SETTINGS.hidePlatformWelcomePopup));
		} else {
			authStore.user?.updateSettings(USER_SETTINGS.hidePlatformWelcomePopup, true);
		}
	}, []);

	return (
		<Box className={styles.appLayout}>
			<ApiLimitReachedBanner />
			<AppHeader />
			<Box className={styles.sidePagesContainer}>
				{!hideSidebar && <Sidebar />}
				<Outlet />
			</Box>
			<WelcomePopup
				onClose={() => {
					setShowWelcomePopup(false);
					authStore.user?.updateSettings(USER_SETTINGS.hidePlatformWelcomePopup, true);
				}}
				open={showWelcomePopup}
			/>
			<CreateOrganizationPopup
				open={uiStore.CreateOrganizationDialog}
				onClose={() => uiStore.hideDialog("CreateOrganizationDialog")}
			/>
			{uiStore.SubscriptionLinkGeneratorPopup && authStore.isLoggedIn && authStore.user?.isSuperAdmin() && (
				<SubscriptionLinkGeneratorPopup />
			)}
			<PricingFlow />
			{uiStore.ExportImagePopup && <ExportImagePopup open={uiStore.ExportImagePopup} />}
		</Box>
	);
};

export default observer(Layout);
