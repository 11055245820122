import { IframePages } from "./new-iframe";

export interface ApiKey {
	api_token: string;
	created: string;
	status: string;
	key_type: string;
}

export enum SsoConfigTypes {
	Iframe = "iframe",
	Platform = "platform",
}

export type SsoConfig = {
	type: SsoConfigTypes;
	providerId: string;
};

export type Organization = {
	id: string;
	allowed_pages: Partial<{ [page in IframePages]: boolean }>;
	is_blocked: boolean;
	sso_config?: SsoConfig[];
	block_reason?: string;
	block_notes?: string;
	blocked_at?: Date;
};

export interface FBOrganization {
	name?: string;
	uid: string;
	owner_uid?: string;
}

export interface UserOrganization {
	organization: FBOrganization;
	role: string;
}

export interface MyAccountUser {
	uid: string;
	user_name?: string;
	email?: string;
	profile_picture?: string;
	role?: string;
}

export interface UserOrganizationFlat {
	org_uid: string;
	role: string;
	status: string;
}
