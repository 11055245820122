import { Box, Divider, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "baseui";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../../../../hooks/useStores";
import Icons from "../../../../components/Icons";
import Scrollable from "../../../../components/Scrollable";
import { BASE_ITEMS, PanelType } from "../../../../constants/app-options";
import useAppContext from "../../../../hooks/useAppContext";
import useSetIsSidebarOpen from "../../../../hooks/useSetIsSidebarOpen";

interface StyleProps {
	name?: string;
	activePanel?: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	editorPanelList: (props: StyleProps) => ({
		height: "58px",
		marginRight: "5px",
		marginLeft: "5px",
		marginTop: "8px",
		backgroundColor:
			props.name === props.activePanel ? `${theme?.palette?.primary.main}0D` : theme?.palette?.common?.white,
		borderRadius: props.name === props.activePanel ? "8px" : "",
		border: props.name === props.activePanel ? `2px solid ${theme?.palette?.primary.main}0D` : "",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		justifyContent: "center",
		color: "#5b5b5b",
		gap: "3px",
		"&:hover, &.active": {
			cursor: "pointer",
			borderRadius: "8px",
			color: `${theme?.palette?.primary.main} !important`,
			background: `${theme?.palette?.primary.main}0D`,

			"& svg path": {
				stroke: theme?.palette?.primary.main,
			},
		},
	}),
	tapsTitle: {
		fontFamily: "Montserrat",
		fontSize: "8px",
		fontWeight: 600,
		lineHeight: "normal",
	},
	devider: {
		padding: "5px",
	},
}));

const Container = styled("div", (_props) => ({
	backgroundColor: "white",
	display: "flex",
	borderRadius: "8px",
	border: "1px solid #E7E7E7",
	background: "#FFF",
	boxShadow: "0px 2px 8px 0px rgba(36, 0, 88, 0.04)",
	width: "80px",
	marginBottom: "8px",
}));

function PanelsList() {
	const { activePanel, setActivePanel } = useAppContext();
	const { t } = useTranslation("translation");
	const { campaignStore, playgroundStore } = useAppStore();
	const classes = useStyles({});
	useEffect(() => {
		if (!campaignStore.isEditorPageLoading && !playgroundStore.isText2ImagePopupOpened) {
			if (!campaignStore.isAdminMode) {
				if (activePanel == PanelType.TEMPLATES) setActivePanel(PanelType.CAMPAIGNS);
			} else {
				if (activePanel == PanelType.CAMPAIGNS) setActivePanel(PanelType.TEMPLATES);
			}
		}
		campaignStore.setProperty("isEditorPageLoading", false);
	}, [campaignStore.isAdminMode]);
	return (
		<Container>
			<Scrollable autoHide={true}>
				{BASE_ITEMS.map((panelListItem, index) => (
					<Box key={panelListItem.name}>
						<PanelListItem
							label={t(`editor.panels.panelsList.${panelListItem.id}`)}
							name={panelListItem.name}
							key={panelListItem.name}
							icon={panelListItem.name}
							activePanel={activePanel}
						/>
						{index === BASE_ITEMS.length - 2 && (
							<Box className={classes.devider}>
								<Divider />
							</Box>
						)}
					</Box>
				))}
			</Scrollable>
		</Container>
	);
}

function PanelListItem({ label, icon, activePanel, name }: any) {
	const { setActivePanel } = useAppContext();
	const setIsSidebarOpen = useSetIsSidebarOpen();
	const { campaignStore } = useAppStore();
	const classes = useStyles({ name: name, activePanel: activePanel });
	// @ts-ignore
	const Icon = Icons[icon];

	return (
		<Box
			id={"EditorPanelList"}
			onClick={() => {
				setIsSidebarOpen(true);
				setActivePanel(name);
				campaignStore.setProperty("isManualTemplateTabSelection", true);
			}}
			className={`${classes.editorPanelList} ${name === activePanel ? "active" : ""}`}
		>
			<Icon size={24} />
			<Typography className={classes.tapsTitle}>{label}</Typography>
		</Box>
	);
}

const ObservedComponent = observer(PanelsList);
export default ObservedComponent;
