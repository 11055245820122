import { Box, FormControlLabel, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { IconButton } from "rsuite";
import { DownArrow } from "../../../../../assets/images/icons/DownArrow";
import InfoIcon from "../../../../../assets/images/icons/InfoIcon";
import BriaCheckbox from "../../../../../components/common/BriaCheckbox/BriaCheckbox";
import BriaTooltip from "../../../../../components/common/BriaTooltip/BriaTooltip";
import { ConfigProps } from "../../../../../models/image-to-image";
import styles from "./Config.module.scss";

const Config = observer(
	({ title, checked, onClick, isActive, setIsActive, titleOnly, className, tooltip, children }: ConfigProps) => {
		return (
			<Box className={clsx(styles.configWrapper, className)}>
				<Box className={styles.configTitleContainer}>
					<Box className={styles.configTitle}>
						{titleOnly ? (
							<Typography className={styles.title}>{title}</Typography>
						) : (
							<FormControlLabel
								className={styles.formControlContainer}
								control={
									<BriaCheckbox
										title={title}
										onChange={(e) => onClick?.(e)}
										checked={checked}
										className={styles.checkbox}
									/>
								}
								label={title}
							/>
						)}
						{tooltip && (
							<BriaTooltip
								title={tooltip}
								className={styles.info}
								componentsProps={{ tooltip: { className: styles.tooltip } }}
							>
								<InfoIcon className={styles.infoIcon} />
							</BriaTooltip>
						)}
					</Box>

					{children && setIsActive && (
						<IconButton
							className={clsx(styles.accordionIcon, { [styles.open]: isActive })}
							onClick={() => setIsActive(isActive ? "" : title)}
						>
							<DownArrow />
						</IconButton>
					)}
				</Box>
				{children && (
					<Box className={clsx(styles.accordionContainer, { [styles.open]: isActive })}>{children}</Box>
				)}
			</Box>
		);
	},
);

export default Config;
