import { useTranslation } from "react-i18next";
import { useAppStore } from "../../../hooks/useStores.tsx";
import iframeStore from "../../../pages/IframeNew/iframe-store.tsx";
import BriaButton, { ButtonTypes } from "../BriaButton/BriaButton";
import BriaDropdown from "../BriaDropDown/BriaDropDown.tsx";
import styles from "./ExportButton.module.scss";

interface IProps {
	onDownload?: () => Promise<void>;
	onIframeSave?: () => Promise<void>;
	disabled?: boolean;
	loading?: boolean;
	disableExportPopup?: boolean;
	buttonType?: ButtonTypes;
	className?: string;
}

const ExportButton = ({
	onDownload,
	onIframeSave,
	disabled,
	loading,
	disableExportPopup,
	buttonType = "textSmall",
	className,
}: IProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "exportButton" });
	const { uiStore } = useAppStore();

	const onExportWithoutPopup = async (action: string) => {
		if (disabled) {
			return;
		}
		if (action === "save") {
			onIframeSave?.();
		} else if (action === "download") {
			onDownload?.();
		}
	};

	const ExportButtonWithPopup = () => {
		return (
			<BriaButton
				className={className}
				buttonType={buttonType}
				onClick={() => uiStore.showExportImagePopup()}
				size="small"
				disabled={disabled}
				loading={loading}
			>
				{t("export")}
			</BriaButton>
		);
	};

	const ExportButtonWithoutPopup = ({ label, value }: { label: string; value: string }) => {
		return (
			<BriaButton
				buttonType="textSmall"
				onClick={() => onExportWithoutPopup(value)}
				size="small"
				disabled={disabled}
				loading={loading}
			>
				{label}
			</BriaButton>
		);
	};

	const IframeExport = () => {
		const saveLabel = iframeStore.iframe.config.general_config?.save_label
			? iframeStore.iframe.config.general_config?.save_label
			: t("save");
		if (
			iframeStore.iframe.config.general_config?.enable_download ||
			iframeStore.iframe.config.general_config?.enable_save
		) {
			if (!disableExportPopup) {
				return <ExportButtonWithPopup />;
			} else {
				if (
					iframeStore.iframe.config.general_config?.enable_download &&
					iframeStore.iframe.config.general_config?.enable_save
				) {
					return (
						<BriaDropdown
							loading={loading}
							height={"32px"}
							onChange={(e) => onExportWithoutPopup(e.target.value)}
							disabled={disabled}
							items={[
								{
									key: t("download"),
									value: "download",
								},
								{
									key: saveLabel,
									value: "save",
								},
							]}
							className={styles.exportDropdown}
							placeholder={t("export")}
						/>
					);
				} else {
					return (
						<ExportButtonWithoutPopup
							label={iframeStore.iframe.config.general_config?.enable_download ? t("export") : saveLabel}
							value={iframeStore.iframe.config.general_config?.enable_download ? "download" : "save"}
						/>
					);
				}
			}
		} else {
			return <></>;
		}
	};

	return (
		<>
			{iframeStore.isIframe() ? (
				<IframeExport />
			) : disableExportPopup ? (
				<ExportButtonWithoutPopup label={t("export")} value={"download"} />
			) : (
				<ExportButtonWithPopup />
			)}
		</>
	);
};

export default ExportButton;
