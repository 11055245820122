import { ChevronRight } from "@mui/icons-material";
import { Box, Divider, Link, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";
import StopIcon from "../../../../../assets/images/icons/StopIcon.tsx";
import BriaButton from "../../../../../components/common/BriaButton/BriaButton.tsx";
import { APPS } from "../../../../../constants/AppsConstants.ts";
import { MAX_ALLOWED_SELECTED_IMAGES } from "../../../../../constants/ImageToImageConstants.ts";
import { useImageToImageConfig } from "../../../../../hooks/useImageToImageConfig.tsx";
import { useAppStore } from "../../../../../hooks/useStores.tsx";
import ExpandableMenuButton from "../../../../../layout/ExpandableMenu/Button/ExpandableMenuButton.tsx";
import ExpandableMenuLayout from "../../../../../layout/ExpandableMenu/Menu/ExpandableMenuLayout.tsx";
import {
	ImageEditingConfigsProps,
	ImageEditingViewMode,
	ImageToImageConfigType,
} from "../../../../../models/image-to-image.ts";
import { isPlaygroundLoadingImages } from "../../../../../utils/index.ts";
import iframeStore from "../../../../IframeNew/iframe-store.tsx";
import ConfigButtonLabel from "../ConfigButtonLabel/ConfigButtonLabel.tsx";
import ConfigDisplay from "../ConfigDisplay/ConfigDisplay";
import BackgroundConfig from "../Features/Background/BackgroundConfig";
import EraseObject from "../Features/EraseObject/EraseObject.tsx";
import ForegroundPlacement from "../Features/ForegroundPlacement/ForegroundPlacement";
import SizeConfig from "../Features/Size/SizeConfig";
import styles from "../ImageToImageConfig.module.scss";

const ImageEditingConfigs = observer(({ handleGenerateImageToImage }: ImageEditingConfigsProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.imageToImage.config" });
	const { imageToImageStore, playgroundStore } = useAppStore();
	const {
		isGenerateEnabled,
		updateSelectedBackgroundConfigs,
		updateSelectedSizeConfigs,
		updateSelectedForegroundPlacementConfigs,
	} = useImageToImageConfig();

	const [activeConfig, setActiveConfig] = useState<string>("");
	const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(false);
	const [isSingleMode, setIsSingleMode] = useState<boolean>(false);

	const [openTooltipIndex, setOpenTooltipIndex] = useState<number | null>(null);

	const hasLoadingImages = isPlaygroundLoadingImages(playgroundStore.playgroundResults, APPS.IMAGE_TO_IMAGE);

	useEffect(() => {
		setIsSingleMode(playgroundStore.imageEditingViewMode === ImageEditingViewMode.SINGLE);
	}, [playgroundStore.imageEditingViewMode]);

	const handleDeleteConfig = (configKey: keyof ImageToImageConfigType, field: string, value: any) => {
		const currentConfig = { ...(imageToImageStore.config[configKey] as Record<string, any> | undefined) };

		imageToImageStore.handleConfigChange(configKey, {
			...currentConfig,
			[field]: value,
		});
	};

	const onSubMenuOpen = (open: boolean) => {
		setIsSubMenuOpen(open);
	};

	const toggleTooltip = (index: number) => {
		setOpenTooltipIndex((prevIndex) => (prevIndex === index ? null : index)); // Close if already open
	};

	const ToolTipInfo = ({ tooltip, href }: { tooltip: string; href: string }) => (
		<>
			{tooltip}
			{!iframeStore.isIframe() && (
				<Box className={styles.apiDocContainer}>
					<Link href={href} target={"_blank"} className={styles.link}>
						{t("apiDocumentation")} <ChevronRight />
					</Link>
				</Box>
			)}
		</>
	);
	return (
		<Box className={styles.imageToImageConfigsWrapper}>
			<ExpandableMenuLayout
				className={clsx(styles.config, styles.imageToImageConfigs)}
				setActiveConfig={setActiveConfig}
				isSubMenuOpen={isSubMenuOpen}
			>
				<Box className={styles.configHeader}>
					<Typography variant="h6" className={styles.title}>
						{t("title")}
					</Typography>
					<Typography className={styles.subTitle}>{t("subTitle")}</Typography>
				</Box>
				<ExpandableMenuButton
					expandTo={<BackgroundConfig onSubMenuOpen={onSubMenuOpen} />}
					subMenuPanelProps={{ title: t("features.background.title") }}
					isActive={activeConfig === t("features.background.title")}
				>
					<ConfigButtonLabel
						className={clsx(styles.buttonLabel)}
						label={t("features.background.button")}
						info={renderToString(
							<ToolTipInfo
								tooltip={t("features.background.tooltip")}
								href={
									"https://bria-ai-api-docs.redoc.ly/tag/Image-Modifications#operation/background/remove"
								}
							/>,
						)}
						isOpen={openTooltipIndex === 0}
						onToggle={() => toggleTooltip(0)}
					/>
				</ExpandableMenuButton>
				<ConfigDisplay
					config={imageToImageStore.config.background}
					onDelete={(configKey: string, value: string) => {
						handleDeleteConfig("background", configKey, value);
						updateSelectedBackgroundConfigs();
					}}
					expandTo={<BackgroundConfig onSubMenuOpen={onSubMenuOpen} />}
					subMenuPanelProps={{ title: t("features.background.title") }}
				/>
				<Divider />
				<ExpandableMenuButton
					expandTo={<SizeConfig />}
					subMenuPanelProps={{ title: t("features.size.title") }}
					isActive={activeConfig === t("features.size.title")}
				>
					<ConfigButtonLabel
						className={clsx(styles.buttonLabel)}
						label={t("features.size.button")}
						info={t("features.size.tooltip")}
						isOpen={openTooltipIndex === 1}
						onToggle={() => toggleTooltip(1)}
					/>
				</ExpandableMenuButton>
				<ConfigDisplay
					config={imageToImageStore.config.size}
					onDelete={(configKey: string, value: string) => {
						handleDeleteConfig("size", configKey, value);
						updateSelectedSizeConfigs();
					}}
					expandTo={<SizeConfig />}
					subMenuPanelProps={{ title: t("features.size.title") }}
				/>
				<Divider />
				<ExpandableMenuButton
					expandTo={<ForegroundPlacement />}
					subMenuPanelProps={{ title: t("features.foregroundPlacement.title") }}
					isActive={activeConfig === t("features.foregroundPlacement.title")}
				>
					<ConfigButtonLabel
						className={clsx(styles.buttonLabel)}
						label={t("features.foregroundPlacement.button")}
						info={renderToString(
							<ToolTipInfo
								tooltip={t("features.foregroundPlacement.tooltip")}
								href={
									"https://bria-ai-api-docs.redoc.ly/tag/Product-Shots-Generation#operation/productLifestyleShotByText"
								}
							/>,
						)}
						isOpen={openTooltipIndex === 2}
						onToggle={() => toggleTooltip(2)}
					/>
				</ExpandableMenuButton>
				<ConfigDisplay
					config={imageToImageStore.config.foreground_placement}
					onDelete={(configKey: string, value: string) => {
						handleDeleteConfig("foreground_placement", configKey, value);
						updateSelectedForegroundPlacementConfigs();
					}}
					expandTo={<ForegroundPlacement />}
					subMenuPanelProps={{ title: t("features.foregroundPlacement.title") }}
				/>
				{isSingleMode && <Divider />}
				<ExpandableMenuButton
					expandTo={<EraseObject />}
					subMenuPanelProps={{
						title: t("features.eraseObject.title"),
						subTitle: t("features.eraseObject.subTitle"),
					}}
					isActive={activeConfig === t("features.eraseObject.title")}
					disabled={!isSingleMode}
				>
					<ConfigButtonLabel
						className={clsx(styles.buttonLabel)}
						label={t("features.eraseObject.button")}
						info={renderToString(
							<ToolTipInfo
								tooltip={t("features.eraseObject.tooltip")}
								href={"https://bria-ai-api-docs.redoc.ly/tag/Image-Modifications#operation/eraser"}
							/>,
						)}
						isOpen={openTooltipIndex === 3}
						onToggle={() => toggleTooltip(3)}
					/>
				</ExpandableMenuButton>
			</ExpandableMenuLayout>
			{hasLoadingImages && !imageToImageStore.isEraseConfigsEnabled && !imageToImageStore.isErasingImages ? (
				<BriaButton
					buttonType="primaryMedium"
					className={styles.stopGeneratingBtn}
					disabled={isGenerateEnabled() as boolean}
					fullWidth
					onClick={() => imageToImageStore.abortImageGeneration()}
				>
					<StopIcon /> {t("stopGenerating")}
				</BriaButton>
			) : (
				<BriaButton
					buttonType="primaryMedium"
					disabled={!isGenerateEnabled()}
					fullWidth
					onClick={handleGenerateImageToImage}
					className="generate-image-to-image"
				>
					{imageToImageStore.getGeneratedImagesCount() > MAX_ALLOWED_SELECTED_IMAGES
						? t("tooManySelected")
						: t("button")}
					{imageToImageStore.getGeneratedImagesCount() > 0 &&
						isGenerateEnabled() &&
						` ${imageToImageStore.getGeneratedImagesCount()} ${t("results")}`}
				</BriaButton>
			)}
		</Box>
	);
});

export default ImageEditingConfigs;
