import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { useBrushOnCanvas } from "../../../hooks/brush/useBrushOnCanvas.tsx";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation.tsx";
import styles from "./BriaCanvas.module.scss";

export type BriaImageProps = {
	url: string;
	enableBrush?: boolean;
	canvasRef: (ref: HTMLCanvasElement | null) => void;
	canvasOverlayRef: (ref: HTMLCanvasElement | null) => void;
	isLoading?: boolean;
	brushConfigs: any;
	onCanvasSizeUpdate: () => void;
};

const BriaCanvas = ({
	url,
	enableBrush = true,
	canvasRef,
	canvasOverlayRef,
	isLoading = false,
	brushConfigs,
	onCanvasSizeUpdate,
}: BriaImageProps) => {
	const containerRef = useRef<null | HTMLDivElement>(null);
	const loadingContainerRef = useRef<HTMLDivElement | null>(null);
	const localCanvasRef = useRef<HTMLCanvasElement | null>(null);
	const localCanvasOverlayRef = useRef<null | HTMLCanvasElement>(null);

	const { clearBrushOverlayCanvasRef } = useBrushOnCanvas(brushConfigs, localCanvasRef, localCanvasOverlayRef);

	useEffect(() => {
		if (brushConfigs.reset) {
			clearBrushOverlayCanvasRef();
			brushConfigs.reset = false;
		}
	}, [brushConfigs.reset]);

	useEffect(() => {
		if (canvasRef && canvasOverlayRef && localCanvasRef.current && localCanvasOverlayRef.current) {
			canvasRef(localCanvasRef.current);
			canvasOverlayRef(localCanvasOverlayRef.current);
		}
		const canvas = localCanvasRef.current;
		const context = canvas?.getContext("2d");
		const container = containerRef.current;

		if (!canvas || !context || !container) {
			return;
		}

		const updateCanvasSize = () => {
			const img = new Image();
			img.crossOrigin = "anonymous";
			img.src = url;

			img.onload = () => {
				const { width: containerWidth, height: containerHeight } = container.getBoundingClientRect();

				const imgAspectRatio = img.width / img.height;
				let drawWidth = img.width;
				let drawHeight = img.height;

				if (drawWidth > containerWidth) {
					drawWidth = containerWidth;
					drawHeight = drawWidth / imgAspectRatio;
				}

				if (drawHeight > containerHeight) {
					drawHeight = containerHeight;
					drawWidth = drawHeight * imgAspectRatio;
				}

				canvas.width = drawWidth;
				canvas.height = drawHeight;
				if (localCanvasOverlayRef && localCanvasOverlayRef.current) {
					localCanvasOverlayRef.current.width = drawWidth;
					localCanvasOverlayRef.current.height = drawHeight;
				}

				if (loadingContainerRef && loadingContainerRef.current) {
					loadingContainerRef.current.style.width = `${drawWidth}px`;
					loadingContainerRef.current.style.height = `${drawHeight}px`;
				}
				context.clearRect(0, 0, canvas.width, canvas.height);
				context.drawImage(img, 0, 0, drawWidth, drawHeight);
				onCanvasSizeUpdate();
			};
		};

		updateCanvasSize();
		window.addEventListener("resize", updateCanvasSize);

		return () => {
			window.removeEventListener("resize", updateCanvasSize);
			if (canvasRef) {
				canvasRef(null);
			}
		};
	}, [url]);

	return (
		<Box className={clsx(styles.container)} ref={containerRef}>
			{enableBrush && (
				<Box
					className={styles.customCursor}
					id="customBrushCursor"
					style={{
						width: brushConfigs.lineWidth,
						height: brushConfigs.lineWidth,
						backgroundColor: brushConfigs.strokeStyle,
						opacity: brushConfigs.brushColorOpacity,
					}}
				></Box>
			)}
			<canvas
				onContextMenu={(e) => e.preventDefault()}
				className={clsx(styles.briaCanvas, {
					[styles.isLoading]: isLoading,
				})}
				ref={localCanvasRef}
			/>
			<canvas
				className={clsx({
					[styles.isLoading]: isLoading,
				})}
				onContextMenu={(e) => e.preventDefault()}
				ref={localCanvasOverlayRef}
			/>
			<Box className={styles.loadingContainer} ref={loadingContainerRef}>
				<Box>
					<LoadingAnimation loading={isLoading} progressBarTime={10} />
				</Box>
			</Box>
		</Box>
	);
};

const ObservedComponent = observer(BriaCanvas);
export default ObservedComponent;
