import { ChangeEvent, useContext } from "react";
import { useAppStore } from "../../hooks/useStores.tsx";
import useScenesUtils from "../CustomUtils/ScenesUtils.tsx";
import useDesignEditorUtils from "../CustomUtils/UseDesignEditor.tsx";
import { PanelType } from "../constants/app-options.ts";
import { DesignEditorContext } from "../contexts/DesignEditor.tsx";
import { ILayer, IScene } from "../types";
import { useSmartImageUtils } from "../views/DesignEditor/utils/smartImageUtils.ts";
import useAppContext from "./useAppContext.tsx";
import { useEditor } from "./useEditor.tsx";

const useWizardImagesHeader = () => {
	const editor = useEditor();
	const { applyWizardImagesChangesOnScene } = useDesignEditorUtils();
	const { findObjectFromScene, getChangeEventFromImageUrl } = useSmartImageUtils();
	const { designEditorStore, imagesStore } = useAppStore();
	const { setCurrentScene, currentScene } = useContext(DesignEditorContext);
	const { updateScenes } = useScenesUtils();
	const { isPopupView } = useContext(DesignEditorContext);
	const { setActivePanel } = useAppContext();

	const onUploadImage = async (e: ChangeEvent<HTMLInputElement>) => {
		if (!editor || !currentScene) return;
		const activeObject = editor?.canvas.canvas.getActiveObject();
		const _currentScene = editor?.scene.exportToJSON();
		const currentActiveImage = activeObject && (await findObjectFromScene(_currentScene, activeObject.id));
		currentActiveImage && (await uploadAndRegisterNewWizardImages(e, undefined, _currentScene, currentActiveImage));
	};

	/**
	 * Uploads and registers new Wizard  images,
	 * and show the new image on the editor canvas.
	 *
	 * @param event - The change event from an input element used to upload images.
	 * @param imageUrl - Optional URL of an image to be used as a background.
	 * @param externalScene
	 * @returns A promise that resolves when the images are uploaded, registered, and drawn.
	 */
	const uploadAndRegisterNewWizardImages = async (
		event: ChangeEvent<HTMLInputElement>,
		imageUrl?: string,
		externalScene?: IScene,
		currentActiveImage?: Partial<ILayer>,
	) => {
		if (!event?.target?.files && !imageUrl) {
			return;
		}
		imagesStore.setProperty("isLoadingWizardImages", true);
		await updateScenes();
		const tempEvent: ChangeEvent<HTMLInputElement> = await getChangeEventFromImageUrl(event, imageUrl);
		const newUploadedImage = await imagesStore.registerImageAndGetUrl(!imageUrl ? event : tempEvent);
		if (currentActiveImage?.id) {
			const existingEntry = designEditorStore.backupOfReplacedStaticImagesLayers.find(
				(layer: Partial<ILayer>) => layer.id === currentActiveImage?.id,
			);
			if (!existingEntry) {
				designEditorStore.backupOfReplacedStaticImagesLayers.push(currentActiveImage);
			}
		}
		if (currentActiveImage?.imageType)
			designEditorStore.imageTypeToNewUrlMap[currentActiveImage.imageType] = newUploadedImage.imageUrl;
		if (!designEditorStore.originalSceneInWizard)
			designEditorStore.setProperty("originalSceneInWizard", externalScene);
		if (externalScene) {
			await applyWizardImagesChangesOnScene(externalScene, newUploadedImage.imageUrl, currentActiveImage);
			setCurrentScene(externalScene);
			imagesStore.setProperty("isLoadingWizardImages", false);
		}
	};

	const onGenerateWizardImageClick = async () => {
		const activeObject = editor?.canvas.canvas.getActiveObject();
		const _currentScene = editor?.scene.exportToJSON();
		const currentActiveImage =
			_currentScene && activeObject && (await findObjectFromScene(_currentScene, activeObject.id));
		imagesStore.setProperty("isLoadingWizardImages", true);
		designEditorStore.originalActiveObject = currentActiveImage;
		designEditorStore.setProperty("currentSceneInWizard", _currentScene);
		setActivePanel(PanelType.WIZARDIMAGES);
		designEditorStore.openText2ImagePopup(isPopupView ?? false);
	};

	return {
		onUploadImage,
		uploadAndRegisterNewWizardImages,
		onGenerateWizardImageClick,
	};
};

export default useWizardImagesHeader;
