import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import FileUploader from "../../../../components/common/FileUploader/FileUploader.tsx";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import aiAction from "../../../assets/svgs/Ai Actions.svg";
import useWizardImagesHeader from "../../../hooks/useWizardImagesHeader.tsx";
import Icons from "../../Icons";
import styles from "./WizardImageHeader.module.scss";

interface IProps {
	contextMenuPosition?: { left: number; top: number; right?: number };
}

const WizardImageHeader = ({ contextMenuPosition }: IProps) => {
	const { imagesStore } = useAppStore();
	const { t: wizardImageButtonsHeader } = useTranslation("translation", {
		keyPrefix: "campaignTab.wizardImageButtonsHeader",
	});
	const { onUploadImage, onGenerateWizardImageClick } = useWizardImagesHeader();

	return (
		<Box
			onContextMenu={(e) => e.preventDefault()}
			className={styles.wiardImageHeader}
			style={{
				top: `calc(${contextMenuPosition?.top}px - 45px)`,
				left: `calc(${contextMenuPosition?.left}px - 200px)`,
			}}
		>
			<Box
				className={clsx(styles.buttonContainer, {
					[styles.buttonContainerLoading]: imagesStore.isLoadingWizardImages,
					[styles.disabled]: imagesStore.isLoadingWizardImages,
				})}
				onClick={onGenerateWizardImageClick}
			>
				<img src={aiAction} />
				<Typography className={styles.buttonLabel}>{wizardImageButtonsHeader("generate")}</Typography>
			</Box>
			<Box
				className={clsx(styles.uploadButtonContainer, {
					[styles.buttonContainerLoading]: imagesStore.isLoadingWizardImages,
					[styles.disabled]: imagesStore.isLoadingWizardImages,
				})}
			>
				<FileUploader
					buttonText={wizardImageButtonsHeader("upload")}
					buttonType="textSmall"
					inputProps={{ accept: "image/png, image/jpeg, image/jpg, image/webp" }}
					buttonProps={{
						startIcon: <Icons.Uploads1 size={16} />,
						onClick: () => {},
					}}
					onUpload={onUploadImage}
					buttonClassName={styles.uploadButtonLabel}
					loading={imagesStore.isLoadingWizardImages}
					disabled={imagesStore.isLoadingWizardImages}
				/>
			</Box>
			{/* TODO: add Edit button on phase3 */}
			{/* <Box className={styles.editButtonContainer}>
        <EditIcon2 className={styles.actionBtn} onClick={() => {}} sx={{ width: "16px !important" }} />
        <Typography className={styles.buttonLabel}>{wizardImageButtonsHeader("edit")}</Typography>
    </Box> */}
		</Box>
	);
};

const ObservedComponent = observer(WizardImageHeader);
export default ObservedComponent;
