import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import styles from "../../../DesignEditor/styles/GraphicEditor.module.scss";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { PopUpSteps } from "../../constants/mock-data.ts";
import useSetIsSidebarOpen from "../../hooks/useSetIsSidebarOpen.tsx";
import CampaignWorkspace from "./components/CampaignWizard/CampaignWorkspace/CampaignWorkspace.tsx";
import Canvas from "./components/Canvas";
import EditorContainer from "./components/EditorContainer";
import Footer from "./components/Footer";
import CampaignHeader from "./components/Header/CampaignHeader.tsx";
import Panels from "./components/Panels";
import Toolbox from "./components/Toolbox";

function GraphicEditor(props: { isPopup?: boolean }) {
	const setIsSidebarOpen = useSetIsSidebarOpen();
	const { designEditorStore } = useAppStore();
	const SMART_IMAGE_INDEX = PopUpSteps.indexOf("images");
	const WIZARD_IMAGE_INDEX = PopUpSteps.indexOf("WizardImages");
	useEffect(() => {
		return () => {
			setIsSidebarOpen(true);
			if (
				designEditorStore.activeStep !== SMART_IMAGE_INDEX &&
				designEditorStore.activeStep !== WIZARD_IMAGE_INDEX
			)
				designEditorStore.resetCampaignPopup();
		};
	}, []);

	return (
		<>
			<EditorContainer>
				{props.isPopup ? (
					<CampaignWorkspace />
				) : (
					<>
						{/*<Navbar />*/}
						<CampaignHeader />
						<Box component="div" className={styles.graphicContainer}>
							<Panels />
							<Box component="div" className={styles.graphicSubContainer}>
								<Toolbox />
								<Canvas />
								<Footer />
							</Box>
						</Box>
					</>
				)}
			</EditorContainer>
		</>
	);
}

const ObservedComponent = observer(GraphicEditor);
export default ObservedComponent;
