import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { ScrollPosition } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import FullScreenViewer, {
	FullScreenViewerProps,
} from "../../../../../../components/common/FullScreenViewer/FullScreenViewer.tsx";
import BriaImage, { BriaImageProps } from "../../../../../../components/common/Galleries/BriaImage.tsx";
import { useAppStore } from "../../../../../../hooks/useStores.tsx";
import { PlaygroundImage } from "../../../../../../models/image-to-image.ts";
import styles from "./CampaignWizardGallery.module.scss";

type CampaignWizardGalleryProps = {
	images: (BriaImageProps & PlaygroundImage)[];
	maxInLine?: number;
	className?: string;
	fullScreenProps?: Partial<FullScreenViewerProps>;
	scrollPosition?: ScrollPosition;
};

const CampaignWizardGallery = ({
	images,
	maxInLine = 4,
	className,
	fullScreenProps,
	scrollPosition,
}: CampaignWizardGalleryProps) => {
	const { playgroundStore } = useAppStore();
	const [imageToView, setImageToView] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState<boolean | undefined>(fullScreenProps?.open);
	const [displayMode, setDisplayMode] = useState(fullScreenProps?.displayMode);

	useEffect(() => {
		setIsViewerOpen(fullScreenProps?.open);
	}, [fullScreenProps?.open]);

	const handleOpenFullScreen = (index: number) => {
		setDisplayMode("scroll");
		setImageToView(index);
		setIsViewerOpen(true);
	};

	const handleCloseFullScreen = () => {
		setIsViewerOpen(false);
		fullScreenProps?.onClose?.();
		setDisplayMode(fullScreenProps?.displayMode);
	};

	const handleSelectImage = (image: any) => {
		playgroundStore.handleSelectImage(image);
	};

	return (
		<>
			<Box
				className={`${className} ${styles.ttiGallery}`}
				sx={{
					"&  span > span": { width: "100%" },
					"&  > span": { width: `calc( ${100 / maxInLine}% - 2vw)` },
				}}
			>
				{images.map((image, index) => (
					<span className={styles.imageWrapper}>
						<BriaImage
							key={index}
							{...image}
							handleFullScreen={() => handleOpenFullScreen(index)}
							lazyImageProps={{ ...image.lazyImageProps, scrollPosition: scrollPosition }}
							fullScreenProps={image.fullScreenProps}
							hideDownloadIcon={true}
							handleClick={() => handleSelectImage(image)}
						/>
					</span>
				))}
			</Box>
			{isViewerOpen && (
				<FullScreenViewer
					{...fullScreenProps}
					open={isViewerOpen}
					images={images
						.filter((image) => !image.hideFullScreenButton && !image.errorType)
						.map((image) => ({ src: image.url, ...image.fullScreenProps }))}
					currentIndex={imageToView}
					onClose={handleCloseFullScreen}
					displayMode={displayMode}
					hideDownloadIcon={true}
				/>
			)}
		</>
	);
};

const ObservedComponent = observer(CampaignWizardGallery);
export default ObservedComponent;
