import backgroundGenerationPng from "../assets/images/pngs/APICards/API-Background-Generation.png";
import BaseModel from "../assets/images/pngs/APICards/API-Base-Model.png";
import backgroundBlur from "../assets/images/pngs/APICards/API-Blur-Background.png";
import consistentStudioShotsPng from "../assets/images/pngs/APICards/API-Consistent-Studio-Shots.png";
import contextualKeywordExtractionPng from "../assets/images/pngs/APICards/API-Contextual-Keyword-Extraction.png";
import cropOutForgroundPng from "../assets/images/pngs/APICards/API-Crop-Out-Forground.png";
import GenFill from "../assets/images/pngs/APICards/API-Erase-And-Replace.png";
import EraseElements from "../assets/images/pngs/APICards/API-Erase-Elements.png";
import EraseForeground from "../assets/images/pngs/APICards/API-Erase-Foreground.png";
import FastModel from "../assets/images/pngs/APICards/API-Fast-Model.png";
import managedTailoredModelsPng from "../assets/images/pngs/APICards/API-Generate-Images.png";
import ImageReference from "../assets/images/pngs/APICards/API-Generate-image-ref.png";
import HDModel from "../assets/images/pngs/APICards/API-HD-Model.png";
import imageExpansion from "../assets/images/pngs/APICards/API-Image-Expansion.png";
import imageToPsd from "../assets/images/pngs/APICards/API-Image-to-PSD.png";
import increase_res from "../assets/images/pngs/APICards/API-Increase-Resolution.png";
import LabelessAIImageSearch from "../assets/images/pngs/APICards/API-Labeless-AI-Image-Search.png";
import lifestyleProductShotByImagePng from "../assets/images/pngs/APICards/API-Lifestyle-Product-Image.png";
import lifestyleProductTextPng from "../assets/images/pngs/APICards/API-Lifestyle-Product-Text.png";
import manageDatasetsPng from "../assets/images/pngs/APICards/API-Manage-Datasets.png";
import generateImagesPng from "../assets/images/pngs/APICards/API-Managed-Tailored-Models.png";
import masksGenerator from "../assets/images/pngs/APICards/API-Masks-Generator.png";
import modifyPresenter from "../assets/images/pngs/APICards/API-Modify-Presenter.png";
import productCutoutsPng from "../assets/images/pngs/APICards/API-Product-Cutouts.png";
import productPackShotsPng from "../assets/images/pngs/APICards/API-Product-Pack-Shots.png";
import productShadowPng from "../assets/images/pngs/APICards/API-Product-Shadow.png";
import PromptEnhancement from "../assets/images/pngs/APICards/API-Prompt-enhancement.png";
import backgroundRemoval from "../assets/images/pngs/APICards/API-Remove-Background.png";
import trainModelsPng from "../assets/images/pngs/APICards/API-Train-Models.png";
import VectorFast from "../assets/images/pngs/APICards/API-Vector-Fast.png";
import VectorBase from "../assets/images/pngs/APICards/API-Vector-base.png";
import VideoBackgroundRemove from "../assets/images/pngs/APICards/API-Video-Background-Remove.png";

import { IAPICardsProps } from "../components/API/APICardComponent";
import { ImageModificationsSubAPIType } from "../models/sandboxAPI.ts";
import LinkConstants from "./LinkConstants";
import RouterConstants from "./RouterConstants";

export enum APICardType {
	ImageGenerationAPICards = "image-generation",
	ImageEditingAPICards = "image-editing",
	BaseSuiteAPICards = "base-platform",
	ProductShot = "product-shot",
	BrandedGenAPICards = "branded-generation",
	AISearchAPICards = "ai-search",
	VideoEditingAPICards = "video-editing",
}

export const APICards: IAPICardsProps[] = [
	{
		type: APICardType.ImageEditingAPICards,
		title: "genFill",
		description: "genFillDescription",
		link: LinkConstants.BRIA_API_ERASE_AND_REPLACE,
		image: GenFill,
		demoLink: RouterConstants.ERASE_AND_REPLACE_APP.fullPath,
	},
	{
		type: APICardType.ImageEditingAPICards,
		title: "eraseElements",
		description: "eraseElementsDescription",
		link: LinkConstants.BRIA_API_REMOVE_OBJECT,
		image: EraseElements,
		demoLink: RouterConstants.ERASER_APP.fullPath,
	},
	{
		type: APICardType.ImageEditingAPICards,
		title: "eraseForeground",
		description: "eraseForegroundDescription",
		link: LinkConstants.BRIA_API_ERASER_FOREGROUND,
		image: EraseForeground,
	},
	{
		type: APICardType.ImageEditingAPICards,
		title: "backgroundGeneration",
		description: "backgroundGenerationDescription",
		link: LinkConstants.BRIA_API_BACKGROUND_GENERATION,
		image: backgroundGenerationPng,
		demoLink: RouterConstants.GENERATE_BACKGROUND_APP.fullPath,
	},
	{
		type: APICardType.ImageEditingAPICards,
		title: "background_Removal",
		description: "backgroundRemovalDescription",
		link: LinkConstants.BRIA_API_BACKGROUND_REMOVAL,
		image: backgroundRemoval,
		demoLink: RouterConstants.REMOVE_BACKGROUND_APP.fullPath,
	},
	{
		type: APICardType.ImageEditingAPICards,
		title: "blur_Background",
		description: "blurBackgroundDescription",
		link: LinkConstants.BRIA_API_BACKGROUND_BLUR,
		image: backgroundBlur,
		demoLink: RouterConstants.BLUR_BACKGROUND_APP.fullPath,
	},
	{
		type: APICardType.ImageEditingAPICards,
		title: "image_Expansion",
		description: "imageExpansionDescription",
		link: LinkConstants.BRIA_API_IMAGE_EXPANSTION,
		image: imageExpansion,
		demoLink: RouterConstants.EXPAND_IMAGE_APP.fullPath,
	},
	{
		type: APICardType.ImageEditingAPICards,
		subType: ImageModificationsSubAPIType.IncreaseResolution,
		title: "increase_Resolution",
		description: "increaseResolutionDescription",
		link: LinkConstants.BRIA_API_INCREASE_RESOLUTION,
		image: increase_res,
		demoLink: RouterConstants.INCREASE_RESOLUTION_APP.fullPath,
	},
	{
		type: APICardType.ImageEditingAPICards,
		title: "delayerImage",
		description: "delayerImageDescription",
		link: LinkConstants.BRIA_API_IMAGE_TO_PSD,
		image: imageToPsd,
		demoLink: RouterConstants.DELAYER_IMAGE_APP.fullPath,
	},
	{
		type: APICardType.AISearchAPICards,
		title: "labeless_AI_Image_Search",
		description: "labelessAIImageSearchDescription",
		link: LinkConstants.BRIA_API_LABELESS_AI_IMAGE_SEARCH,
		image: LabelessAIImageSearch,
	},
	{
		type: APICardType.ImageEditingAPICards,
		title: "cropOutForground",
		description: "cropOutForgroundDescription",
		link: LinkConstants.BRIA_API_CROP_OUT_FOREGROUND,
		image: cropOutForgroundPng,
		demoLink: RouterConstants.CROP_OUT_FOREGROUND_APP.fullPath,
	},
	{
		type: APICardType.ImageEditingAPICards,
		title: "modify_Presenter",
		description: "modifyPresenterDescription",
		link: LinkConstants.BRIA_API_MODIFY_PRESENTER,
		image: modifyPresenter,
	},
	{
		type: APICardType.ImageEditingAPICards,
		title: "masks_Generator",
		description: "masksGeneratorDescription",
		link: LinkConstants.BRIA_API_MASK_GENERATOR,
		image: masksGenerator,
		demoLink: RouterConstants.GET_MASKS_APP.fullPath,
	},
	{
		type: APICardType.ProductShot,
		title: "productCutouts",
		description: "productCutoutsDescription",
		link: LinkConstants.BRIA_API_PRODUCT_CUTOUTS,
		image: productCutoutsPng,
		demoLink: RouterConstants.PRODUCT_CUTOUT_APP.fullPath,
	},
	{
		type: APICardType.ProductShot,
		title: "productPackShots",
		description: "productPackShotsDescription",
		link: LinkConstants.BRIA_API_PRODUCT_PACK_SHOTS,
		image: productPackShotsPng,
		demoLink: RouterConstants.PRODUCT_PACKSHOT_APP.fullPath,
	},
	{
		type: APICardType.ProductShot,
		title: "productShadows",
		description: "productShadowDescription",
		link: LinkConstants.BRIA_API_PRODUCT_SHADOW,
		image: productShadowPng,
		demoLink: RouterConstants.PRODUCT_SHADOW_APP.fullPath,
	},
	{
		type: APICardType.ProductShot,
		title: "lifestyleProductShotByText",
		description: "lifestyleProductShotByTextDescription",
		link: LinkConstants.BRIA_API_TEXT_PROMPTS_LIFESTYLE,
		image: lifestyleProductTextPng,
		demoLink: RouterConstants.LIFESTYLE_PRODUCT_SHOT_BY_TEXT_APP.fullPath,
	},
	{
		type: APICardType.ProductShot,
		title: "lifestyleProductShotByImage",
		description: "lifestyleProductShotByImageDescription",
		link: LinkConstants.BRIA_API_LIFESTYLE_PRODUCT_SHOT_BY_IMAGE,
		image: lifestyleProductShotByImagePng,
		badges: ["comingSoon"],
	},
	{
		type: APICardType.ProductShot,
		title: "consistentStudioShots",
		description: "consistentStudioShotsDescription",
		link: LinkConstants.BRIA_API_CONSISTENT_STUDIO_SHOTS,
		image: consistentStudioShotsPng,
		badges: ["comingSoon"],
	},
	{
		type: APICardType.ProductShot,
		title: "contextualKeywordExtraction",
		description: "contextualKeywordExtractionDescription",
		link: LinkConstants.BRIA_API_CONTEXTUAL_KEYWORD_EXTRACTION,
		image: contextualKeywordExtractionPng,
		badges: ["comingSoon"],
	},
	{
		type: APICardType.BrandedGenAPICards,
		title: "managedTailoredModels",
		description: "managedTailoredModelsDescription",
		link: LinkConstants.BRIA_API_BRANDED_MODELS_MANAGEMENT,
		image: managedTailoredModelsPng,
	},
	{
		type: APICardType.BrandedGenAPICards,
		title: "manageDatasets",
		description: "manageDatasetsDescription",
		link: LinkConstants.BRIA_API_DATASET_MANAGMENT,
		image: manageDatasetsPng,
	},
	{
		type: APICardType.BrandedGenAPICards,
		title: "trainModels",
		description: "trainModelsDescription",
		link: LinkConstants.BRIA_API_TRAIN_MODELS,
		image: trainModelsPng,
	},
	{
		type: APICardType.BrandedGenAPICards,
		title: "generateImages",
		description: "generateImagesDescription",
		link: LinkConstants.BRIA_API_GENERATE_IMAGES,
		image: generateImagesPng,
	},
	{
		type: APICardType.ImageGenerationAPICards,
		title: "baseModel",
		description: "baseModelDescription",
		link: LinkConstants.BRIA_API_TEXT_TO_IMAGE,
		image: BaseModel,
		demoLink: RouterConstants.TEXT_TO_IMAGE_BASE.fullPath,
	},
	{
		type: APICardType.ImageGenerationAPICards,
		title: "fastModel",
		description: "fastModelDescription",
		link: LinkConstants.BRIA_API_TEXT_TO_IMAGE_FAST,
		image: FastModel,
		demoLink: RouterConstants.TEXT_TO_IMAGE_FAST.fullPath,
	},
	{
		type: APICardType.ImageGenerationAPICards,
		title: "HDModel",
		description: "HDModelDescription",
		link: LinkConstants.BRIA_API_TEXT_TO_IMAGE_HD,
		image: HDModel,
		demoLink: RouterConstants.TEXT_TO_IMAGE_HD.fullPath,
	},
	{
		type: APICardType.ImageGenerationAPICards,
		title: "vectorGraphicsBase",
		description: "vectorGraphicsBaseDescription",
		link: LinkConstants.BRIA_API_TEXT_TO_VECTOR,
		image: VectorBase,
		demoLink: RouterConstants.TEXT_TO_VECTOR_BASE.fullPath,
	},
	{
		type: APICardType.ImageGenerationAPICards,
		title: "vectorGraphicsFast",
		description: "vectorGraphicsFastDescription",
		link: LinkConstants.BRIA_API_TEXT_TO_VECTOR_FAST,
		image: VectorFast,
		demoLink: RouterConstants.TEXT_TO_VECTOR_FAST.fullPath,
	},
	{
		type: APICardType.ImageGenerationAPICards,
		title: "generateImageWithReference",
		description: "generateImageWithReferenceDescription",
		link: LinkConstants.BRIA_API_IMAGE_REFERENCE,
		image: ImageReference,
		demoLink: RouterConstants.GENERATE_IMAGE_WITH_REFERENCE.fullPath,
	},
	{
		type: APICardType.ImageGenerationAPICards,
		title: "promptEnhancement",
		description: "promptEnhancementDescription",
		link: LinkConstants.BRIA_API_PROMPT_ENHANCE,
		image: PromptEnhancement,
		demoLink: RouterConstants.PROMPT_ENHANCEMENT.fullPath,
	},
	{
		type: APICardType.VideoEditingAPICards,
		title: "videoBackgroundRemove",
		description: "videoBackgroundRemoveDescription",
		link: LinkConstants.BRIA_API_VIDEO_BACKGROUND_REMOVE,
		image: VideoBackgroundRemove,
		demoLink: RouterConstants.VIDEO_BACKGROUND_REMOVE_APP.fullPath,
	},
];
