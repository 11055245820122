import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CloudUploadSVG from "../../../../assets/images/svgs/AIEditor/cloud-upload.svg";
import VideoUploader from "../../../../components/common/Video/VideoUploader.tsx";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import styles from "./EmptyPlayground.module.scss";

const EmptyPlayground = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.videoEmptyState" });
	const { sandboxAPIStore } = useAppStore();

	useEffect(() => {}, []);

	return (
		<>
			<Box className={styles.container}>
				<VideoUploader
					className={styles.videoUploader}
					onUpload={sandboxAPIStore.handleUploadVideo}
					description={
						<Box className={styles.videoUploaderDesc}>
							<Box className={styles.header}>{t("header")}</Box>
                            <Box className={styles.subHeader}>{t("subHeader")}</Box>
						</Box>
					}
					loading={sandboxAPIStore.isUploadingVideo}
					icon={CloudUploadSVG}
                    maxDuration={10}
					error={sandboxAPIStore.errorMessage}
				/>
			</Box>
		</>
	);
};

export default observer(EmptyPlayground);
