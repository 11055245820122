import { Box, Grid, Paper, Typography } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import useSecureNavigate from "../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../hooks/useStores.tsx";
import { EventLogTypesEnum } from "../../models/common.ts";
import styles from "../../pages/Models/BriaModels/BriaModels.module.scss";
import BriaButton from "../common/BriaButton/BriaButton.tsx";

export interface IModelCardButton {
	name: string;
	link: string;
	color?: string;
	type: string;
	iconSrc?: string;
}

export interface IModelCardProps {
	type: string;
	title: string;
	description: string;
	buttons?: IModelCardButton[];
	image?: string;
}

export const ModelCardComponent = ({ title, description, image, buttons }: IModelCardProps) => {
	const navigate = useSecureNavigate();
	const { appStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "models_page" });
	return (
		<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
			<Paper elevation={0} className={styles.apiCard}>
				<Box className={styles.card}>
					<Box>
						<Box className={styles.imgContainter}>
							<img className={styles.image} src={image} alt="" />
						</Box>
					</Box>
					<Box className={styles.textAndButtonContainer}>
						<Typography className={styles.title}>{t(title)} </Typography>
						<Typography className={styles.description}>{t(description)}</Typography>
						<Box className={styles.buttonsGroup}>
							{buttons?.map((button: IModelCardButton) => (
								<>
									{button.link !== "" && (
										<Box className={styles.button}>
											<BriaButton
												onClick={async () => {
													if (button.link) {
														if (button.type === "redirect") {
															if (
																button.name === "foundationModels.buttons.requestAccess"
															) {
																appStore.logEvent({
																	eventType:
																		EventLogTypesEnum.REQUEST_FOR_FOUNDATION_MODELS,
																});
															}
															navigate(button.link);
														} else {
															window.open(button.link, "_blank");
														}
													}
												}}
												className={clsx({
													[styles.link]: true,
													[styles.blueLink]: button.color === "blue",
												})}
												buttonType="textSmall"
											>
												{button.iconSrc && (
													<img src={button.iconSrc} className={styles.buttonIcon} />
												)}
												{t(button.name)}
											</BriaButton>
										</Box>
									)}
								</>
							))}
						</Box>
					</Box>
				</Box>
			</Paper>
		</Grid>
	);
};
