import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { PlaygroundVideoResult } from "../../../models/playground.ts";
import styles from "./Video.module.scss";

interface VideoProps {
	video: PlaygroundVideoResult;
}

const Video: React.FC<VideoProps> = ({ video }) => {
	const { t } = useTranslation("translation", { keyPrefix: "video" });

	if (!video.src) {
		return <Box className={styles.placeholder}>{t("noVideo")}</Box>;
	}

	return (
		<Box className={styles.container}>
			<video controls src={video.src} className={styles.videoPlayer}>
				{t("browserDoesNotSupportVideo")}
			</video>
		</Box>
	);
};

const ObservedComponent = observer(Video);
export default ObservedComponent;
