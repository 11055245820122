import { Avatar, Box, ButtonBase, Divider, Tooltip, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import EditAvatarIcon from "../../../assets/images/icons/EditAvatarIcon";
import BriaButton from "../../../components/common/BriaButton/BriaButton";
import BriaInput from "../../../components/common/BriaInput/BriaInput";
import ConfirmationPopup from "../../../components/common/ConfirmationPopup/ConfirmationPopup";
import RouterConstants from "../../../constants/RouterConstants";
import { USER_DEFAULT_AVATAR } from "../../../constants/UserConstants";
import { getSelectedOrganization } from "../../../helpers/localStorage";
import { useAuthService } from "../../../hooks/useAuthService";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../hooks/useStores";
import styles from "./Profile.module.scss";

const Profile = () => {
	const { authStore, appStore, uiStore } = useAppStore();
	const { resetPassword } = useAuthService();
	const navigate = useSecureNavigate();
	const { t } = useTranslation("translation", { keyPrefix: "userProfile.profile" });
	const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
	const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
	const [showDeleteUserAndOrgModal, setShowDeleteUserAndOrgModal] = useState(false);
	const [showManageOwnershipModal, setShowManageOwnershipModal] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [newProfilePicture, setNewProfilePicture] = useState<any>();
	const fullName = authStore.user?.userName || "";
	const [firstName, setFirstName] = useState(fullName.split(" ")[0] || "");
	const [lastName, setLastName] = useState(fullName.split(" ")[1] || "");

	const handleProfilePictureChange = (event: any) => {
		const file = event.target.files[0];
		const reader = new FileReader();
		reader.onloadend = () => {
			setNewProfilePicture(reader.result);
		};
		reader.readAsDataURL(file);
	};

	const handleFirstNameChange = (event: any) => {
		setFirstName(event.target.value);
	};

	const handleLastNameChange = (event: any) => {
		setLastName(event.target.value);
	};

	const handleSaveChanges = async () => {
		setIsSaving(true);
		const username = `${firstName} ${lastName}`;
		const user = authStore.user;
		if (!user) {
			setIsSaving(false);
			return;
		}

		try {
			await appStore.updateUserInfo(user, username, newProfilePicture);
			setIsSaving(false);
		} catch (error) {
			console.error("Error updating user:", error);
			setIsSaving(false);
		}
	};

	const handleResetPassword = () => {
		setShowResetPasswordModal(true);
		const email = authStore.user?.email;

		if (email) {
			resetPassword(email)
				.then(() => {
					setTimeout(() => {
						setShowResetPasswordModal(false);
					}, 5000);
				})
				.catch((error) => {
					console.error("Error resetting password:", error);
				});
		}
	};

	const handleDeleteUserValidation = async () => {
		setIsDeleting(true);
		const resp = await appStore.validateUserDeletion();
		if (resp.is_owner_of_single_user_org) {
			setShowDeleteUserAndOrgModal(true);
		} else if (resp.is_owner_of_multi_user_org) {
			setShowManageOwnershipModal(true);
		} else {
			setShowDeleteUserModal(true);
		}
		setIsDeleting(false);
	};

	const handleDeleteUser = async () => {
		await appStore.deleteUser();
		await authStore.logout();
	};

	const handleDeleteUserAndOrg = async () => {
		await appStore.deleteUserAndOrganization();
		setShowDeleteUserAndOrgModal(false);
		uiStore.showSnackBarAlert("success", t("accountWillBeDeletedIn14Days"), 5000);
	};

	return (
		<Box className={styles.container}>
			{/* Personal details section */}
			<Box className={styles.section}>
				<Box className={styles.header}>
					<Typography className={styles.title}>{t("personalDetailsTitle")}</Typography>
				</Box>
				<Box className={styles.body}>
					<Box className={styles.fieldContainer}>
						<Box className={styles.fieldHeader}>
							<Typography className={clsx(styles.title, styles.fieldTitle)}>{t("avatar")}</Typography>
						</Box>
						<Box className={clsx(styles.fieldBody, styles.avatarContainer)}>
							<Avatar
								src={newProfilePicture ?? authStore.user?.profilePicture ?? USER_DEFAULT_AVATAR}
								className={styles.avatarStyle}
								onClick={() => {}}
							/>
							<Tooltip title={t("editProfile")} placement="top">
								<ButtonBase
									component="label"
									className={styles.editAvatarIcon}
									htmlFor="profile-picture-upload"
								>
									<input
										accept="image/*"
										id="profile-picture-upload"
										type="file"
										style={{ display: "none" }}
										onChange={handleProfilePictureChange}
									/>
									<EditAvatarIcon />
								</ButtonBase>
							</Tooltip>
						</Box>
					</Box>
					<Box className={clsx(styles.fieldContainer, styles.disabledField)}>
						<Box className={styles.fieldHeader}>
							<Typography className={clsx(styles.title, styles.fieldTitle)}>{t("email")}</Typography>
						</Box>
						<Box className={styles.fieldBody}>
							<Typography>{authStore.user?.email}</Typography>
						</Box>
					</Box>
					<Box className={styles.fieldContainer}>
						<Box className={styles.fieldHeader}>
							<Typography className={clsx(styles.title, styles.fieldTitle)}>{t("firstName")}</Typography>
						</Box>
						<Box className={styles.fieldBody}>
							<BriaInput
								type="text"
								optional={false}
								placeholder={t("firstName")}
								height="40px"
								fullWidth={true}
								value={firstName}
								onChange={handleFirstNameChange}
							></BriaInput>
						</Box>
					</Box>
					<Box className={styles.fieldContainer}>
						<Box className={styles.fieldHeader}>
							<Typography className={clsx(styles.title, styles.fieldTitle)}>{t("lastName")}</Typography>
						</Box>
						<Box className={styles.fieldBody}>
							<BriaInput
								type="text"
								optional={false}
								placeholder={t("lastName")}
								height="40px"
								fullWidth={true}
								value={lastName}
								onChange={handleLastNameChange}
							></BriaInput>
						</Box>
					</Box>
					<Box className={styles.submitContainer}>
						<BriaButton buttonType="primaryMedium" onClick={handleSaveChanges} loading={isSaving}>
							{t("saveChanges")}
						</BriaButton>
					</Box>
				</Box>
			</Box>
			<Divider />
			{/* Password section */}
			<Box className={clsx(styles.section, styles.passwordSection)}>
				<Box className={styles.header}>
					<Typography className={styles.title}>{t("password")}</Typography>
				</Box>
				<Box className={styles.body}>
					<Box className={styles.submitContainer}>
						<BriaButton buttonType="primaryMedium" onClick={handleResetPassword}>
							{t("resetPassword")}
						</BriaButton>
					</Box>
				</Box>
			</Box>
			<Divider />
			{/* Delete account section */}
			<Box className={clsx(styles.section, styles.deleteSection)}>
				<Box className={styles.header}>
					<Typography className={styles.title}>{t("deleteAccount")}</Typography>
				</Box>
				<Box className={styles.body}>
					<Box className={styles.fieldContainer}>
						<Box className={styles.fieldHeader}>
							<Typography className={clsx(styles.title, styles.fieldTitle)}>
								{t("deleteAccount")}
							</Typography>
						</Box>
						<Box className={styles.fieldBody}>
							<Typography>{t("deleteAccountWarning")}</Typography>
						</Box>
					</Box>
					<Box className={styles.submitContainer}>
						<BriaButton buttonType="outlined" onClick={handleDeleteUserValidation} loading={isDeleting}>
							{t("deleteAccount")}
						</BriaButton>
					</Box>
				</Box>
			</Box>
			{/* Reset password confirmation popup */}
			<ConfirmationPopup
				onClose={() => {
					setShowResetPasswordModal(false);
				}}
				description={t("resetPasswordSent")}
				hideActions={true}
				open={showResetPasswordModal}
				maxWidth="505px"
			/>
			{/* Delete user confirmation popup */}
			<ConfirmationPopup
				onClose={() => {
					setShowDeleteUserModal(false);
				}}
				title={t("confirmDeleteUser.title")}
				description={t("confirmDeleteUser.description")}
				subTitle={t("confirmDeleteUser.subTitle")}
				confirmButtonText={t("confirmDeleteUser.button")}
				hideCancel={true}
				loading={isSaving}
				onClick={handleDeleteUser}
				open={showDeleteUserModal}
			/>
			{/* Delete user and organization confirmation popup */}
			<ConfirmationPopup
				onClose={() => {
					setShowDeleteUserAndOrgModal(false);
				}}
				title={t("confirmDeleteUserAndOrg.title")}
				description={t("confirmDeleteUserAndOrg.description", {
					organization: getSelectedOrganization()?.organization?.name,
				})}
				confirmButtonText={t("confirmDeleteUserAndOrg.button")}
				hideCancel={true}
				loading={appStore.isUserDeletionLoading}
				onClick={handleDeleteUserAndOrg}
				open={showDeleteUserAndOrgModal}
			/>
			{/* Manage ownership popup */}
			<ConfirmationPopup
				onClose={() => {
					setShowManageOwnershipModal(false);
				}}
				title={t("manageOwnershipPopup.title")}
				description={t("manageOwnershipPopup.description")}
				subDescription={t("manageOwnershipPopup.subDescription")}
				confirmButtonText={t("manageOwnershipPopup.button")}
				hideCancel={true}
				onClick={() => navigate(RouterConstants.USERS.fullPath)}
				open={showManageOwnershipModal}
			/>
		</Box>
	);
};

export default observer(Profile);
