export enum APPS {
	TEXT_TO_IMAGE = "textToImage",
	TEXT_TO_VECTOR = "textToVector",
	IMAGE_TO_IMAGE = "imageToImage",
	PRODUCT_PLACEMENT = "productPlacement",
	SANDBOX_API = "sandboxAPI",
	CAMPAIGN = "campaign",
}

export enum IMAGES_FORMATS {
	PNG = "PNG",
	PSD = "PSD",
	SVG = "SVG",
}

export enum IMAGES_RESOLUTIONS {
	X1 = "X1",
	X2 = "X2",
	X4 = "X4",
}

export const IMAGES_FORMATS_LIST = [
	{
		key: IMAGES_FORMATS.PNG,
		value: IMAGES_FORMATS.PNG,
	},
	{
		key: IMAGES_FORMATS.SVG,
		value: IMAGES_FORMATS.SVG,
	},
	{
		key: IMAGES_FORMATS.PSD,
		value: IMAGES_FORMATS.PSD,
	},
];

export const IMAGES_RESOLUTIONS_LIST = [
	{
		key: IMAGES_RESOLUTIONS.X1,
		value: IMAGES_RESOLUTIONS.X1,
	},
	{
		key: IMAGES_RESOLUTIONS.X2,
		value: IMAGES_RESOLUTIONS.X2,
	},
	{
		key: IMAGES_RESOLUTIONS.X4,
		value: IMAGES_RESOLUTIONS.X4,
	},
];
export const SANDBOX_API_BASE_URL = "https://engine.prod.bria-api.com/v1";

export const MAX_INC_RESOLUTION_PIXELS = 67108864;
