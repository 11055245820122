import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useRef } from "react";
import { APPS } from "../../../constants/AppsConstants.ts";
import { useAppStore } from "../../../hooks/useStores";
import PlaygroundActionsHeader from "../ActionsHeader/PlaygroundActionsHeader";
import EmptyPlayground from "./EmptyState/EmptyPlayground";
import styles from "./PlaygroundVideos.module.scss";
import VideoResults from "./VideoResults/VideoResults.tsx";

const PlaygroundVideos = () => {
	const { playgroundStore } = useAppStore();
	const resultsRef = useRef<HTMLDivElement>(null);

	return (
		<Box className={styles.root}>
			<PlaygroundActionsHeader />
			{playgroundStore.selectedConfig === APPS.SANDBOX_API && (
				<Box ref={resultsRef} className={`results ${styles.results} ${styles.sandboxAPIresults}`}>
					{!playgroundStore.playgroundVideoResults.length ? (
						<EmptyPlayground />
					) : (
						playgroundStore.playgroundVideoResults.map((result, index) => (
							<VideoResults key={index} playgroundResult={result} />
						))
					)}
				</Box>
			)}
		</Box>
	);
};

export default observer(PlaygroundVideos);
