import { Box, Link as MuiLink, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import RouterConstants from "../../constants/RouterConstants";
import useOrgNavigation from "../../hooks/useOrgNavigation";
import styles from "../../pages/Home/Home.module.scss";

export interface ICardProps {
	icon: string;
	cardTitle: string;
	cardDescription: string;
	mainButton: string;
	background: string;
	newTab: boolean;
	href?: string;
	containGetApiKeyButton: boolean;
	containGetModelButton: boolean;
	containconfigureIframeButton: boolean;
	extraCardLinks?: IExtraCardLinks[];
}

export interface IExtraCardLinks {
	icon?: string;
	title: string;
	href: string;
}

export const DashoboardCardComponent = ({
	icon,
	cardTitle,
	cardDescription,
	background,
	newTab,
	href,
	containGetApiKeyButton,
	containGetModelButton,
	containconfigureIframeButton,
	extraCardLinks,
}: ICardProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "briaProductsAndServices" });
	const { orgNavigationHandler } = useOrgNavigation();
	return (
		<Grid
			item
			xs={12}
			lg={6}
			md={12}
			xl={4}
			className={styles.card}
			onClick={() => (href ? orgNavigationHandler(href, undefined, newTab) : undefined)}
		>
			<Box className={styles.cardWrapper}>
				<Paper className={styles.paper} elevation={0}>
					<Box className={styles.cardContent}>
						<Box
							className={styles.imageContainer}
							style={{
								background: background,
							}}
						>
							<img className={styles.image} src={icon} alt="" />
						</Box>
						<Box>
							<Typography className={styles.cardTitle}>{t(cardTitle)}</Typography>
							<Typography className={styles.cardDescription}>{t(cardDescription)}</Typography>
							<Box className={styles.cardButton}>
								{containGetApiKeyButton && (
									<MuiLink
										className={styles.muiLink}
										component={RouterLink}
										to={RouterConstants.AUTH_KEYS.fullPath}
										target={"_self"}
									>
										{t("getApiKey")}
									</MuiLink>
								)}
								{containGetModelButton && (
									<MuiLink
										className={styles.muiLink}
										component={RouterLink}
										to={RouterConstants.FOUNDATION_MODELS.fullPath}
										target={"_self"}
									>
										{t("getModel")}
									</MuiLink>
								)}
								{containconfigureIframeButton && (
									<MuiLink
										className={styles.muiLink}
										component={RouterLink}
										to={RouterConstants.IFRAME_CONFIG.fullPath}
										target={"_self"}
									>
										{t("configureIframe")}
									</MuiLink>
								)}
							</Box>
							{extraCardLinks && (
								<Box className={styles.externalLinks}>
									{extraCardLinks?.map((externalLink: IExtraCardLinks, index: number) => (
										<Box key={index} className={styles.externalLinkItem}>
											{externalLink.icon && (
												<img
													src={externalLink.icon}
													alt=""
													onClick={() => window.open(externalLink.href, "_blank")}
												/>
											)}
											<MuiLink
												component={RouterLink}
												className={styles.link}
												to={externalLink.href}
												target={"_blank"}
												onClick={(e) => e.stopPropagation()}
											>
												{t(externalLink.title)}
											</MuiLink>
										</Box>
									))}
								</Box>
							)}
						</Box>
					</Box>
				</Paper>
			</Box>
		</Grid>
	);
};
