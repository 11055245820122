const EditAvatarIcon = ({...props}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
			<path
				d="M7.5 4H7.505M5.5 10H3.5C3.10218 10 2.72064 9.84196 2.43934 9.56066C2.15804 9.27936 2 8.89782 2 8.5V3.5C2 3.10218 2.15804 2.72064 2.43934 2.43934C2.72064 2.15804 3.10218 2 3.5 2H8.5C8.89782 2 9.27936 2.15804 9.56066 2.43934C9.84196 2.72064 10 3.10218 10 3.5V5.5M2 7.50001L4 5.50001C4.464 5.05351 5.036 5.05351 5.5 5.50001L7 7.00001L7.5 6.5C7.655 6.351 7.822 6.2515 7.9935 6.202M9.21 7.80499C9.30751 7.70748 9.42326 7.63014 9.55066 7.57737C9.67806 7.5246 9.81461 7.49744 9.9525 7.49744C10.0904 7.49744 10.2269 7.5246 10.3543 7.57737C10.4817 7.63014 10.5975 7.70748 10.695 7.80499C10.7925 7.9025 10.8699 8.01825 10.9226 8.14565C10.9754 8.27305 11.0026 8.40959 11.0026 8.54749C11.0026 8.68538 10.9754 8.82193 10.9226 8.94933C10.8699 9.07673 10.7925 9.19248 10.695 9.28999L9 11H7.5V9.49999L9.21 7.80499Z"
				stroke="#5B5B5B"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default EditAvatarIcon;
