import { saveAs } from "file-saver";
import JSZip from "jszip";
import LinkConstants from "../constants/LinkConstants.ts";
import { BRIA_USER_ASSETS } from "../constants/common.ts";
import { IframePostMessageActionTypes, IframePostMessageItemTypes } from "../models/new-iframe.ts";
import iframeStore from "../pages/IframeNew/iframe-store.tsx";
import ImagesService from "../services/ImagesService.ts";
import QueryService from "./QueryService";
import { getSafeFileName } from "./index.ts";

export type AsyncProps = {
	type?: "frontend" | "backend";
	maxAttempts?: number;
	sleepSecs?: number;
};

type ImageUtils = {
	exportImage: (src: string, fileName: string, resolution?: number, action?: "download" | "save") => Promise<void>;
	exportImageAsPsd: (src: string, fileName: string) => Promise<void>;
	uploadImageAndExportPsd: (
		imageUrl: string,
		fileName?: string,
		resolution?: number,
		action?: "download" | "save",
	) => Promise<string>;
	exportZipImages: (
		images: {
			fileName: string;
			imageUrl: string;
		}[],
		fileName: string,
		action?: "download" | "save",
	) => Promise<void>;
	getFileExtension: (blob: Blob) => string;
	isS3Uri: (uri: string) => boolean;
	convertS3UriToHttpsUrl: (s3Uri: string) => string;
	convertS3UriToHttpsCloudFrontUrl: (s3Uri: string) => string;
	getBlob: (src: string) => Promise<Blob>;
	isBlob: (url: string) => boolean;
	getBase64: (blob: Blob) => Promise<string | ArrayBuffer | null>;
	pullUntilAvailable: (fileUrl: string, abortController?: AbortController, asyncProps?: AsyncProps) => Promise<void>;
	getAspectRatioFromBase64: (base64: string) => Promise<number>;
};

const useImageUtils = (): ImageUtils => {
	const uploadImageAndExportPsd = async (
		imageUrl: string,
		fileName?: string,
		resolution: number = 1,
		action: "download" | "save" = "download",
	): Promise<string> => {
		const scaledUrl =
			resolution === 1 ? imageUrl : await new ImagesService().increaseResolution(imageUrl, resolution);
		const psdUrl = await new ImagesService().exportPsd(scaledUrl);
		const ImageFileName = fileName ?? new URL(psdUrl).pathname.split("/").pop();

		if (action === "download") {
			await exportImageAsPsd(psdUrl, ImageFileName ?? "psd_image");

			iframeStore.sendExportPostMessage(IframePostMessageActionTypes.Download, IframePostMessageItemTypes.Psd, [
				{ type: IframePostMessageItemTypes.Psd, src: psdUrl },
			]);
		} else if (action === "save") {
			iframeStore.sendExportPostMessage(IframePostMessageActionTypes.Save, IframePostMessageItemTypes.Psd, [
				{ type: IframePostMessageItemTypes.Psd, src: psdUrl },
			]);
		}

		return psdUrl;
	};

	const exportImage = async (
		url: string,
		fileName: string,
		resolution: number = 1,
		action: "download" | "save" = "download",
	) => {
		const scaledUrl = resolution === 1 ? url : await new ImagesService().increaseResolution(url, resolution);
		if (action === "download") {
			const blob = await getBlob(scaledUrl);
			saveAs(blob, `${getSafeFileName(fileName)}.${getFileExtension(blob)}`);

			iframeStore.sendExportPostMessage(IframePostMessageActionTypes.Download, IframePostMessageItemTypes.Image, [
				{ type: IframePostMessageItemTypes.Image, src: scaledUrl },
			]);
		} else if (action === "save") {
			iframeStore.sendExportPostMessage(IframePostMessageActionTypes.Save, IframePostMessageItemTypes.Image, [
				{ type: IframePostMessageItemTypes.Image, src: scaledUrl },
			]);
		}
	};

	const exportImageAsPsd = async (url: string, fileName: string) => {
		const blob = await getBlob(url);
		saveAs(blob, `${getSafeFileName(fileName)}.psd`);
	};

	const exportZipImages = async (
		images: {
			fileName: string;
			imageUrl: string;
		}[],
		zipName: string,
		action: "download" | "save" = "download",
	) => {
		if (images.length) {
			const zip = new JSZip();

			try {
				await Promise.all(
					images.map(async (image, _index) => {
						try {
							const blob = await getBlob(image.imageUrl);
							const fullFileName = `${getSafeFileName(image.fileName)}_${_index}.${getFileExtension(
								blob,
							)}`;
							const base64 = ((await getBase64(blob)) as string).split(",")[1];
							zip.file(fullFileName, base64, { base64: true });
						} catch (err) {
							console.error(`Error processing ${image.fileName}:`, err);
						}
					}),
				);
				if (action === "download") {
					zip.generateAsync({ type: "blob" }).then((content) => {
						saveAs(content, `${zipName}.zip`);
					});
					iframeStore.sendExportPostMessage(
						IframePostMessageActionTypes.Download,
						IframePostMessageItemTypes.Image,
						images.map((image) => ({
							type: IframePostMessageItemTypes.Image,
							src: image.imageUrl,
						})),
					);
				} else if (action === "save") {
					iframeStore.sendExportPostMessage(
						IframePostMessageActionTypes.Save,
						IframePostMessageItemTypes.Image,
						images.map((image) => ({
							type: IframePostMessageItemTypes.Image,
							src: image.imageUrl,
						})),
					);
				}
			} catch (err) {
				console.error("Error creating zip file:", err);
			}
		}
	};

	const getFileExtension = (blob: Blob): string => {
		const parts = blob.type.split("/");
		return parts[parts.length - 1].replace("octet-stream", "png").replace("svg+xml", "svg");
	};

	const isS3Uri = (uri: string): boolean => {
		return uri.startsWith("s3://");
	};

	const convertS3UriToHttpsUrl = (s3Uri: string): string => {
		const parts = s3Uri.split("/");
		const bucketName = parts[2];
		const filePath = parts.slice(3).join("/");
		const region = "us-east-1";

		return `https://${bucketName}.s3.${region}.amazonaws.com/${filePath}`;
	};

	const getBase64 = (blob: Blob): Promise<string | ArrayBuffer | null> => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	};

	const getAspectRatioFromBase64 = (base64: string): Promise<number> => {
		return new Promise<number>((resolve, reject) => {
			const img = new Image();

			img.onload = () => {
				const aspectRatio = img.width / img.height;
				resolve(aspectRatio);
			};

			img.onerror = reject;
			img.src = base64;
		});
	};

	const getBlob = async (url: string) => {
		const response = await fetch(url);
		const blob = await response.blob();
		return blob;
	};

	const isBlob = (url: string) => {
		return url.startsWith("blob:");
	};

	const checkFile = async (url: string, type: "frontend" | "backend") => {
		try {
			return type === "frontend"
				? await pullingFetch(url)
				: // Remove client+server completely once engine deploy routes for frontend pulling
				  (await new QueryService("/").post("pull_until_available", { file_url: url })).message;
		} catch {
			return false;
		}
	};

	async function pullingFetch(url: string) {
		try {
			const response = await fetch(url, {
				headers: {
					Range: "bytes=0-0",
				},
			});
			if (response.status === 200 || response.status === 206) return true;
			// Not functioning yet, wait for engine deployment to check implementation
			// if (response.status === 206) {
			// 	const sizeMatch = response.headers.get("Content-Range")?.match(/\/(\d+)$/);
			// 	return sizeMatch && parseInt(sizeMatch[1]) > 0;
			// }
		} catch {
			return false;
		}
		return false;
	}

	const pullUntilAvailable = async (
		fileUrl: string,
		abortController?: AbortController,
		{ type = "frontend", maxAttempts = 120, sleepSecs = 1 }: AsyncProps = {},
	): Promise<void> => {
		if (!fileUrl) throw new Error("Invalid input");
		for (let tries = 0; tries < maxAttempts; tries++) {
			if (abortController?.signal.aborted) throw new Error("Aborted");
			if (await checkFile(fileUrl, type)) return;
			await new Promise((resolve) => setTimeout(resolve, sleepSecs * 1000));
		}
		throw new Error("Max attempts reached");
	};

	const convertS3UriToHttpsCloudFrontUrl = (s3Uri: string): string => {
		const parts = s3Uri.split("/");
		const bucketName = parts[2];
		let newHost = LinkConstants.BRIA_ORG_ASSETS_CLOUDFRONT;
		if (bucketName == BRIA_USER_ASSETS) {
			newHost = LinkConstants.BRIA_USER_ASSETS_CLOUDFRONT;
		}
		const filePath = parts.slice(3).join("/");

		return `${newHost}${filePath}`;
	};

	return {
		exportImage,
		exportZipImages,
		getFileExtension,
		isS3Uri,
		convertS3UriToHttpsUrl,
		convertS3UriToHttpsCloudFrontUrl,
		getBlob,
		isBlob,
		pullUntilAvailable,
		exportImageAsPsd,
		uploadImageAndExportPsd,
		getBase64,
		getAspectRatioFromBase64,
	};
};

export default useImageUtils;

export const supportedImageFileTypes = ["png", "jpg", "jpeg", "webp"];
