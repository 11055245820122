import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { useAppStore } from "../../hooks/useStores";
import TabsLayout from "../../layout/TabsLayout/TabsLayout";

const UserProfile = () => {
	const { t } = useTranslation("translation", { keyPrefix: "userProfile" });
	const { appStore } = useAppStore();
	const subTabs = ["profile"];

	return (
		<>
			<TabsLayout title={t("userProfilePageTitle")} subTabs={subTabs} isLoading={appStore.isLoading}>
				<Outlet />
			</TabsLayout>
		</>
	);
};

export default observer(UserProfile);