import { getImageMeta } from "../../pages/Campaign/Brands/utils.tsx";

export class BrushActions {
	private tempCanvas: HTMLCanvasElement;
	private canvasRef: HTMLCanvasElement | null;
	private canvasOverlayRef: HTMLCanvasElement | null;
	private dataUrl: string;

	constructor(canvasRef: HTMLCanvasElement | null, canvasOverlayRef: HTMLCanvasElement | null) {
		this.tempCanvas = document.createElement("canvas");
		this.canvasRef = canvasRef;
		this.canvasOverlayRef = canvasOverlayRef;
		this.dataUrl = "#";
	}

	public handleCanvasClear(): void {
		if (!this.canvasOverlayRef) return;
	
		const ctx = this.canvasOverlayRef.getContext("2d");
		if (ctx) {
			ctx.clearRect(0, 0, this.canvasOverlayRef.width, this.canvasOverlayRef.height);
	
			// Optional: Reinitialize canvas state if needed
			ctx.globalCompositeOperation = "source-over"; // Reset blending mode
		}
	}

	public createBase64ImageMask(base64Image: string): Promise<string> {
		return new Promise<string>((resolve) => {
			const tempCanvas = this.tempCanvas;
			const ctx = tempCanvas.getContext("2d");

			getImageMeta(base64Image).then((img: any) => {
				tempCanvas.width = img.width;
				tempCanvas.height = img.height;
				ctx?.drawImage(img, 0, 0);

				const imageData = ctx?.getImageData(0, 0, tempCanvas.width, tempCanvas.height);
				const pixels = imageData?.data || [];
				const originalImgPixels = this.canvasOverlayRef
					?.getContext("2d")
					?.getImageData(0, 0, tempCanvas.width, tempCanvas.height)?.data;

				let whitePixelsCounter = 0;
				if (originalImgPixels) {
					for (let i = 0; i < originalImgPixels.length; i += 4) {
						const r = originalImgPixels[i];
						const g = originalImgPixels[i + 1];
						const b = originalImgPixels[i + 2];

						if (r > 0 || g > 0 || b > 0) {
							// Set pixel to white
							pixels[i] = 255;
							pixels[i + 1] = 255;
							pixels[i + 2] = 255;
							whitePixelsCounter++;
						} else {
							// Set pixel to black
							pixels[i] = 0;
							pixels[i + 1] = 0;
							pixels[i + 2] = 0;
						}
					}

					ctx?.putImageData(imageData!, 0, 0);
				}

				if(!whitePixelsCounter) {
					resolve("");
				} else {
					const processedBase64 = tempCanvas.toDataURL();
					resolve(processedBase64);
				}
			});
		});
	}

	public async handleCanvasDownload(): Promise<string | undefined> {
		if (!this.canvasRef) return;

		let base64Image = this.canvasRef.toDataURL("image/png");
		base64Image = await this.createBase64ImageMask(base64Image);

		return base64Image;
	}

	public async downloadCanvasImage() {
		const base64Image = await this.handleCanvasDownload();

		if (base64Image) {
			const link = document.createElement("a");

			link.href = base64Image;
			link.download = "canvas_image.png";

			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
		} else {
			console.error("Failed to download image: No valid base64 data returned");
		}
	}

	public getDataUrl() {
		return this.dataUrl;
	}
}
