import { Box, useTheme } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Swiper, SwiperSlide } from "swiper/react";
import BriaImage, { BriaImageProps } from "../../../../components/common/Galleries/BriaImage.tsx";
import styles from "./SingleImageToImage.module.scss";

// @ts-expect-error "React couldn't see Navigation inside swiper/modules while I ensured it's there"
import { Keyboard, Mousewheel, Navigation } from "swiper/modules";
import type { Swiper as SwiperClass } from "swiper/types";
import FullScreenViewer from "../../../../components/common/FullScreenViewer/FullScreenViewer.tsx";
import { FullScreenImageType } from "../../../../components/common/FullScreenViewer/Image/FullScreenImage.tsx";
import BriaCanvas from "../../../../components/common/Galleries/BriaCanvas.tsx";
import { APPS } from "../../../../constants/AppsConstants.ts";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import { PlaygroundImage } from "../../../../models/image-to-image.ts";
import { isPlaygroundLoadingImages } from "../../../../utils/index.ts";
import PlaygroundImageOverlay from "../../Images/Overlay/PlaygroundImageOverlay.tsx";

type IProps = {
	className?: string;
	swiperClassName?: string;
};

const SingleImageToImage = ({ className, swiperClassName }: IProps) => {
	const theme = useTheme();
	const { playgroundStore, imageToImageStore } = useAppStore();
	const [images, setImages] = useState<(BriaImageProps & PlaygroundImage)[]>([]);
	const [openFullScreen, setOpenFullScreen] = useState(false);
	const [imageIndexToView, setImageIndexToView] = useState<number>();
	const [numImages, setNumImages] = useState<number>(images.length);
	const [selectedImageIndex, setSelectedImageIndex] = useState<number>(images.findIndex((img) => img.selected));
	const [swiper, setSwiper] = useState<SwiperClass>();
	const loading = !!images.filter((image) => image.loading).length;
	const [isLoading, setIsLoading] = useState(false);
	const hasLoadingImages = isPlaygroundLoadingImages(playgroundStore.playgroundResults, APPS.IMAGE_TO_IMAGE) || false;
	const hasUploadingImages = isPlaygroundLoadingImages(playgroundStore.playgroundResults, "upload") || false;

	useEffect(() => {
		const updatedImages = hasUploadingImages
			? (playgroundStore.playgroundResults?.[0]?.images as (BriaImageProps & PlaygroundImage)[])
			: (playgroundStore.playgroundResults.flatMap((results) =>
					results.images.filter((image) => !image.errorType),
			) as (BriaImageProps & PlaygroundImage)[]);
		setImages(updatedImages);
	}, [playgroundStore.playgroundResults, imageToImageStore.isErasingImages, imageToImageStore.isGeneratingImages]);

	useEffect(() => {
		const defaultSlideIndex = images.length - 1;
		const isLoadingAndErasing = hasLoadingImages && imageToImageStore.isErasingImages;
		const selectedIndex = images.findIndex((image) => image.id === playgroundStore?.selectedSingleImage?.id);

		const slideIndex = isLoadingAndErasing
			? defaultSlideIndex
			: selectedIndex !== -1
			? selectedIndex
			: defaultSlideIndex;

		images[slideIndex] && playgroundStore.handleSelectSingleImage(images[slideIndex]);
		setSelectedImageIndex(slideIndex);
		if (numImages !== images.length && swiper) {
			slideTo(slideIndex);
		}
		setNumImages(images.length);
	}, [images, swiper]);

	useEffect(() => {
		imageToImageStore.brushConfigs.strokeStyle = theme.palette.primary.main;
	}, []);

	const handleOpenFullScreen = (index: number) => {
		setImageIndexToView(index);
		setOpenFullScreen(true);
	};

	const handleCloseFullScreen = () => {
		setOpenFullScreen(false);
		playgroundStore.selectImages([images[selectedImageIndex]]);
	};

	const initSwiper = (swiper: SwiperClass) => {
		setSwiper(swiper);
		onSlideChange(swiper);
	};

	const slideTo = (index: number) => {
		if (swiper) swiper.slideTo(index);
		playgroundStore.selectImages([images[index]]);
		setSelectedImageIndex(index);
		imageToImageStore.toggleIsMaskApplied(false);
	};

	const onSlideChange = (swiper: SwiperClass) => {
		const activeImage = images[swiper.realIndex];

		if (activeImage && !hasLoadingImages) {
			playgroundStore.selectImages([activeImage]);
			setSelectedImageIndex(swiper.realIndex);
			playgroundStore.handleSelectSingleImage(activeImage);
		}
		imageToImageStore.toggleIsMaskApplied(false);
	};

	const isBrushMode = playgroundStore.enablePlaygroundBrushMode && !images[selectedImageIndex]?.loading;

	return (
		<>
			<Box
				className={clsx(className, styles.container, {
					[styles.centerCanvas]: isBrushMode,
				})}
			>
				<Swiper
					onSwiper={initSwiper}
					speed={300}
					onSlideChange={onSlideChange}
					initialSlide={selectedImageIndex}
					className={clsx(swiperClassName, styles.swiper)}
					direction={"vertical"}
					spaceBetween={8}
					slidesPerView={1}
					mousewheel={true}
					loop={false}
					keyboard={{ enabled: true }}
					pagination={{ clickable: false }}
					modules={[Navigation, Keyboard, Mousewheel]}
					allowTouchMove={false}
				>
					{images.map((image, index) => {
						return (
							<SwiperSlide
								className={clsx(styles.swiperSlideContainer, {
									[styles.isEraseConfigsEnabled]: imageToImageStore.isEraseConfigsEnabled,
								})}
								key={index}
							>
								{isBrushMode && index === selectedImageIndex ? (
									<BriaCanvas
										key={`${selectedImageIndex}_${index}`}
										url={images[selectedImageIndex]?.url ?? ""}
										enableBrush={true}
										brushConfigs={imageToImageStore.brushConfigs}
										isLoading={isLoading || imageToImageStore.isErasingImages}
										onCanvasSizeUpdate={() => {
											setIsLoading(false);
										}}
										canvasRef={(canvas: HTMLCanvasElement | null) => {
											if (!imageToImageStore.brushCanvasRefs[0]) {
												imageToImageStore.brushCanvasRefs[0] = {
													canvasRef: { current: null },
													canvasOverlayRef: { current: null },
												};
											}

											imageToImageStore.brushCanvasRefs[0].canvasRef.current = canvas;
										}}
										canvasOverlayRef={(overlayCanvas: HTMLCanvasElement | null) => {
											if (!imageToImageStore.brushCanvasRefs[0]) {
												imageToImageStore.brushCanvasRefs[0] = {
													canvasRef: { current: null },
													canvasOverlayRef: { current: null },
												};
											}
											imageToImageStore.brushCanvasRefs[0].canvasOverlayRef.current =
												overlayCanvas;
										}}
									/>
								) : (
									<BriaImage
										{...image}
										ImageOverlay={<PlaygroundImageOverlay {...{ image }} />}
										displayOverlay={"customHover"}
										selectable={true}
										htmlJsonData={image.id}
										fullScreenProps={{
											...image.fullScreenProps,
											fileName: playgroundStore.getFileName(image),
										}}
										onSuccessPulling={async () => {
											index === swiper?.realIndex && playgroundStore.selectImages([image]);
											playgroundStore.onSuccessResult(image);
										}}
										onErrorPulling={(errorType) => playgroundStore.onErrorResult(image, errorType)}
										async={loading}
										asyncProps={{ maxAttempts: 360 }}
										downloadProps={{
											...image.downloadProps,
											fileName: playgroundStore.getFileName(image),
										}}
										handleFullScreen={() => handleOpenFullScreen && handleOpenFullScreen(index)}
										lazyImageProps={{ ...image.lazyImageProps }}
										image={image}
										hideDownloadIcon={false}
										skeletonClassName={styles.briaSkeletonWrapper}
										wrapperClassName={styles.briaWrapper}
										className={styles.briaImage}
									/>
								)}
							</SwiperSlide>
						);
					})}
				</Swiper>
			</Box>
			{openFullScreen && (
				<FullScreenViewer
					open={openFullScreen}
					images={images
						.filter((image) => !image.hideFullScreenButton && !image.errorType)
						.map((image) => ({ src: image.url, ...image.fullScreenProps }))}
					currentIndex={imageIndexToView}
					onClose={handleCloseFullScreen}
					onDownload={(fullscreenImages: FullScreenImageType[]) => {
						playgroundStore.onFullScreenViewerDownload(images, fullscreenImages);
					}}
				/>
			)}
		</>
	);
};

export default observer(SingleImageToImage);
