import { Box, ThemeProvider, Typography, createTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import styles from "../../pages/Home/Home.module.scss";
import { DashoboardCardComponent, ICardProps } from "./DashoboardCardComponent";

interface IProps {
	mainTitle?: string;
	title: string;
	description: string;
	cards: ICardProps[];
	className?: string;
}

export const DashboardSectionComponent = ({ mainTitle, title, description, cards, className }: IProps) => {
	const theme = createTheme({
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 960,
				lg: 1280,
				xl: 2000,
			},
		},
	});
	return (
		<Box className={className}>
			{mainTitle && <Typography className={styles.mainTitle}>{mainTitle}</Typography>}
			<Typography className={styles.mainComponentTitle}>{title}</Typography>
			<Typography className={styles.mainComponentDescription}>{description}</Typography>
			<ThemeProvider theme={theme}>
				<Grid className={styles.gridContainer} container>
					{cards.map((card: ICardProps, index: number) => (
						<DashoboardCardComponent key={index} {...card} />
					))}
				</Grid>
			</ThemeProvider>
		</Box>
	);
};
