import { Box, FormControlLabel, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ANALYTICS_EVENTS } from "../../../../../analytics-store";
import StopIcon from "../../../../../assets/images/icons/StopIcon";
import Vector from "../../../../../assets/images/svgs/VectorIcon.svg";
import BriaButton from "../../../../../components/common/BriaButton/BriaButton";
import BriaCheckbox from "../../../../../components/common/BriaCheckbox/BriaCheckbox";
import BriaInput from "../../../../../components/common/BriaInput/BriaInput";
import SurpriseButton from "../../../../../components/common/SurpriseButton/SurpriseButton";
import { APPS } from "../../../../../constants/AppsConstants";
import { useAppStore } from "../../../../../hooks/useStores";
import { modelTypes } from "../../../../../models/sandboxAPI";
import { isPlaygroundLoadingImages } from "../../../../../utils";
import styles from "./TextToImageSearch.module.scss";

const TextToImageSearch = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.textToImage.generator.searchBox" });
	const { textToImageStore, playgroundStore, analyticsStore, authStore } = useAppStore();
	const hasLoadingImages = isPlaygroundLoadingImages(playgroundStore.playgroundResults, APPS.TEXT_TO_IMAGE);
	const isGenerateEnabled =
		(textToImageStore.config.model || textToImageStore.config.model_url) &&
		textToImageStore.config.prompt &&
		!authStore.isLoadingOrgSubscriptions &&
		!authStore.orgPassedFreeLimit &&
		!hasLoadingImages;

	useEffect(() => {
		if (textToImageStore.config.tailored_model_id) {
			textToImageStore.handleConfigChange("prompt_enhancement", false);
		} else if (!textToImageStore.isTextToVector()) {
			textToImageStore.handleConfigChange("prompt_enhancement", true);
		}
	}, [textToImageStore.config.tailored_model_id]);

	const handleGenerateTextToImage = () => {
		const { config, generateTextToImage, isImageReferenceEnabled } = textToImageStore;
		const { logEvent } = analyticsStore;

		const modelName = textToImageStore.config.model_name;

		generateTextToImage();

		logEvent(ANALYTICS_EVENTS.IMAGE_GENERATION_GENERATE, {
			model_name: modelName,
		});

		if (isImageReferenceEnabled()) {
			logEvent(ANALYTICS_EVENTS.IMAGE_GENERATION_IMAGE_REFERENCE, {
				model_name: modelName,
			});
		}

		if (config.tailored_model_id) {
			logEvent(ANALYTICS_EVENTS.IMAGE_GENERATION_TAILORED_MODELS, {
				tailoredModelId: config.tailored_model_id,
				model_name: modelName,
			});
			return;
		}

		const modelEventMap = {
			[modelTypes.Fast]: ANALYTICS_EVENTS.IMAGE_GENERATION_BRIA_FAST,
			[modelTypes.Base]: ANALYTICS_EVENTS.IMAGE_GENERATION_BRIA_BASE,
			[modelTypes.Hd]: ANALYTICS_EVENTS.IMAGE_GENERATION_BRIA_HD,
		};

		const modelEvent = modelEventMap[config.model as keyof typeof modelEventMap];
		if (modelEvent) {
			logEvent(modelEvent, {
				model_name: modelName,
			});
		}
	};

	return (
		<Box className={playgroundStore.isText2ImagePopupOpened ? styles.rootModal : styles.root}>
			{textToImageStore.errorMessage && (
				<Box className={styles.errorMessageContainer}>
					<img src={Vector} />
					<Typography className={styles.errorMessage}>{textToImageStore.errorMessage}</Typography>
				</Box>
			)}
			<Box className={styles.searchBoxWrapper}>
				<BriaInput
					value={textToImageStore.config.prompt}
					onChange={(e) => textToImageStore.handleConfigChange("prompt", e.target.value)}
					onEnterPress={handleGenerateTextToImage}
					className={styles.searchBox}
					placeholder={t("placeholder")}
					InputProps={{
						endAdornment: (
							<SurpriseButton
								textsListType={textToImageStore.isTextToVector() ? "textToVector" : "textToImage2"}
								onSurprise={(prompt) => textToImageStore.handleConfigChange("prompt", prompt)}
							/>
						),
					}}
					inputProps={{ className: styles.inputBase }}
				/>
				{hasLoadingImages ? (
					<BriaButton
						buttonType="primaryMedium"
						className={clsx(styles.button, styles.stopGeneratingBtn)}
						onClick={() => textToImageStore.abortImageGeneration()}
					>
						<StopIcon /> {t("stop")}
					</BriaButton>
				) : (
					<BriaButton
						buttonType="primaryMedium"
						className={styles.button}
						onClick={handleGenerateTextToImage}
						loading={authStore.isLoadingOrgSubscriptions}
						disabled={!isGenerateEnabled || textToImageStore.isUploadingImageReference}
					>
						{t("button")}
					</BriaButton>
				)}
			</Box>
			{!textToImageStore.isTextToVector() && !textToImageStore.config.tailored_model_id && (
				<FormControlLabel
					disabled={textToImageStore.isImageReferenceEnabled()}
					control={
						<BriaCheckbox
							checked={
								textToImageStore.config?.prompt_enhancement &&
								!textToImageStore.isImageReferenceEnabled()
							}
							onChange={(e) =>
								textToImageStore.handleConfigChange("prompt_enhancement", e.target.checked)
							}
						/>
					}
					label={t("enhancedPrompt")}
				/>
			)}
		</Box>
	);
};

export default observer(TextToImageSearch);
