import CloseIcon from "@mui/icons-material/CloseTwoTone";
import { Box, CircularProgress, Popover, TextField, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "rsuite";
import DislikeSVG from "../../../../assets/images/svgs/Dislike.svg";
import LikeSVG from "../../../../assets/images/svgs/Like.svg";
import SelectedDislikeSVG from "../../../../assets/images/svgs/Selected-Dislike.svg";
import SelectedLikeSVG from "../../../../assets/images/svgs/Selected-Like.svg";
import SubmittedFeedbackSVG from "../../../../assets/images/svgs/SubmittedFeedback.svg";
import BriaButton from "../../../../components/common/BriaButton/BriaButton";
import BriaButtonGroup from "../../../../components/common/BriaButtonGroup/BriaButtonGroup";
import BriaTooltip from "../../../../components/common/BriaTooltip/BriaTooltip";
import { APPS } from "../../../../constants/AppsConstants.ts";
import useSecureNavigate from "../../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../../hooks/useStores";
import InputLayout from "../../../../layout/InputLayout/InputLayout.tsx";
import { ImageEditingViewMode, PlaygroundImage } from "../../../../models/image-to-image";
import { TextToImageConfigType } from "../../../../models/text-to-image.ts";
import { isFoxApps } from "../../../../utils/index.ts";
import iframeStore from "../../../IframeNew/iframe-store.tsx";
import classes from "../../../auth/UserInfo/UserInfo.module.scss";
import styles from "./PlaygroundImageOverlay.module.scss";

export type PlaygroundImageOverlayProps = {
	image: PlaygroundImage;
	hideAiEditorButton?: boolean;
};

export type Feedback = {
	appName: string;
	type: string;
	imageUrl: string;
};

export enum FeedbackTypeEnum {
	Positive = "Positive",
	Negative = "Negative",
}

const feedbackAppNameMap: { [k: string]: string } = {
	fox: "Fox",
	imageToImage: "Image To Image",
	textToImage: "Text To Image",
	productPlacement: "Product Placement",
};

const PlaygroundImageOverlay = ({ image, hideAiEditorButton = false }: PlaygroundImageOverlayProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.textToImage.imageOverlay" });
	const { uiStore, playgroundStore } = useAppStore();
	const navigate = useSecureNavigate();
	const [openFeedbackBox, setOpenFeedbackBox] = useState(false);
	const [feedbackText, setFeedbackText] = useState<string>("");
	const [shareAnchorEl, setShareAnchorEl] = useState<HTMLButtonElement>();
	const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState<boolean>(false);

	// const handleOpenSharePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
	// 	setShareAnchorEl(event.currentTarget);
	// };

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleClickOutside = (event: any) => {
		event.stopPropagation();
		// Get all dislike icons and check if one of them was clicked
		const elements = document.querySelectorAll(".dislikeIconRef") ?? [];
		let isDislikeClicked = false;
		elements.forEach((element) => {
			if ((element as HTMLElement).contains(event.target)) {
				isDislikeClicked = true;
			}
		});

		if (isDislikeClicked) {
			// If one of dislikes was clicked, hide all feedback forms and show the relevant one
			const feedbackElements = document.querySelectorAll(".feedbackContainerRef") ?? [];
			feedbackElements.forEach((element) => {
				(element as HTMLElement).style.display = "none";
			});
			const closestFeedbackContainerRef = event.target
				?.closest(".container")
				?.querySelector(".feedbackContainerRef");
			if (closestFeedbackContainerRef) {
				closestFeedbackContainerRef.style.display = "flex";
			}

			// setOpenFeedbackBox(true);
		} else {
			uiStore.hideAllFeedbackBoxes(event);
		}
	};

	const handleCloseSharePopover = () => {
		setShareAnchorEl(undefined);
	};

	const openAiEditor = () => {
		playgroundStore.onAiEditorButtonClick(image, navigate);
	};

	const sendFeedback = async (e: any, type: string, imageUrl: string) => {
		e.stopPropagation();
		const newFeedback = {
			appName:
				isFoxApps() && image.type == APPS.IMAGE_TO_IMAGE
					? feedbackAppNameMap.fox
					: feedbackAppNameMap[image.type ?? ""],
			type: type,
			imageUrl: imageUrl,
			text: feedbackText,
		};
		const alreadySent = playgroundStore.feedbackHistory.find(
			(feedback) => feedback.imageUrl === imageUrl && feedback.type === type,
		);

		if (type === FeedbackTypeEnum.Positive) {
			setOpenFeedbackBox(false);
		}

		if (!alreadySent) {
			await playgroundStore.addOrUpdateFeedback(newFeedback);
		}
		setFeedbackText("");
		if (type === FeedbackTypeEnum.Negative) {
			setIsFeedbackSubmitted(true);
		}
		setTimeout(() => {
			uiStore.hideAllFeedbackBoxes(e);
		}, 2000)
	};

	const aiEditorButton = () => {
		if (image.type === APPS.CAMPAIGN || (image.config as TextToImageConfigType)?.format === "svg") {
			return;
		}

		return (
			<>
				{!playgroundStore.isText2ImagePopupOpened &&
					(!iframeStore.isIframe() || iframeStore.iframe.config.enabled_pages.imageToImage) &&
					!hideAiEditorButton &&
					(playgroundStore.selectedConfig === APPS.TEXT_TO_IMAGE ||
						playgroundStore.imageEditingViewMode === ImageEditingViewMode.GALLERY) && (
						<BriaTooltip className={styles.tooltip} title={t("customizeImage")}>
							<BriaButton
								buttonType="tertiaryMedium"
								onClick={openAiEditor}
								loading={image.type === APPS.PRODUCT_PLACEMENT && image.aiEditorButtonLoading}
								disabled={image.type === APPS.PRODUCT_PLACEMENT && image.aiEditorButtonLoading}
								className={clsx(styles.aiEditorButton, {
									["iconHover"]: true,
								})}
							>
								{t("aiEditor")}
							</BriaButton>
						</BriaTooltip>
					)}
				{image.type === "textToImage" && (
					<>
						<Popover
							open={!!shareAnchorEl}
							onClose={handleCloseSharePopover}
							anchorEl={shareAnchorEl}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
							slotProps={{
								paper: {
									className: styles.popover,
								},
							}}
						>
							<BriaButton>{t("campaign")}</BriaButton>
							<BriaButton>{t("report")}</BriaButton>
							<BriaButton>{t("save")}</BriaButton>
							<BriaButton>{t("export")}</BriaButton>
						</Popover>
					</>
				)}
			</>
		);
	};

	const feedBackButtons = () => {
		if (image.type === APPS.CAMPAIGN) {
			return;
		}

		return (
			<Box className={styles.feedbackWrapper}>
				<BriaButtonGroup className={styles.feedbackBtns}>
					<BriaTooltip title={t("like")}>
						<IconButton
							className={clsx(styles.icon, {
								["iconHover"]: true,
							})}
							onClick={(e) => sendFeedback(e, FeedbackTypeEnum.Positive, image.url)}
						>
							<img
								src={
									playgroundStore.feedbackHistory.find(
										(feedback) =>
											feedback.imageUrl === image.url &&
											feedback.type === FeedbackTypeEnum.Positive,
									)
										? SelectedLikeSVG
										: LikeSVG
								}
								alt=""
							/>
						</IconButton>
					</BriaTooltip>
					<BriaTooltip title={t("disLike.tooltip")}>
						<IconButton
							className={clsx(styles.icon, "dislikeIconRef", {
								["iconHover"]: true,
							})}
							onClick={(e) => {
								e.stopPropagation();
								setIsFeedbackSubmitted(false);
								setOpenFeedbackBox(!openFeedbackBox);
							}}
						>
							<img
								src={
									playgroundStore.feedbackHistory.find(
										(feedback) =>
											feedback.imageUrl === image.url &&
											feedback.type === FeedbackTypeEnum.Negative,
									)
										? SelectedDislikeSVG
										: DislikeSVG
								}
								alt=""
							/>
						</IconButton>
					</BriaTooltip>
				</BriaButtonGroup>
				<Box
					className={clsx({
						[styles.feedbackContainer]: true,
						[styles.singleMode]: playgroundStore.imageEditingViewMode === ImageEditingViewMode.SINGLE,
						["feedbackContainerRef"]: true,
						[styles.hidden]: !openFeedbackBox,
					})}
				>
					<Box className={styles.feedbackElements}>
						{!isFeedbackSubmitted ? (
							<Box className={styles.feedbackInput}>
								<InputLayout className={styles.inputText} label={t("disLike.label")}>
									<TextField
										value={feedbackText}
										onChange={(e) => {
											setFeedbackText(e.target.value);
										}}
										onClick={(e) => {
											e.stopPropagation();
										}}
										placeholder={t("disLike.placeholder")}
										multiline
										maxRows={3}
										InputProps={{
											classes: {
												root: styles.bigTextField,
											},
										}}
									/>
								</InputLayout>
								<BriaButton
									className={styles.button}
									buttonType="primary"
									onClick={(e) => {
										e.stopPropagation();
										sendFeedback(e, FeedbackTypeEnum.Negative, image.url);
									}}
								>
									{playgroundStore.loadingFeedback ? (
										<Box className={classes.circleLoader}>
											{playgroundStore.loadingFeedback && (
												<CircularProgress color="inherit" size={15} />
											)}
										</Box>
									) : (
										<>{t("disLike.button")}</>
									)}
								</BriaButton>
							</Box>
						) : (
							<Box className={styles.feedbackSubmitted}>
								<img className={styles.feedbackSubmittedIcon} src={SubmittedFeedbackSVG} />
								<Typography className={styles.feedbackSubmittedMessage}>
									{t("disLike.feedback1")}
									<br />
									{t("disLike.feedback2")}
								</Typography>
								<CloseIcon
									onClick={(event) => {
										event.stopPropagation();
										uiStore.hideAllFeedbackBoxes(event);
									}}
									className={clsx({
										[styles.closeIcon]: true,
										["closeIcon"]: true,
									})}
								/>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
		);
	};

	return (
		<Box className={clsx(styles.container, "container")}>
			<Box
				className={clsx(styles.imageFooter, {
					[styles.singleMode]: playgroundStore.imageEditingViewMode === ImageEditingViewMode.SINGLE,
				})}
			>
				{aiEditorButton()}
				{feedBackButtons()}
			</Box>
		</Box>
	);
};

const ObservedComponent = observer(PlaygroundImageOverlay);
export default ObservedComponent;
