const SingleModeIcon = () => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M2.5 3.9585H17.5" stroke="#1A0638" stroke-width="1.35" stroke-linecap="round" />
		<mask id="path-2-inside-1_773_1456" fill="white">
			<rect x="2.5" y="5.625" width="15" height="9.58333" rx="1" />
		</mask>
		<rect
			x="2.5"
			y="5.625"
			width="15"
			height="9.58333"
			rx="1"
			stroke="#1A0638"
			stroke-width="2.5"
			mask="url(#path-2-inside-1_773_1456)"
		/>
		<path d="M2.5 16.875H17.5" stroke="#1A0638" stroke-width="1.35" stroke-linecap="round" />
	</svg>
);

export default SingleModeIcon;
